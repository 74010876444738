import { Modal } from 'antd';
import confirmModalTypes from '../../const-values/confirmModalTypes';
import CancelCircledIcon from '../icons/CancelCircledIcon';
import CheckmarkCircledIcon from '../icons/CheckmarkCircledIcon';
import './ConfirmModal.scss';
const ConfirmModal = ({
  modalOpened,
  setModalOpened,
  children,
  handleAccept,
  modalTitle,
  modalType = confirmModalTypes?.successModal,
  className,
  style
}) => {
  return (
    <Modal
      maskStyle={style}
      wrapClassName={className}
      className={`${className} shared-custom-modal confirm-modal ${
        modalType === confirmModalTypes?.successModal ? 'success-modal' : ''
      } ${modalType === confirmModalTypes?.errorModal ? 'error-modal' : ''}`}
      width="96%"
      style={{ maxWidth: '542px' }}
      title={modalTitle || ''}
      open={modalOpened}
      // onOk={() => {
      //   setModalOpened(false);
      // }}
      onCancel={() => {
        setModalOpened(false);
      }}
      footer={false}
    >
      {children}

      <div className="modal-action-btns-wrapper">
        <button
          className="accept-btn"
          onClick={() => {
            handleAccept();
          }}
        >
          <CheckmarkCircledIcon />
          Yes
        </button>
        <button
          onClick={() => {
            setModalOpened(false);
          }}
          className="cancel-btn"
        >
          <CancelCircledIcon color="#404041" />
          No
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
