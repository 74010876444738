import { useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import CustomBreadcrumb from '../../common/bread-crumb/Breadcrumb';
import routerLinks from '../../components/app/routerLinks';
import { useEffect } from 'react';

const PrivacyPage = () => {
  const isLoadingAboutData = useSelector(
    (store) => store.general.isLoadingAboutData
  );
  const fetchedAboutData = useSelector(
    (store) => store.general.fetchedAboutData
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderData = () => {
    if (isLoadingAboutData) {
      return (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 332
            }}
          >
            <LoadingOutlined style={{ fontSize: 20 }} />
          </div>
        </div>
      );
    } else if (!fetchedAboutData?.privacy) {
      return <h1>{t('noData')}</h1>;
    } else if (fetchedAboutData?.privacy) {
      return (
        <p
          style={{
            whiteSpace: 'pre-line',
            lineHeight: 1.8,
            marginTop: 22
          }}
        >
          {fetchedAboutData.privacy}
        </p>
      );
    }
    return null;
  };

  return (
    <div className="privacy-page">
      <CustomBreadcrumb
        arr={[
          {
            title: t('breadcrumbs.home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: t('breadcrumbs.privacy'),
            isLink: false
          }
        ]}
      />
      <div className="custom-container">{renderData()}</div>
    </div>
  );
};

export default PrivacyPage;
