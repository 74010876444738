import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dImg from '../../assets/imgs/d-img.png';
import CustomImage from '../../common/custom-image/CustomImage';
import CoinsIcon from '../../common/icons/CoinsIcon';
import quoteTypes from '../../const-values/quoteTypes';
import {
  setQuoteModalOpened,
  setQuoteType
} from '../../redux/quote-slice/quoteSlice';
import { setNotAuthedModalOpened } from '../../redux/user-slice/userSlice';
import './HomeDevelopmentSection.scss';

const HomeDevelopmentSection = () => {
  const { t } = useTranslation();
  const user = useSelector((store) => store.user.user);
  const fetchedAboutData = useSelector(
    (store) => store.general.fetchedAboutData
  );

  const dispatch = useDispatch();
  return (
    <section className="home-dev-section">
      <div className="custom-container">
        <div className="sec-content-wrapper">
          <CustomImage className="d-img" src={dImg} />
          <div className="sec-text">
            <div className="sec-title">{t('aboutTitle')}</div>

            <div className="sec-desc">{fetchedAboutData?.about}</div>

            <div className="sec-actions">
              <button
                className="request-q-btn"
                onClick={() => {
                  if (user) {
                    dispatch(setQuoteType(quoteTypes?.publicQuote));
                    dispatch(setQuoteModalOpened(true));
                  } else {
                    dispatch(setNotAuthedModalOpened(true));
                  }
                }}
              >
                <CoinsIcon color="#fff" />
                {t('hero_section.shop_btn')}
              </button>
              {/* <Link to="/" className="more-link">
                {t('hero_section.readMore')}
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeDevelopmentSection;
