import React from 'react';

const RadioButtonFilled = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle
        cx="12"
        cy="12"
        r="10.25"
        stroke={color || '#58B030'}
        strokeWidth="1.5"
      />
      <circle cx="12" cy="12" r="5.5" fill={color || '#58B030'} />
    </svg>
  );
};

export default RadioButtonFilled;
