import StarIcon from '../icons/StarIcon';
import './RatePreview.scss';

const RatePreview = ({ value }) => {
  return (
    <div className="rate-preview">
      <StarIcon />
      <div className="value">{value}</div>
    </div>
  );
};

export default RatePreview;
