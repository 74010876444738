/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import './MakeOfferForm.scss';
import { useTranslation } from 'react-i18next';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import RadioButtonFilled from '../../common/icons/RadioButtonFilled';
import RadioButtonEmpty from '../../common/icons/RadioButtonEmpty';
import { useEffect } from 'react';
import FileInput from '../../common/file-input/FileInput';
import imgsSrcs from '../../helpers/imgsSrcs';
import { useState } from 'react';
import checkRes from '../../utils/checkRes';
import { useParams } from 'react-router-dom';

const emptyFormValues = {
  offerType: '',
  prices: [],
  offerFile: null
};

const MakeOfferForm = ({
  modalOpened,
  setModalOpened,
  formAction,
  productsArr,
  dataObj = null
}) => {
  const [files, setFiles] = useState(null);

  const { i18n, t } = useTranslation();
  const sharedT = (key) => t(`makeOfferModal.${key}`);
  const schema = Yup.object().shape({
    offerType: Yup.string().required(sharedT('offerTypeError')),
    prices: Yup.array()
      .test('prices', 'required', (v, context) => {
        let result = true;
        if (
          (!v || v?.length === 0) &&
          context.parent.offerType === offerTypes.manual
        )
          result = false;
        return result;
      })
      .of(
        Yup.object().shape({
          price: Yup.string()
            .test('prices.price', sharedT('requiredPrice'), (v, context) => {
              let result = true;
              if (
                !v &&
                context?.from[1]?.value?.offerType === offerTypes?.manual
              )
                result = false;
              return result;
            })
            .test('prices.price', sharedT('priceInNumbers'), (v, context) => {
              let result = true;
              if (
                v &&
                !v.match(/^(\d+)?(\.\d+)?$/) &&
                context?.from[1]?.value?.offerType === offerTypes?.manual
              )
                result = false;
              return result;
            })
        })
      ),

    offerFile: Yup.mixed().test(
      'offerFile',
      sharedT('selectFiles'),
      (v, context) => {
        let result = true;
        if (!v && context?.parent?.offerType === offerTypes?.attachements)
          result = false;
        return result;
      }
    )
  });
  const offerTypes = {
    manual: '1',
    attachements: '2'
  };
  const {
    handleSubmit,
    control,
    watch,
    register,
    unregister,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      ...emptyFormValues
    }
  });

  // handle initial values
  useEffect(() => {
    if (productsArr?.length > 0) {
      setValue(
        'prices',
        productsArr.map((p) => ({
          product_id: p?.id,
          price: p?.price
        }))
      );
    }
  }, [productsArr, modalOpened]);

  const isSubmitting = useSelector((store) => store.user.isSubmitting);
  const dispatch = useDispatch();
  const params = useParams();
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('order_id', params.order_id);
    if (data.offerType === offerTypes?.manual && data?.prices?.length > 0)
      formData.append(
        'prices',
        JSON.stringify(
          data.prices.map((obj) => ({
            product_id: obj?.product_id,
            price: obj?.price
          }))
        )
      );
    else if (
      data.offerType === offerTypes?.attachements &&
      data?.offerFile?.length > 0
    )
      formData.append('offerFile', data.offerFile[0]);

    if (
      dataObj &&
      typeof dataObj === 'object' &&
      Object.keys(dataObj)?.length > 0
    ) {
      for (let key in dataObj) {
        formData.append(key, dataObj[key]);
      }
    }
    const action = await dispatch(
      formAction({
        dataToBeSubmitted: formData
      })
    );
    const res = action?.payload?.res;
    if (checkRes(res)) {
      reset(emptyFormValues);
      setFiles(null);
    }
  };

  console.log(watch());

  const [form] = Form.useForm();

  const renderProductsList = () => {
    if (productsArr?.length > 0)
      return (
        <div className="products-list">
          {productsArr.map((p, index) => (
            <div key={p.id} className="p-row">
              <div className="label-value-row">
                <div className="label-p">{sharedT('materialName')}</div>
                <div className="label-v">{p?.productName || '---'}</div>
              </div>
              <div className="p-data">
                <div className="label-value-row">
                  <div className="label-p">{sharedT('quantity')}</div>
                  <div className="label-v">{p?.quantity || '---'}</div>
                </div>
                <div className="label-value-row">
                  <div className="label-p">{sharedT('unitType')}</div>
                  <div className="label-v">{p?.unit?.name || '---'}</div>
                </div>
                <div className="label-value-row">
                  {/* <div className="label-p">{sharedT('price')}</div> */}
                  <div className="label-v">
                    <div
                      style={{
                        minWidth: `${watch(`prices.${index}.width`)}ch`
                      }}
                      className={`custom-text-field-wrapper ${i18n.dir()}`}
                    >
                      <AntdTextField
                        onChange={(v) => {
                          if (watch('prices')?.length > 0)
                            setValue(
                              'prices',
                              watch('prices').map((obj) => ({
                                ...obj,
                                width: v?.length >= 23 ? 23 : v.length
                              }))
                            );
                        }}
                        className="custom-text-field"
                        name={`prices.${index}.price`}
                        type="text"
                        errorMsg={
                          errors?.prices &&
                          errors.prices[index]?.price &&
                          errors.prices[index].price.message
                        }
                        validateStatus={
                          errors?.prices &&
                          errors.prices[index]?.price &&
                          errors?.prices[index]?.price
                            ? 'error'
                            : ''
                        }
                        control={control}
                      />
                      <p className="custom-label">{sharedT('price')}</p>

                      <span className="prefix-span">{t('currency.sar')}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      );

    return (
      <h2
        style={{
          margin: '42px auto',
          textAlign: 'center'
        }}
      >
        {t('noProducts')}
      </h2>
    );
  };

  return (
    <>
      <Form
        fields={[
          {
            name: 'offerType',
            value: watch('offerType')
          }
        ]}
        className="make-offer-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <div className="radios-wrap">
            {/* <p className="radios-group-title">
            </p> */}
            <div className="labels-wrap">
              <label
                className={`radio-input-label ${
                  String(watch(`offerType`)) == String(offerTypes?.manual)
                    ? 'selected'
                    : ''
                }`}
              >
                <input
                  type="radio"
                  value={offerTypes?.manual}
                  {...register(`offerType`)}
                  onClick={(e) => {
                    if (watch('offerType') === offerTypes?.manual)
                      setValue('offerType', '');
                    else setValue('offerType', offerTypes?.manual);
                  }}
                />

                <div className="svg-radio-icon-wrap">
                  {watch(`offerType`) == String(offerTypes?.manual) ? (
                    <RadioButtonFilled />
                  ) : (
                    <RadioButtonEmpty />
                  )}
                </div>
                <span className={`label-span`}>{sharedT('pricesTitle')}</span>
              </label>

              {watch('offerType') === offerTypes?.manual
                ? renderProductsList()
                : null}

              {/*  */}
              {/*  */}
              {/*  */}
              <div className="or-div">
                <span></span>
                {sharedT('or')}
                <span></span>
              </div>
              {/*  */}
              {/*  */}
              {/*  */}

              <label
                className={`radio-input-label ${
                  String(watch(`offerType`)) == String(offerTypes?.attachements)
                    ? 'selected'
                    : ''
                }`}
              >
                <input
                  type="radio"
                  value={offerTypes?.attachements}
                  {...register(`offerType`)}
                  onClick={(e) => {
                    if (watch('offerType') === offerTypes?.attachements)
                      setValue('offerType', '');
                    else setValue('offerType', offerTypes?.attachements);
                  }}
                />
                <div className="svg-radio-icon-wrap">
                  {watch(`offerType`) == String(offerTypes?.attachements) ? (
                    <RadioButtonFilled />
                  ) : (
                    <RadioButtonEmpty />
                  )}
                </div>
                <span className={`label-span`}>{sharedT('attachments')}</span>
              </label>
              {watch('offerType') === offerTypes?.attachements ? (
                <div
                  className="attatches-wrap"
                  style={{
                    marginBottom: 22
                  }}
                >
                  <FileInput
                    files={files}
                    setFiles={setFiles}
                    name="offerFile"
                    label=""
                    max={5}
                    // accept="image/png, image/jpg, image/jpeg, image/gif"
                    accept="image/*"
                    multiple={false}
                    setValue={setValue}
                    watch={watch}
                    register={register}
                    unregister={unregister}
                    // setUrls={setUrls}
                    dropzoneText={sharedT('selectFiles')}
                    className="product-images-dropzone"
                    dropzoneUrls={[]}
                    canDelete={false}
                    icon={
                      <img
                        src={imgsSrcs?.uploadCloudImg}
                        style={{ width: 22 }}
                        alt="upload"
                      />
                    }
                    showError={errors?.offerFile}
                    errorMsg={errors?.offerFile?.message}
                  />
                </div>
              ) : null}
            </div>
            {errors?.offerType && (
              <p
                className="error-p"
                style={{
                  marginTop: 6
                }}
              >
                {errors?.offerType?.message}
              </p>
            )}
          </div>

          <div className="offer-action-btns">
            <button
              onClick={() => {
                reset(emptyFormValues);
                setFiles(null);
              }}
              type="button"
              className="cancel-btn"
            >
              {sharedT('cancel')}
            </button>

            <div className="main-btns">
              <button
                onClick={() => {
                  setModalOpened(false);
                }}
                type="button"
                className="back-btn"
              >
                {sharedT('back')}
              </button>
              <Button
                className="submit-btn"
                htmlType="submit"
                type="primary"
                // icon={<LoginOutlined />}
                loading={isSubmitting}
              >
                {sharedT('submit')}
              </Button>
            </div>
          </div>
        </div>
      </Form>

      {isSubmitting && <LoadingModal />}
    </>
  );
};

export default MakeOfferForm;
