const MapIcon = ({ color }) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.30757 10.5856C7.75103 10.5856 6.47949 9.32138 6.47949 7.75754C6.47949 6.19369 7.75103 4.93677 9.30757 4.93677C10.8641 4.93677 12.1356 6.201 12.1356 7.76484C12.1356 9.32869 10.8641 10.5856 9.30757 10.5856ZM9.30757 6.03292C8.35757 6.03292 7.57565 6.80754 7.57565 7.76484C7.57565 8.72215 8.35026 9.49677 9.30757 9.49677C10.2649 9.49677 11.0395 8.72215 11.0395 7.76484C11.0395 6.80754 10.2576 6.03292 9.30757 6.03292Z"
        fill="#246DB4"
      />
      <path
        d="M9.30773 16.863C8.22619 16.863 7.13734 16.4538 6.28965 15.6426C4.13388 13.5672 1.75157 10.2569 2.65042 6.31801C3.46157 2.74455 6.58196 1.14417 9.30773 1.14417C9.30773 1.14417 9.30773 1.14417 9.31503 1.14417C12.0408 1.14417 15.1612 2.74455 15.9723 6.32532C16.8639 10.2642 14.4816 13.5672 12.3258 15.6426C11.4781 16.4538 10.3893 16.863 9.30773 16.863ZM9.30773 2.24032C7.18119 2.24032 4.44811 3.37301 3.72465 6.55917C2.93542 10.0011 5.0985 12.968 7.05696 14.8461C8.32119 16.0665 10.3016 16.0665 11.5658 14.8461C13.517 12.968 15.68 10.0011 14.9054 6.55917C14.1746 3.37301 11.4343 2.24032 9.30773 2.24032Z"
        fill={color || '#58B030'}
      />
    </svg>
  );
};

export default MapIcon;
