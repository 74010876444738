/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomImage from '../../common/custom-image/CustomImage';
import accountTypes from '../../const-values/accountTypes';
import {
  isAbleNegotiateValues,
  negotiationStatus,
  neogtiationTypes,
  threeOrderStatusValues
} from '../../const-values/orderStatus';
import imgsSrcs from '../../helpers/imgsSrcs';
import {
  setConfirmNegotiateModalOpened,
  setNegotiationsModalOpenedAction,
  setRejectNegotiateModalOpened,
  setSelectedNeotiationAction
} from '../../redux/user-orders-slice/userOrdersSlice';
import { userGetOrderNegotitionHistoryAction } from '../../redux/user-orders-slice/userOrdersSliceThunkApi';
import './OrderNegotiationsModal.scss';
import UserNegotiationForm from './UserNegotiationForm';

const OrderNegotiationsModal = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user.user);
  const negotiationssModalOpened = useSelector(
    (store) => store.userOrders.negotiationssModalOpened
  );
  const fetchedUserSingleOrder = useSelector(
    (store) => store.userOrders.fetchedUserSingleOrder
  );
  const fetchSingleOrderCount = useSelector(
    (store) => store.userOrders.fetchSingleOrderCount
  );
  const isLoadingUserOrderNegotiations = useSelector(
    (store) => store.userOrders.isLoadingUserOrderNegotiations
  );
  const orderNegotiations = useSelector(
    (store) => store.userOrders.orderNegotiations
  );

  const { i18n, t } = useTranslation();
  const sharedT = (key) => t(`offersModal.${key}`);

  useEffect(() => {
    if (negotiationssModalOpened) {
      document.body.style.overflowY = 'hidden';
    } else document.body.style.overflowY = 'auto';
  }, [negotiationssModalOpened]);

  useEffect(() => {
    let isMounted = true;
    if (negotiationssModalOpened && isMounted)
      dispatch(
        userGetOrderNegotitionHistoryAction({
          filterObj: {
            order_id: params?.order_id || ''
          }
        })
      );

    return () => (isMounted = false);
  }, [i18n.language, fetchSingleOrderCount, negotiationssModalOpened]);

  const renderBoxBody = () => {
    if (isLoadingUserOrderNegotiations) {
      return (
        <div
          style={{
            height: 200,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <LoadingOutlined />
        </div>
      );
    } else if (orderNegotiations?.length === 0) {
      return (
        <h2
          style={{
            textAlign: 'center',
            fontSize: 20,
            margin: '42px 0'
          }}
        >
          {sharedT('noNego')}
        </h2>
      );
    } else if (orderNegotiations?.length > 0) {
      return orderNegotiations.map((obj) => (
        <div key={obj?.id} className="order-nego-card">
          {renderNegoCard(obj)}

          {renderAcceptRejectNego(obj)}
        </div>
      ));
    }
    return null;
  };

  const renderAcceptRejectNego = (obj) => {
    if (
      obj?.status === negotiationStatus?.inReview &&
      obj?.type !== accountTypes()?.user
    ) {
      return (
        <div className="accept-reject-btns">
          <button
            onClick={() => {
              dispatch(setSelectedNeotiationAction(obj));
              dispatch(setRejectNegotiateModalOpened(true));
            }}
            className="reject-btn"
          >
            {sharedT('reject')}
          </button>
          <button
            onClick={() => {
              dispatch(setSelectedNeotiationAction(obj));
              dispatch(setConfirmNegotiateModalOpened(true));
            }}
            className="accept-btn"
          >
            {sharedT('accept')}
          </button>
        </div>
      );
    } else if (obj?.status === negotiationStatus?.accepted) {
      return (
        <div className="accepted-wrap">
          <img src={imgsSrcs?.checkGreenImg} alt="" />
          <p className="accepted-p">{sharedT('accepted')}</p>
        </div>
      );
    } else if (obj?.status === negotiationStatus?.rejected) {
      return (
        <div className="rejected-wrap">
          <img src={imgsSrcs?.wrongRedImg} alt="" />
          <p className="rejected-p">{sharedT('rejected')}</p>
        </div>
      );
    } else if (
      fetchedUserSingleOrder?.status === threeOrderStatusValues?.accepted
    ) {
      return (
        <div className="accepted-wrap">
          <img src={imgsSrcs?.checkGreenImg} alt="" />
          <p className="accepted-p">{sharedT('accepted')}</p>
        </div>
      );
    } else if (
      fetchedUserSingleOrder?.status === threeOrderStatusValues?.rejected
    ) {
      return (
        <div className="rejected-wrap">
          <img src={imgsSrcs?.wrongRedImg} alt="" />
          <p className="rejected-p">{sharedT('rejected')}</p>
        </div>
      );
    } else if (
      fetchedUserSingleOrder?.status === threeOrderStatusValues?.canceledByUser
    ) {
      return (
        <div className="rejected-wrap">
          <img src={imgsSrcs?.wrongRedImg} alt="" />
          <p className="rejected-p">{sharedT('canceled')}</p>
        </div>
      );
    }
    return null;
  };

  const renderProfileCard = (obj) => {
    if (obj?.type === neogtiationTypes?.company) {
      // supplier card
      return (
        <div className="prof-card other">
          <div className="img-name-date-wrap">
            <div className="img-name">
              <CustomImage
                className="prof-img"
                src={fetchedUserSingleOrder?.company?.image}
              />
              <div className="name">
                {fetchedUserSingleOrder?.company?.name}
              </div>
            </div>

            <div className="date">{obj?.created_at}</div>
          </div>
        </div>
      );
    } else if (obj?.type === neogtiationTypes?.user) {
      // user card
      return (
        <div className="prof-card mine">
          <div className="img-name-date-wrap">
            <div className="img-name">
              <CustomImage className="prof-img" src={user?.image} />
              <div className="name">{user?.name}</div>
            </div>

            <div className="date">{obj?.created_at}</div>
          </div>
        </div>
      );
    }
  };

  const renderNegoCard = (nego) => {
    return (
      <div
        className={`nego-card ${
          nego?.type === neogtiationTypes?.user ? 'mine' : 'other'
        }`}
      >
        {renderProfileCard(nego)}
        <p className="nego-title">{sharedT('myPrices')}</p>
        <div className="total-price">
          <div className="price-label">{sharedT('totalPrice')}</div>
          <div className="price-v">
            {nego?.price}
            <span className="curr-label">{t('currency.sar')}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    const isAbleNegotiate =
      fetchedUserSingleOrder?.lastNegotiate?.isAbleNegotiate;
    const type = fetchedUserSingleOrder?.lastNegotiate?.type;
    if (
      fetchedUserSingleOrder?.status === threeOrderStatusValues?.new &&
      isAbleNegotiate === isAbleNegotiateValues?.yes &&
      type === accountTypes()?.company
    ) {
      return (
        <div className="box-footer">
          <UserNegotiationForm />
        </div>
      );
    }
    return null;
  };
  return (
    <AnimatePresence>
      {negotiationssModalOpened && (
        <motion.div
          onClick={() => {
            dispatch(setNegotiationsModalOpenedAction(null));
          }}
          initial={{
            opacity: 0
          }}
          animate={{
            opacity: 1,
            transition: {
              opacity: { ease: 'linear', duration: 0.4 }
            }
          }}
          exit={{
            opacity: 0,
            transitionDelay: 1,
            animationDelay: 1,

            transition: {
              delay: 0.2,
              opacity: { ease: 'easeOut', duration: 0.4 }
            }
          }}
          className={`selected-supplier-product-wrapper order-negotiations-modal ${i18n.dir()}`}
        >
          <motion.div
            // ref={menuRef}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            initial={{
              translateX: i18n.dir() === 'ltr' ? 600 : -600
            }}
            animate={{
              translateX: 0,
              transition: {
                delay: 0.2,
                duration: 0.4,
                ease: 'linear'
              }
            }}
            exit={{
              translateX: i18n.dir() === 'ltr' ? 600 : -600,
              transition: {
                duration: 0.4,
                ease: 'linear'
              }
            }}
            className="selected-supplier-product"
            // ref={menuRef}
          >
            <div className="selected-supplier-product-with-overflow">
              <div className="box-header">
                <div className="titles-wrap">
                  <p>{sharedT('negotiations')}</p>
                  <p
                    style={{
                      marginTop: 8,
                      marginInlineStart: 4,
                      color: '#668199',
                      fontSize: 14
                    }}
                  >{`${sharedT('order')} #${fetchedUserSingleOrder?.id}`}</p>
                </div>

                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    // handleClose();
                    dispatch(setNegotiationsModalOpenedAction(false));
                  }}
                  className="back-btn"
                >
                  {i18n.dir() === 'ltr' ? (
                    <img src={imgsSrcs?.arrowRightImg} alt="" />
                  ) : (
                    <img src={imgsSrcs?.arrowLeftImg} alt="" />
                  )}
                </button>
              </div>

              <div className="box-body">{renderBoxBody()}</div>

              {renderFooter()}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default OrderNegotiationsModal;
