import React from 'react';

const CancelCircledIcon = ({ color, width, height, ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99998 15.1668C4.04665 15.1668 0.833313 11.9535 0.833313 8.00016C0.833313 4.04683 4.04665 0.833496 7.99998 0.833496C11.9533 0.833496 15.1666 4.04683 15.1666 8.00016C15.1666 11.9535 11.9533 15.1668 7.99998 15.1668ZM7.99998 1.8335C4.59998 1.8335 1.83331 4.60016 1.83331 8.00016C1.83331 11.4002 4.59998 14.1668 7.99998 14.1668C11.4 14.1668 14.1666 11.4002 14.1666 8.00016C14.1666 4.60016 11.4 1.8335 7.99998 1.8335Z"
        fill={color || '#fff'}
      />
      <path
        d="M6.11332 10.3869C5.98666 10.3869 5.85999 10.3402 5.75999 10.2402C5.56666 10.0469 5.56666 9.7269 5.75999 9.53357L9.53332 5.76023C9.72666 5.5669 10.0467 5.5669 10.24 5.76023C10.4333 5.95357 10.4333 6.27357 10.24 6.4669L6.46666 10.2402C6.37332 10.3402 6.23999 10.3869 6.11332 10.3869Z"
        fill={color || '#fff'}
      />
      <path
        d="M9.88666 10.3869C9.75999 10.3869 9.63332 10.3402 9.53332 10.2402L5.75999 6.4669C5.56666 6.27357 5.56666 5.95357 5.75999 5.76023C5.95332 5.5669 6.27332 5.5669 6.46666 5.76023L10.24 9.53357C10.4333 9.7269 10.4333 10.0469 10.24 10.2402C10.14 10.3402 10.0133 10.3869 9.88666 10.3869Z"
        fill={color || '#fff'}
      />
    </svg>
  );
};

export default CancelCircledIcon;
