import './NotAuthedModal.scss';

import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import imgsSrcs from '../../helpers/imgsSrcs';
import './NotAuthedModal.scss';
import { useTranslation } from 'react-i18next';
import { setNotAuthedModalOpened } from '../../redux/user-slice/userSlice';
import routerLinks from '../../components/app/routerLinks';
const NotAuthedModal = ({ title }) => {
  const { t } = useTranslation();
  const notAuthedModalOpened = useSelector(
    (store) => store.user.notAuthedModalOpened
  );
  const dispatch = useDispatch();

  return (
    <Modal
      destroyOnClose
      wrapClassName="quote-modal-wrap not-authed-modal"
      className="request-quote-modal"
      closeIcon={<img src={imgsSrcs.closeCircleImg} alt="close" />}
      width="96%"
      style={{ maxWidth: '562px' }}
      title={
        <div className="modal-h">
          <p className="main-header-t">{t('notAuthedModal.title')}</p>
          {/* <p className="sub-header-t">{t('reqFromVendors')}</p> */}
        </div>
      }
      open={notAuthedModalOpened}
      onOk={() => {
        dispatch(setNotAuthedModalOpened(false));
      }}
      onCancel={() => {
        dispatch(setNotAuthedModalOpened(false));
      }}
      footer={false}
    >
      <div className="modal-content">
        <img src={imgsSrcs?.userAvatarImg} alt="avatar" />

        <div className="action-links">
          <Link
            onClick={() => {
              dispatch(setNotAuthedModalOpened(false));
            }}
            className="signin-link"
            to={routerLinks?.signinPage}
          >
            {t('notAuthedModal.signin')}
          </Link>
          <Link
            onClick={() => {
              dispatch(setNotAuthedModalOpened(false));
            }}
            className="signup-link"
            to={routerLinks?.userSignupRoute}
          >
            {t('notAuthedModal.signup')}
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default NotAuthedModal;
