// import successImg from '../../assets/imgs/icons/success.png';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from 'antd';
import './SuccessCard.scss';

const SuccessCard = ({
  mainTitle,
  subTitle,
  btnText,
  welcomeText,
  coloredText,
  btnUrl,
  onClick,
  className
}) => {
  return (
    <div className={`success-card ${className || ''}`}>
      {/* <Logo className="logo-wrapper" /> */}
      {/* <img src={successImg} alt="success" /> */}
      {mainTitle && <h2>{mainTitle}</h2>}
      {subTitle && <p>{subTitle}</p>}
      <div className="welcome-wrap">
        {welcomeText && <p>{welcomeText}</p>}{' '}
        {coloredText && <p className="colored-p">{coloredText}</p>}
      </div>
      {btnUrl ? (
        <RouterLink className="action-btn" to={btnUrl}>
          {btnText}
        </RouterLink>
      ) : (
        <Button className="action-btn" onClick={onClick} type="primary">
          {btnText}
        </Button>
      )}
    </div>
  );
};

export default SuccessCard;
