/* eslint-disable react-hooks/exhaustive-deps */
import { t } from 'i18next';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../common/bread-crumb/Breadcrumb';
import routerLinks from '../../components/app/routerLinks';
import userRouterLinks from '../../components/app/user-routes/uesRouterLinks';
import {
  getUserSingleOrdersAction,
  userAnswerOfferAction
} from '../../redux/user-orders-slice/userOrdersSliceThunkApi';
import './UserSingleOrderPage.scss';
import { LoadingOutlined } from '@ant-design/icons';
import imgsSrcs from '../../helpers/imgsSrcs';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import OrderOffersModal from './OrderOffersModal';
import OrderNegotiationsModal from './OrderNegotiationsModal';
import ConfirmModals from './ConfirmModals';
import RenderOrderActionBtns from './RenderOrderActionBtns';
import OrderSideCard from './OrderSideCard';
import OrderDetails from './OrderDetails';
import CompaniesModal from './CompaniesModal';
import MakeOfferModal from '../../components/make-offer/MakeOfferModal';
import { setUserOfferModalOpenedAction } from '../../redux/user-orders-slice/userOrdersSlice';
import { userOfferStatus } from '../../const-values/orderStatus';

const UserSingleOrderPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const isSubmitting = useSelector((store) => store.userOrders.isSubmitting);
  const isLoadingUserOrder = useSelector(
    (store) => store.userOrders.isLoadingUserOrder
  );
  const fetchedUserSingleOrder = useSelector(
    (store) => store.userOrders.fetchedUserSingleOrder
  );
  const fetchSingleOrderCount = useSelector(
    (store) => store.userOrders.fetchSingleOrderCount
  );
  const userOfferModalOpened = useSelector(
    (store) => store.userOrders.userOfferModalOpened
  );
  const isSubmittingUserOffer = useSelector(
    (store) => store.userOrders.isSubmittingUserOffer
  );
  const fetchedCompanyOffers = useSelector(
    (store) => store.userOrders.fetchedCompanyOffers
  );

  const params = useParams();
  useEffect(() => {
    dispatch(
      getUserSingleOrdersAction({
        filterObj: {
          order_id: params?.order_id || ''
        }
      })
    );
  }, [i18n.language, fetchSingleOrderCount]);

  const renderPageLayout = () => {
    if (isLoadingUserOrder) {
      return (
        <div
          style={{
            height: 332,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <LoadingOutlined />
        </div>
      );
    } else if (!fetchedUserSingleOrder) {
      return (
        <div
          style={{
            height: 300,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img
            style={{
              width: '100%',
              maxWidth: '300px'
            }}
            src={imgsSrcs?.emptyResultsImg}
            alt="empty"
          />
        </div>
      );
    } else if (fetchedUserSingleOrder) {
      return (
        <div className="page-layout">
          <div className="side-card-offer-result">
            <OrderSideCard />
            {fetchedUserSingleOrder?.acceptedOffer && (
              <div className="offer-result">
                <div className="accepted-wrap">
                  <img src={imgsSrcs?.checkGreenImg} alt="" />
                  <p className="accepted-p">{t('offersModal.accepted')}</p>
                </div>
              </div>
            )}
          </div>

          <div className="page-content">
            <OrderDetails />
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="user-single-order-page">
      <CustomBreadcrumb
        arr={[
          {
            title: t('breadcrumbs.home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: t('breadcrumbs.userOrders'),
            isLink: true,
            to: userRouterLinks?.userOrdersRoute
          },
          {
            title: fetchedUserSingleOrder?.id
              ? `#${fetchedUserSingleOrder.id}`
              : t('breadcrumbs.orderDetails'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <div className="page-title-action-wrap">
          <p className="page-title">
            {t('ordersPage.pageTitle')} / #{fetchedUserSingleOrder?.id}
          </p>

          <RenderOrderActionBtns />
        </div>

        {renderPageLayout()}
      </div>

      <OrderOffersModal />
      <OrderNegotiationsModal />
      <CompaniesModal />

      <MakeOfferModal
        productsArr={fetchedUserSingleOrder?.products}
        formAction={userAnswerOfferAction}
        dataObj={{
          offer_id: fetchedCompanyOffers?.lastOffer?.id,
          status: userOfferStatus?.newOffer
        }}
        modalOpened={userOfferModalOpened}
        setModalOpened={(v) => dispatch(setUserOfferModalOpenedAction(v))}
        isSubmitting={isSubmittingUserOffer}
      />

      <ConfirmModals />

      {isSubmitting && <LoadingModal />}
    </div>
  );
};

export default UserSingleOrderPage;
