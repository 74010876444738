import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { answerNegotiateStatus } from '../../const-values/orderStatus';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import { userAnswerOrderNegotiationAction } from '../../redux/user-orders-slice/userOrdersSliceThunkApi';
// import useFCMDeviceToken from '../../custom-hooks/useFCMDeviceToken';

const UserNegotiationForm = () => {
  const { t, i18n } = useTranslation();
  const sharedT = (key) => t(`offersModal.${key}`);

  const schema = Yup.object().shape({
    price: Yup.string()
      .required(sharedT('anotherPrice.errors.required'))
      .test('price', sharedT('anotherPrice.errors.notValid'), (v, context) => {
        let result = true;
        if (v && !v.match(/^(\d+)?(\.\d+)?$/)) result = false;
        return result;
      })
  });
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      price: ''
    }
  });

  const isSubmitting = useSelector((store) => store.userOrders.isSubmitting);
  const fetchedUserSingleOrder = useSelector(
    (store) => store.userOrders.fetchedUserSingleOrder
  );
  const dispatch = useDispatch();
  let isMounted = true;
  const onSubmit = (data) => {
    if (isMounted && fetchedUserSingleOrder?.lastNegotiate?.id) {
      const formData = new FormData();
      formData.append('negotiate_id', fetchedUserSingleOrder.lastNegotiate.id);
      formData.append('status', answerNegotiateStatus?.anotherPrice);
      formData.append('price', data.price);
      dispatch(
        userAnswerOrderNegotiationAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  const [form] = Form.useForm();
  return (
    <>
      <Form
        className="user-new-price-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="price"
              type="text"
              errorMsg={errors?.price?.message}
              validateStatus={errors?.price ? 'error' : ''}
              control={control}
            />
            <p className="custom-label">{sharedT('anotherPrice.label')}</p>
          </div>

          <Button
            onClick={(e) => e.stopPropagation()}
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={isSubmitting}
          >
            {sharedT('newPrice')}
          </Button>
        </div>
      </Form>

      {isSubmitting && <LoadingModal />}
    </>
  );
};

export default UserNegotiationForm;
