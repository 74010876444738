/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AntdSelectOption from '../antd-form-components/AntdSelectOption';

const ServicesSelectOption = ({
  name,
  label,
  mode,
  parentClassName,
  control,
  setValue,
  errors,
  isLoadingSelectOption,
  selectOptionArr,
  selectOptionPagination,
  handleClickMore
}) => {
  const { i18n } = useTranslation();

  const [servicesArr, setServicesArr] = useState([]);
  useEffect(() => {
    if (
      selectOptionArr?.length >= 0 &&
      selectOptionPagination?.current_page > 1
    ) {
      setServicesArr((prev) => {
        return [...prev, ...selectOptionArr];
      });
    } else setServicesArr([...selectOptionArr]);
  }, [selectOptionArr]);
  const renderServicesOptions = () => {
    return servicesArr.map((obj) => ({
      title: obj?.name,
      value: obj?.id
    }));
  };

  return (
    <div className={`custom-select-field-wrapper without-icon ${i18n.dir()}`}>
      <AntdSelectOption
        withPagination={
          selectOptionPagination?.current_page <
          selectOptionPagination?.total_pages
        }
        handleClickMore={handleClickMore}
        loadingMore={isLoadingSelectOption}
        className="custom-select-field"
        name={name}
        mode={mode || ''}
        errorMsg={errors[name] && errors[name]?.message}
        validateStatus={errors[name] ? 'error' : ''}
        control={control}
        setValue={setValue}
        options={renderServicesOptions()}
        showSearch={true}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        // formClassName={parentClassName}
      />
      <p className="custom-label">{label}</p>
    </div>
  );
};

export default ServicesSelectOption;
