// create quote action

import { createAsyncThunk } from '@reduxjs/toolkit';
import getCompanyOrdersApi from '../../apis/company-orders-apis/getCompanyOrdersApi';
import getCompanySingleOrderApi from '../../apis/company-orders-apis/getCompanySingleOrderApi';
import makeOfferApi from '../../apis/company-orders-apis/makeOfferApi';
import supplierAcceptOrRejectOrderApi from '../../apis/company-orders-apis/supplierAcceptOrRejectOrderApi';
import supplierNegotiationHistoryApi from '../../apis/company-orders-apis/supplierNegotiationHistoryApi';
import supplierAnswerNegotiationApi from '../../apis/company-orders-apis/supplierAnswerNegotiationApi';
import customApiRequest from '../../helpers/customApiRequest';
import contractorAnswerOfferApi from '../../apis/user-quotes-api/contractorAnswerOfferApi';
import getContractorOffersApi from '../../apis/user-quotes-api/getContractorOffersApi';

// get company Orders action
export const getComapnyOrdersAction = createAsyncThunk(
  'companyOrders/getComapnyOrdersAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getCompanyOrdersApi(filterObj),
      thunkAPI
    });
  }
);

// get company single order action
export const getCompanySingleOrdersAction = createAsyncThunk(
  'companyOrders/getCompanySingleOrdersAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getCompanySingleOrderApi(filterObj),
      thunkAPI
    });
  }
);

// make offer action
export const makeOfferAction = createAsyncThunk(
  'companyOrders/makeOfferAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await makeOfferApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

// get contractor offers action
export const getContractorOrderOffersOfferAction = createAsyncThunk(
  'companyOrders/getContractorOrderOffersOfferAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getContractorOffersApi(filterObj),
      thunkAPI
    });
  }
);
// contractor answer offer action
export const contractorAnswerOfferAction = createAsyncThunk(
  'companyOrders/contractorAnswerOfferAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await contractorAnswerOfferApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

// supplier accept reject order action
export const supplierAcceptRejectOrderAction = createAsyncThunk(
  'companyOrders/supplierAcceptRejectOrderAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await supplierAcceptOrRejectOrderApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

// supplier get order negotiations action
export const supplierGetOrderNegotiationsAction = createAsyncThunk(
  'companyOrders/supplierGetOrderNegotiationsAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await supplierNegotiationHistoryApi(filterObj),
      thunkAPI
    });
  }
);

// supplier answer negotiation action
export const supplierAnswerOrderNegotiationAction = createAsyncThunk(
  'companyOrders/supplierAnswerOrderNegotiationAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await supplierAnswerNegotiationApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
