/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomImage from '../../common/custom-image/CustomImage';
import imgsSrcs from '../../helpers/imgsSrcs';
import {
  addProductToCartAction,
  incDecProductQty,
  removeCartProductAction,
  setProductQty
} from '../../redux/cart-slice/cartSlice';
import { setSelectedSupplierProduct } from '../../redux/companies-slice/companiesSlice';
import './SelectedSupplierProduct.scss';

const SelectedSupplierProduct = () => {
  // const menuRef = useRef(null);
  // const [listening, setListening] = useState(false);

  //
  //
  //
  //
  //
  const params = useParams();
  const { i18n, t } = useTranslation();
  const sharedT = (key) => t(`supplierProductsPage.${key}`);
  const dispatch = useDispatch();
  const selectedSupplierProduct = useSelector(
    (store) => store.companies.selectedSupplierProduct
  );

  useEffect(() => {
    if (selectedSupplierProduct) document.body.style.overflowY = 'hidden';
    return () => (document.body.style.overflowY = 'auto');
  }, [selectedSupplierProduct]);
  const cartServiceId = useSelector((store) => store.cart.cart.cartServiceId);
  const cartProducts = useSelector((store) => store.cart.cart.cartProducts);
  const cartTotal = useSelector((store) => store.cart.cart.cartTotal);
  const [selectedProductOption, setSelectedProductOption] = useState(null);
  // useEffect(
  //   listenForOutsideClicks(listening, setListening, menuRef, (v) => {
  // setSelectedProductOption(null);
  // dispatch(setSelectedSupplierProduct(v));
  //   })
  // );

  const renderOptionsList = (arr) => {
    return arr.map((item) => {
      return (
        <button
          type="button"
          key={item?.id}
          onClick={(e) => {
            e.stopPropagation();
            setSelectedProductOption(item);
          }}
          className={`option-btn ${
            selectedProductOption?.id === item?.id ? 'selected' : ''
          }`}
        >
          {item.name}
        </button>
      );
    });
  };

  const [foundItemInCartWithSameOption, setFoundItemInCartWithSameOption] =
    useState(null);

  useEffect(() => {
    if (selectedSupplierProduct) {
      const found =
        cartServiceId === selectedSupplierProduct?.service?.id &&
        cartProducts?.length > 0 &&
        cartProducts.find(
          (cartProObj) =>
            cartProObj?.product_id === selectedSupplierProduct?.id &&
            cartProObj?.option?.id === selectedProductOption?.id
        );

      setFoundItemInCartWithSameOption(found);
    }
  }, [selectedSupplierProduct, selectedProductOption, cartProducts]);

  const renderFooter = () => {
    // check if product in cart and with the same options
    // => then inc or dec is available
    if (
      // isProuctInCart(cartProducts) &&
      foundItemInCartWithSameOption
    ) {
      let productPrice = foundItemInCartWithSameOption.price
        ? parseFloat(foundItemInCartWithSameOption.price)
        : 0;
      let productOptionPrice = foundItemInCartWithSameOption?.option?.price
        ? parseFloat(foundItemInCartWithSameOption.option.price)
        : 0;
      let productQty = foundItemInCartWithSameOption.quantity || 0;
      return (
        <div className="price-inc-dec-qty-wrapper">
          <div className="price">
            <div className="title">{sharedT('totalPrice')}</div>
            <div className="value">
              {/* {cartTotal} {t('currency.sar')} */}
              {productQty * (productPrice + productOptionPrice)}

              {t('currency.sar')}
            </div>
          </div>

          <div className="action-btns">
            <button
              disabled={foundItemInCartWithSameOption?.quantity === 1}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(
                  incDecProductQty({
                    btnType: 'dec',
                    product_id: foundItemInCartWithSameOption?.product_id,
                    option_id: foundItemInCartWithSameOption?.option?.id
                  })
                );
              }}
              type="button"
              className="action-btn dec-btn"
            >
              -
            </button>
            <input
              className="input-qty"
              type="text"
              value={foundItemInCartWithSameOption?.quantity}
              onChange={handleQtyInputChange}
            />
            {/* <div className="qty">{foundItemInCartWithSameOption?.quantity}</div> */}
            <button
              onClick={(e) => {
                e.stopPropagation();
                dispatch(
                  incDecProductQty({
                    btnType: 'inc',
                    product_id: foundItemInCartWithSameOption?.product_id,
                    option_id: foundItemInCartWithSameOption?.option?.id
                  })
                );
              }}
              type="button"
              className="action-btn inc-btn"
            >
              +
            </button>
          </div>

          <button
            onClick={() => {
              dispatch(
                removeCartProductAction({
                  product_id: foundItemInCartWithSameOption?.product_id,
                  option_id: foundItemInCartWithSameOption?.option?.id
                })
              );
            }}
            type="button"
            className="remove-btn"
          >
            <img src={imgsSrcs?.trashImg} alt="trash" />
          </button>
        </div>
      );
    }

    return (
      <button
        className="cart-btn"
        onClick={() => {
          dispatch(
            addProductToCartAction({
              supplierId: params?.company_id,
              serviceId: selectedSupplierProduct?.service?.id,
              cartServiceName: selectedSupplierProduct?.service?.name,
              product: {
                product_id: selectedSupplierProduct?.id,
                name: selectedSupplierProduct?.name,
                image: selectedSupplierProduct?.image,
                quantity: 1,
                price: selectedSupplierProduct?.price,
                unit: selectedSupplierProduct?.unit,
                option: selectedProductOption
              },
              cartTotal: 0
              // selectedSupplierProduct?.price + selectedProductOption?.price
              //   ? parseFloat(selectedProductOption.price)
              //   : 0
            })
          );
        }}
      >
        {sharedT('addToCart')}
      </button>
    );
  };

  const handleQtyInputChange = (e) => {
    if (
      e?.target?.value &&
      Number(e.target.value) &&
      Number.isInteger(Number(e.target.value)) &&
      !isNaN(Number(e.target.value))
    ) {
      dispatch(
        setProductQty({
          qty: parseInt(e.target.value),
          product_id: foundItemInCartWithSameOption?.product_id,
          option_id: foundItemInCartWithSameOption?.option?.id
        })
      );
    } else if (!e.target.value) {
      dispatch(
        removeCartProductAction({
          product_id: foundItemInCartWithSameOption?.product_id,
          option_id: foundItemInCartWithSameOption?.option?.id
        })
      );
    }
  };

  return (
    <AnimatePresence>
      {selectedSupplierProduct && (
        <motion.div
          onClick={() => {
            setSelectedProductOption(null);
            dispatch(setSelectedSupplierProduct(null));
          }}
          initial={{
            opacity: 0
          }}
          animate={{
            opacity: 1,
            transition: {
              opacity: { ease: 'linear', duration: 0.4 }
            }
          }}
          exit={{
            opacity: 0,
            transitionDelay: 1,
            animationDelay: 1,

            transition: {
              delay: 0.2,
              opacity: { ease: 'easeOut', duration: 0.4 }
            }
          }}
          className={`selected-supplier-product-wrapper ${i18n.dir()}`}
        >
          <motion.div
            // ref={menuRef}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            initial={{
              translateX: i18n.dir() === 'ltr' ? 600 : -600
            }}
            animate={{
              translateX: 0,
              transition: {
                delay: 0.2,
                duration: 0.4,
                ease: 'linear'
              }
            }}
            exit={{
              translateX: i18n.dir() === 'ltr' ? 600 : -600,
              transition: {
                duration: 0.4,
                ease: 'linear'
              }
            }}
            className="selected-supplier-product"
            // ref={menuRef}
          >
            <div className="selected-supplier-product-with-overflow">
              <div className="box-header">
                {sharedT('productDetails')}

                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    // handleClose();
                    setSelectedProductOption(null);
                    dispatch(setSelectedSupplierProduct(null));
                  }}
                  className="back-btn"
                >
                  {i18n.dir() === 'ltr' ? (
                    <img src={imgsSrcs?.arrowRightImg} alt="" />
                  ) : (
                    <img src={imgsSrcs?.arrowLeftImg} alt="" />
                  )}
                </button>
              </div>

              <div className="box-body">
                <CustomImage
                  src={selectedSupplierProduct.image}
                  className="p-img"
                />

                <div className="name-price">
                  <div className="name">{selectedSupplierProduct?.name}</div>
                  <div className="price">
                    {selectedSupplierProduct.price} {t('currency.sar')}
                    <span
                      style={{
                        color: '#668199'
                      }}
                    >
                      {' '}
                      / {selectedSupplierProduct.unit?.name}
                    </span>
                  </div>
                </div>

                <div className="desc">{selectedSupplierProduct?.desc}</div>

                {selectedSupplierProduct?.options?.length > 0 && (
                  <>
                    <p className="one-option-title">
                      {sharedT('selectOnOption')}
                    </p>
                    <div className="options-list">
                      {renderOptionsList(selectedSupplierProduct.options)}
                    </div>
                  </>
                )}
              </div>

              <div className="box-footer">{renderFooter()}</div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SelectedSupplierProduct;
