import axios from 'axios';
import apisUrls from '../apisUrls';

const userAnswerNegotiationApi = async (values) => {
  return axios.post(apisUrls.userAnswerNegotiation, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export default userAnswerNegotiationApi;
