import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../../common/confirm-modal/ConfirmModal';
import confirmModalTypes from '../../const-values/confirmModalTypes';
import {
  answerNegotiateStatus,
  userOfferStatus
} from '../../const-values/orderStatus';
import {
  setAcceptCompOfferModalOpenedAction,
  setCancelOrderModalOpenedAction,
  setConfirmNegotiateModalOpened,
  setRejectNegotiateModalOpened,
  setRejectCompOfferModalOpenedAction
} from '../../redux/user-orders-slice/userOrdersSlice';
import {
  userAnswerOfferAction,
  userAnswerOrderNegotiationAction,
  userCancelOrderAction
} from '../../redux/user-orders-slice/userOrdersSliceThunkApi';

const ConfirmModals = () => {
  const dispatch = useDispatch();
  const sharedT = (key) => t(`ordersPage.${key}`);

  const selectedOffer = useSelector((store) => store.userOrders.selectedOffer);
  const selectedCompanyOffer = useSelector(
    (store) => store.userOrders.selectedCompanyOffer
  );
  const acceptCompOfferModalOpened = useSelector(
    (store) => store.userOrders.acceptCompOfferModalOpened
  );
  const rejectCompOfferModalOpened = useSelector(
    (store) => store.userOrders.rejectCompOfferModalOpened
  );
  const selectedNegotiation = useSelector(
    (store) => store.userOrders.selectedNegotiation
  );

  let isMounted = true;
  const handleCancelOrder = () => {
    if (isMounted && fetchedUserSingleOrder?.id) {
      const formData = new FormData();
      formData.append('order_id', fetchedUserSingleOrder.id);
      dispatch(
        userCancelOrderAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };
  const handleAcceptCompOffer = () => {
    if (isMounted && selectedCompanyOffer?.id) {
      const formData = new FormData();
      formData.append('offer_id', selectedCompanyOffer.id);
      formData.append('status', userOfferStatus?.accepted);
      dispatch(
        userAnswerOfferAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };
  const handleRejectCompOffer = () => {
    if (isMounted && selectedCompanyOffer?.id) {
      const formData = new FormData();
      formData.append('offer_id', selectedCompanyOffer.id);
      formData.append('status', userOfferStatus?.rejected);
      dispatch(
        userAnswerOfferAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  const handleAcceptNego = () => {
    if (isMounted && selectedNegotiation?.id) {
      const formData = new FormData();
      formData.append('negotiate_id', selectedNegotiation.id);
      formData.append('status', answerNegotiateStatus?.confirm);
      dispatch(
        userAnswerOrderNegotiationAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  const handleRejectNego = () => {
    if (isMounted && selectedNegotiation?.id) {
      const formData = new FormData();
      formData.append('negotiate_id', selectedNegotiation.id);
      formData.append('status', answerNegotiateStatus?.reject);
      dispatch(
        userAnswerOrderNegotiationAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  const cancelOrderModalOpened = useSelector(
    (store) => store.userOrders.cancelOrderModalOpened
  );
  // const acceptOrderModalOpened = useSelector(
  //   (store) => store.userOrders.acceptOrderModalOpened
  // );
  // const rejectOrderModalOpened = useSelector(
  //   (store) => store.userOrders.rejectOrderModalOpened
  // );
  const confirmNegotiateModalOpened = useSelector(
    (store) => store.userOrders.confirmNegotiateModalOpened
  );
  const rejectNegotiateModalOpened = useSelector(
    (store) => store.userOrders.rejectNegotiateModalOpened
  );
  const fetchedUserSingleOrder = useSelector(
    (store) => store.userOrders.fetchedUserSingleOrder
  );

  return (
    <>
      {/*  */}
      {/* cancel modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={cancelOrderModalOpened}
        modalType={confirmModalTypes?.errorModal}
        setModalOpened={(v) => {
          dispatch(setCancelOrderModalOpenedAction(v));
        }}
        handleAccept={handleCancelOrder}
      >
        <h3 style={{ fontWeight: 'bold' }}>{`${sharedT('cancelOrder')} #${
          fetchedUserSingleOrder?.id || ''
        }`}</h3>
      </ConfirmModal>
      {/* end cancel modal */}

      {/* accept company offer modal */}
      <ConfirmModal
        style={{
          zIndex: 99999
        }}
        className="max-z"
        modalTitle=""
        modalOpened={acceptCompOfferModalOpened}
        modalType={confirmModalTypes?.successModal}
        setModalOpened={(v) => {
          dispatch(setAcceptCompOfferModalOpenedAction(v));
        }}
        handleAccept={handleAcceptCompOffer}
      >
        <h3 style={{ fontWeight: 'bold' }}>{`${sharedT('acceptOffer')} #${
          selectedCompanyOffer?.id || ''
        }`}</h3>
      </ConfirmModal>
      {/* end accept order modal */}

      {/* reject company offer modal */}
      <ConfirmModal
        style={{
          zIndex: 99999
        }}
        className="max-z"
        modalTitle=""
        modalOpened={rejectCompOfferModalOpened}
        modalType={confirmModalTypes?.errorModal}
        setModalOpened={(v) => {
          dispatch(setRejectCompOfferModalOpenedAction(v));
        }}
        handleAccept={handleRejectCompOffer}
      >
        <h3 style={{ fontWeight: 'bold' }}>{`${sharedT('rejectOffer')} #${
          selectedCompanyOffer?.id || ''
        }`}</h3>
      </ConfirmModal>
      {/* end reject order modal */}

      {/* confirm nego modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={confirmNegotiateModalOpened}
        modalType={confirmModalTypes?.successModal}
        setModalOpened={(v) => {
          dispatch(setConfirmNegotiateModalOpened(v));
        }}
        handleAccept={handleAcceptNego}
      >
        <h3 style={{ fontWeight: 'bold' }}>
          {`${sharedT('sureAcceptNego')} ${
            fetchedUserSingleOrder?.lastNegotiate?.price
          }`}
          <span className="curr-span">{t('currency.sar')}</span>
        </h3>
      </ConfirmModal>
      {/* end confirm nego modal */}

      {/* reject nego modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={rejectNegotiateModalOpened}
        modalType={confirmModalTypes?.errorModal}
        setModalOpened={(v) => {
          dispatch(setRejectNegotiateModalOpened(v));
        }}
        handleAccept={handleRejectNego}
      >
        <h3 style={{ fontWeight: 'bold' }}>
          {`${sharedT('sureRejectNego')} ${
            fetchedUserSingleOrder?.lastNegotiate?.price
          }`}
          <span className="curr-span">{t('currency.sar')}</span>
        </h3>
      </ConfirmModal>
      {/* end reject nego modal */}
    </>
  );
};

export default ConfirmModals;
