/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import './CompanyOrdersPage.scss';
import {
  LoadingOutlined,
  RightOutlined,
  LeftOutlined
} from '@ant-design/icons';
import { companyOrderStatusArr } from '../../const-values/orderStatus';
import { t } from 'i18next';
import CustomBreadcrumb from '../../common/bread-crumb/Breadcrumb';
import routerLinks from '../../components/app/routerLinks';
import CompanyOrderCard from './CompanyOrderCard';
import { useTranslation } from 'react-i18next';
import { getComapnyOrdersAction } from '../../redux/company-orders-slice/companyOrdersSliceThunkApi';
import merchentRouterLinks from '../../components/app/merchent-routes/merchentRouterLinks';
import CustomEmpty from '../../common/custom-empty/CustomEmpty';
import { Pagination } from 'antd';

const CompanyOrdersPage = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const user = useSelector((store) => store.user.user);
  const isLoadingCompanyOrders = useSelector(
    (store) => store.companyOrders.isLoadingCompanyOrders
  );
  const fetchedCompanyOrders = useSelector(
    (store) => store.companyOrders.fetchedCompanyOrders
  );
  const companyOrdersPagination = useSelector(
    (store) => store.companyOrders.companyOrdersPagination
  );

  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [search]);

  useEffect(() => {
    dispatch(
      getComapnyOrdersAction({
        filterObj: {
          ...values
        }
      })
    );
  }, [search, i18n.language]);

  useEffect(() => {
    if (!values?.type)
      navigate(`${merchentRouterLinks?.companyOrdersRoute}?type=&page=1`);
  }, []);

  const renderSideNavigation = () => {
    return (
      companyOrderStatusArr(user, t, null)?.length > 0 &&
      companyOrderStatusArr(user, t, null).map((obj) => {
        return (
          <Link
            className={`order-link ${
              values?.type == obj?.status ? 'active' : ''
            }`}
            key={obj?.objKey}
            to={obj?.url}
          >
            {obj?.name}
          </Link>
        );
      })
    );
  };

  const renderOrderCards = () => {
    if (isLoadingCompanyOrders) {
      return (
        <div
          style={{
            height: 332,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <LoadingOutlined />
        </div>
      );
    } else if (!fetchedCompanyOrders || fetchedCompanyOrders?.length === 0) {
      return (
        <CustomEmpty>
          <h2
            style={{
              margin: '18px auto',
              textAlign: 'center'
            }}
          >
            No Orders found
          </h2>
        </CustomEmpty>
      );
    } else if (fetchedCompanyOrders?.length > 0) {
      return (
        <div className="comp-orders-list">
          {fetchedCompanyOrders.map((card) => {
            return (
              <CompanyOrderCard
                key={card?.id}
                card={{
                  ...card,
                  user,
                  to: merchentRouterLinks?.companySingleOrderRoute(card?.id)
                }}
              />
            );
          })}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="comp-orders-page">
      <CustomBreadcrumb
        arr={[
          {
            title: t('breadcrumbs.home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: t('breadcrumbs.userOrders'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        {/* <p className="page-title">{t('ordersPage.pageTitle')}</p> */}
        <div className="page-layout">
          <div className="side-navigation">
            {renderSideNavigation()}
            <a
              className={`order-link`}
              target="_blank"
              rel="noreferrer"
              href="https://api.benaline.com/company/login"
            >
              + {t('add_product')}
            </a>
          </div>

          <div className="outside-content">
            <div className="page-content">
              {renderOrderCards()}

              {!isLoadingCompanyOrders &&
                companyOrdersPagination?.total > 0 && (
                  <div className="pagination-wrapper">
                    <Pagination
                      nextIcon={
                        i18n.dir() === 'ltr' ? (
                          <RightOutlined />
                        ) : (
                          <LeftOutlined />
                        )
                      }
                      prevIcon={
                        i18n.dir() === 'ltr' ? (
                          <LeftOutlined />
                        ) : (
                          <RightOutlined />
                        )
                      }
                      locale={{
                        jump_to: i18n?.language === 'ar' ? 'إذهب إلى' : 'Go to',
                        // page: i18n?.language === 'ar' ? 'الصفحة' : 'Page'
                        page: ''
                      }}
                      showSizeChanger={false}
                      defaultCurrent={1}
                      current={companyOrdersPagination.current_page}
                      pageSize={companyOrdersPagination.per_page}
                      total={companyOrdersPagination.total}
                      // itemRender={itemRender}
                      onChange={(page, pageSize) => {
                        navigate(
                          `${merchentRouterLinks?.companyOrdersRoute}?type=${
                            values?.type || ''
                          }&page=${page || 1}`
                        );
                      }}
                      hideOnSinglePage={true}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyOrdersPage;
