import { Modal, Rate } from 'antd';
import { t } from 'i18next';
import { LoadingOutlined } from '@ant-design/icons';
import './RatesModal.scss';
import CustomImage from '../../common/custom-image/CustomImage';

const RateCard = ({ card }) => {
  return (
    <div className="rate-card">
      <div className="img-name-rate-wrapper">
        <div className="img-name-date">
          <CustomImage className="rate-img" src={card?.image} />

          <div className="name-date">
            {card?.name && <p className="name-p">{card.name}</p>}
            {card?.created_at && <p className="date-p">{card.created_at}</p>}
          </div>
        </div>

        <Rate
          className="rate"
          disabled
          allowClear={false}
          allowHalf={true}
          value={card?.rate || 0}
        />
      </div>
      {card?.comment && <p className="comment-p">{card.comment}</p>}
    </div>
  );
};

const RatesModal = ({
  modalOpened,
  setModalOpened,
  isLoadingCompanyRate,
  fetchedCompanyRate
}) => {
  const renderList = () => {
    if (isLoadingCompanyRate) {
      return (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 332
            }}
          >
            <LoadingOutlined style={{ fontSize: 20 }} />
          </div>
        </div>
      );
    } else if (fetchedCompanyRate?.length === 0) {
      return (
        <div className="no-rates-wrapper">
          <h2>{t('singleCompPage.noRates')}</h2>
        </div>
      );
    } else if (fetchedCompanyRate?.length > 0) {
      return (
        <div className="rates-list">
          {fetchedCompanyRate.map((obj) => (
            <RateCard key={obj?.id} card={obj} />
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <Modal
      className="shared-custom-modal rates-modal"
      width="96%"
      style={{ maxWidth: '842px' }}
      title={t('singleCompPage.rates')}
      open={modalOpened}
      onOk={() => {
        setModalOpened(false);
      }}
      onCancel={() => {
        setModalOpened(false);
      }}
      footer={false}
    >
      {renderList()}
    </Modal>
  );
};

export default RatesModal;
