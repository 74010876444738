import { t } from 'i18next';
import { Link } from 'react-router-dom';
import CustomImage from '../../common/custom-image/CustomImage';
import { threeOrderStatusArr } from '../../const-values/orderStatus';
import imgsSrcs from '../../helpers/imgsSrcs';
import './CompanyOrderCard.scss';

const CompanyOrderCard = ({ card }) => {
  const renderOrderStatus = () => {
    const found = threeOrderStatusArr(t).find((obj) => {
      return obj?.value === card?.status;
    });
    if (found) {
      return (
        <div
          className={`order-status-tag ${
            !card?.status ? 'all' : `_${card.status}`
          }`}
        >
          {found.name}
        </div>
      );
    }
    return null;
  };

  return (
    <Link className="company-order-card" to={card?.to || '/'}>
      <div className="card-body">
        <CustomImage src={card?.service?.logo} className="order-img" />

        <div className="order-data">
          <div className="service-comp">
            {card?.service?.name && (
              <div className="order-service">{card.service.name}</div>
            )}
            {card?.subject && <div className="subj">{card.subject}</div>}
            {card?.client?.name && (
              <div className="comp-name">
                {t('ordersPage.by')} {card?.client?.name}
              </div>
            )}
          </div>

          <div className="address">
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.9999 12.3089C8.2249 12.3089 6.7749 10.8672 6.7749 9.08385C6.7749 7.30052 8.2249 5.86719 9.9999 5.86719C11.7749 5.86719 13.2249 7.30885 13.2249 9.09219C13.2249 10.8755 11.7749 12.3089 9.9999 12.3089ZM9.9999 7.11719C8.91657 7.11719 8.0249 8.00052 8.0249 9.09219C8.0249 10.1839 8.90824 11.0672 9.9999 11.0672C11.0916 11.0672 11.9749 10.1839 11.9749 9.09219C11.9749 8.00052 11.0832 7.11719 9.9999 7.11719Z"
                fill="#246DB4"
              />
              <path
                d="M9.9999 19.466C8.76657 19.466 7.5249 18.9993 6.55824 18.0744C4.0999 15.7077 1.38324 11.9327 2.40824 7.44102C3.33324 3.36602 6.89157 1.54102 9.9999 1.54102C9.9999 1.54102 9.9999 1.54102 10.0082 1.54102C13.1166 1.54102 16.6749 3.36602 17.5999 7.44935C18.6166 11.941 15.8999 15.7077 13.4416 18.0744C12.4749 18.9993 11.2332 19.466 9.9999 19.466ZM9.9999 2.79102C7.5749 2.79102 4.45824 4.08268 3.63324 7.71602C2.73324 11.641 5.1999 15.0243 7.43324 17.166C8.8749 18.5577 11.1332 18.5577 12.5749 17.166C14.7999 15.0243 17.2666 11.641 16.3832 7.71602C15.5499 4.08268 12.4249 2.79102 9.9999 2.79102Z"
                fill="#58B030"
              />
            </svg>

            {card?.client?.country?.name && `${card.client.country.name}`}
            {card?.client?.city?.name && ` / ${card.client.city.name}`}
          </div>
        </div>
        {renderOrderStatus()}
      </div>

      <div className="card-footer">
        <div className="order-client-items">
          <div className="client-name">
            <img src={imgsSrcs?.emptyUserImg} alt="user" />
            {card?.client?.name}
          </div>

          <div className="orders-count-price">
            {/* {card?.totalPrice ? (
              <div className="card-price">
                {card?.totalPrice}
                <span className="curr">{t('currency.sar')} - </span>
              </div>
            ) : null} */}
            {card?.products?.length} {t('cartPage.items')}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CompanyOrderCard;
