import { createSlice } from '@reduxjs/toolkit';
import checkRes from '../../utils/checkRes';
import {
  contractorAnswerOfferAction,
  getComapnyOrdersAction,
  getCompanySingleOrdersAction,
  getContractorOrderOffersOfferAction,
  makeOfferAction,
  supplierAcceptRejectOrderAction,
  supplierAnswerOrderNegotiationAction,
  supplierGetOrderNegotiationsAction
} from './companyOrdersSliceThunkApi';

const initialState = {
  isLoadingCompanyOrders: false,
  fetchedCompanyOrders: [],
  companyOrdersPagination: {
    current_page: 1,
    par_page: 0,
    total: 0
  },
  isLoadingCompanySingleOrder: false,
  fetchedCompanySingleOrder: null,
  fetchSingleOrderCount: 0,
  /////////////////////////////
  /////////////////////////////
  suppNegotiationssModalOpened: false,
  isLoadingOrderOffers: false,
  offerModalOpened: false,
  contractorOffersModalOpened: false,
  isSubmittingOffer: false,
  isSubmittingContractorOffer: false,
  acceptContractorOfferModalOpened: false,
  rejectContractorOfferModalOpened: false,
  isLoadingContractorOffers: false,
  fetchedContractorOffers: null,
  selectedContractorOffer: null,
  /////////////////////////////
  isLoadingSuppOrderNegotiations: false,
  // supplier negotiation
  acceptOrderModalOpened: false,
  rejectOrderModalOpened: false,
  supplierOrderNegotations: [],
  contractorOfferFormModalOpened: false,
  supplierSelectedNegotiation: null,
  /////////////////
  ////////////////
  confirmNegotiateModalOpened: false,
  rejectNegotiateModalOpened: false,
  //////////////
  suppNegotiationPriceFormOpened: false,
};

const companyOrdersSlice = createSlice({
  name: 'comanyOrders',
  initialState,
  reducers: {
    setOfferModalOpenedAction: (sliceState, action) => {
      sliceState.offerModalOpened = action.payload;
    },
    setFetchedCompanySingleOrderAction: (sliceState, action) => {
      sliceState.fetchedCompanySingleOrder = action.payload;
    },
    /////////////
    /////////////
    /////////////
    setAcceptOrderModalOpened: (sliceState, action) => {
      sliceState.acceptOrderModalOpened = action.payload;
    },
    setRejectOrderModalOpened: (sliceState, action) => {
      sliceState.rejectOrderModalOpened = action.payload;
    },
    setSuppNegotiationsModalOpenedAction: (sliceState, action) => {
      sliceState.suppNegotiationssModalOpened = action.payload;
    },
    setConfirmNegotiateModalOpened: (sliceState, action) => {
      sliceState.confirmNegotiateModalOpened = action.payload;
    },
    setRejectNegotiateModalOpened: (sliceState, action) => {
      sliceState.rejectNegotiateModalOpened = action.payload;
    },
    setSelecteSupplierdNeotiationAction: (sliceState, action) => {
      sliceState.supplierSelectedNegotiation = action.payload;
    },
    setSuppNegotiationPriceFormOpened: (sliceState, action) => {
      sliceState.suppNegotiationPriceFormOpened = action.payload;
    },
    setSelectedContractorOffer: (sliceState, action) => {
      sliceState.selectedContractorOffer = action.payload
    },
    setAcceptContractorOfferModalOpened: (sliceState, action) => {
      sliceState.acceptContractorOfferModalOpened = action.payload
    },
    setRejectContractorOfferModalOpened: (sliceState, action) => {
      sliceState.rejectContractorOfferModalOpened = action.payload
    },
    setContractorOffersModalOpened: (sliceState, action) => {
      sliceState.contractorOffersModalOpened = action.payload
    },

    setContractorOfferFormModalOpenedAction: (sliceState, action) => {
      sliceState.contractorOfferFormModalOpened = action.payload
    },

  },
  extraReducers: {
    // get company orders
    [getComapnyOrdersAction.pending]: (state) => {
      state.isLoadingCompanyOrders = true;
    },
    [getComapnyOrdersAction.fulfilled]: (state, action) => {
      state.isLoadingCompanyOrders = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedCompanyOrders = data?.data;
      if (data?.pagination) state.companyOrdersPagination = data.pagination;
      // if (data?.pagination) state.tablePagination = data.pagination;
    },
    [getComapnyOrdersAction.rejected]: (state, action) => {
      state.isLoadingCompanyOrders = false;
    },
    // get single company order
    [getCompanySingleOrdersAction.pending]: (state) => {
      state.isLoadingCompanySingleOrder = true;
    },
    [getCompanySingleOrdersAction.fulfilled]: (state, action) => {
      state.isLoadingCompanySingleOrder = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedCompanySingleOrder = data;
      // if (data?.pagination) state.tablePagination = data.pagination;
    },
    [getCompanySingleOrdersAction.rejected]: (state, action) => {
      state.isLoadingCompanySingleOrder = false;
    },
    //
    // make offer action
    [makeOfferAction.pending]: (state) => {
      state.isSubmittingOffer = true;
    },
    [makeOfferAction.fulfilled]: (state, action) => {
      state.isSubmittingOffer = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.fetchSingleOrderCount += 1;
        state.offerModalOpened = false;
      }
    },


    //contractor get order company offers action
    [getContractorOrderOffersOfferAction.pending]: (state) => {
      state.isLoadingContractorOffers = true;
    },
    [getContractorOrderOffersOfferAction.fulfilled]: (state, action) => {
      state.isLoadingContractorOffers = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        const data = action?.payload?.res?.data?.data;
        state.fetchedContractorOffers = data;
      }
    },
    [getContractorOrderOffersOfferAction.rejected]: (state) => {
      state.isLoadingContractorOffers = false;
    },
    //contractor answer offer action
    [contractorAnswerOfferAction.pending]: (state) => {
      state.isSubmittingContractorOffer = true;
    },
    [contractorAnswerOfferAction.fulfilled]: (state, action) => {
      state.isSubmittingContractorOffer = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.selectedContractorOffer = null;
        state.fetchSingleOrderCount += 1;
        state.contractorOffersModalOpened = false;
        state.acceptContractorOfferModalOpened = false;
        state.rejectContractorOfferModalOpened = false;
      }
    },
    [contractorAnswerOfferAction.rejected]: (state) => {
      state.isSubmittingContractorOffer = false;
    },
    //
    //
    //
    //
    //
    //supplier accept reject order action
    [supplierAcceptRejectOrderAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [supplierAcceptRejectOrderAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.fetchSingleOrderCount += 1;
        state.acceptOrderModalOpened = false;
        state.rejectOrderModalOpened = false;
      }
    },
    [supplierAcceptRejectOrderAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    //
    //
    //supplier get order negotiations action
    [supplierGetOrderNegotiationsAction.pending]: (state) => {
      state.isLoadingSuppOrderNegotiations = true;
    },
    [supplierGetOrderNegotiationsAction.fulfilled]: (state, action) => {
      state.isLoadingSuppOrderNegotiations = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.isLoadingSuppOrderNegotiations = false;
        const data = action?.payload?.res?.data?.data;
        state.supplierOrderNegotations = data;
      }
    },
    [supplierGetOrderNegotiationsAction.rejected]: (state) => {
      state.isLoadingSuppOrderNegotiations = false;
    },
    //
    // supplier answer order nego
    [supplierAnswerOrderNegotiationAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [supplierAnswerOrderNegotiationAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.fetchSingleOrderCount += 1;
        state.confirmNegotiateModalOpened = false;
        state.rejectNegotiateModalOpened = false;
      }
    },
    [supplierAnswerOrderNegotiationAction.rejected]: (state) => {
      state.isSubmitting = false;
    }
  }
});

const companyOrdersReducer = companyOrdersSlice.reducer;

export const {
  setOfferModalOpenedAction,
  setFetchedCompanySingleOrderAction,
  setAcceptOrderModalOpened,
  setRejectOrderModalOpened,
  setConfirmNegotiateModalOpened,
  setRejectNegotiateModalOpened,
  setSuppNegotiationsModalOpenedAction,
  setSelecteSupplierdNeotiationAction,
  setSuppNegotiationPriceFormOpened,
  setContractorOffersModalOpened,
  setContractorOfferFormModalOpenedAction,
  setSelectedContractorOffer,
  setAcceptContractorOfferModalOpened,
  setRejectContractorOfferModalOpened
} = companyOrdersSlice.actions;

export default companyOrdersReducer;
