/* eslint-disable eqeqeq */
import * as Yup from 'yup';

import quoteTypes from '../../const-values/quoteTypes';

const getQuoteStepTwoValidationSchema = (quoteType, sharedErrTrans) => {
  if (
    quoteType === quoteTypes?.publicQuote ||
    quoteType === quoteTypes?.contractorQuote
  ) {
    return Yup.object().shape({
      endUserType: Yup.string().required(
        sharedErrTrans('endUserType', 'required')
      ),
      products: Yup.array()
        .min(1, 'At least one item')
        .of(
          Yup.object().shape({
            productName: Yup.string()
              .required(sharedErrTrans('productName', 'required'))
              .test(
                'products.productName',
                sharedErrTrans('productName', 'anotherProduct'),
                (v, context) => {
                  let result = true;
                  if (
                    v &&
                    context.from[1]?.value?.products?.length > 0 &&
                    context.from[1]?.value?.products.filter(
                      (p) => p.productName.toLowerCase() == v.toLowerCase()
                    )?.length > 1
                  )
                    result = false;

                  return result;
                }
              ),
            unit_id: Yup.string().required(sharedErrTrans('unit', 'required')),
            quantity: Yup.string()
              .required(sharedErrTrans('quantity', 'required'))
              .matches(
                /^(\d+)?(\.\d+)?$/,
                sharedErrTrans('quantity', 'onlyNumbers')
              )
          })
        )
    });
  } else if (quoteType === quoteTypes?.supplierQuote)
    return Yup.object().shape({});

  return null;
};

export default getQuoteStepTwoValidationSchema;
