import axios from 'axios';
import checkRes from './utils/checkRes';
import { toast } from 'react-toastify';
import apisUrls from './apis/apisUrls';

const axiosDefaults = (user, removeUser, viewNotAuthModal, langIsoCode) => {
  // default
  // axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  // axios.defaults.baseURL = "http://10.0.0.82:3001";
  axios.defaults.baseURL = 'https://api.benaline.com/api';
  // axios.defaults.baseURL = 'https://dashboardapi.mycash.sa/apiAdmin';
  // content type
  // axios.defaults.headers.post['Content-Type'] = 'application/json';
  // language
  axios.defaults.headers.common['lang'] = langIsoCode || 'en';
  // content type
  const token = user?.token || null;
  if (token) axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

  // intercepting to capture errors

  // axios.interceptors.request.use(function (request) {
  //   if (request.method === 'post' || request.method === 'POST') {
  //   }
  // });

  function getMsg(url, msg) {
    if (url === apisUrls.resendCode) return 'تم ارسال الكود الي الهاتف';
    // else if (url === apisUrls.signinUrl) return '';
    return msg || 'العمليه تمت بنجاح';
  }

  axios.interceptors.response.use(
    function (response) {
      const method = response.config.method;
      if (method === 'post' || response === 'POST') {
        if (checkRes(response)) {
          if (
            response.config.url !== apisUrls.signinUrl &&
            response.config.url !== apisUrls.createQuoteSupplier
          ) {
            toast.success(
              getMsg(response.config.url, response?.data?.message),
              {
                position: 'bottom-left',
                hideProgressBar: false,
                progress: undefined,
                autoClose: 2000,
                toastId: getMsg(response.config.url, response?.data?.message)
              }
            );
          }
        } else {
          toast.error(
            response.config.headers?.errMsg ||
              response?.data?.message ||
              'حدث خطأ حاول فى وقت لاحق',
            {
              autoClose: 2000,
              position: 'bottom-left',
              hideProgressBar: false,
              progress: undefined,
              toastId: response?.data?.message || 'حدث خطأ حاول فى وقت لاحق'
            }
          );
        }
      }
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      let message;
      switch (error?.response?.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 401: {
          // console.log('un auth : test');
          message = 'Invalid credentials';
          removeUser();
          viewNotAuthModal(false);
          break;
        }
        case 404:
          message = 'Sorry! the data you are looking for could not be found';
          break;
        default:
          message = error.message || error;
      }

      // Store.removeAllNotifications();
      // errorNotification({
      //   title: 'حدث خطأ',
      //   message: message || 'من فضلك حاول فى وقت لاحق'
      // });

      toast.error(message || 'العملية ', {
        autoClose: 2000,
        position: 'bottom-left',
        hideProgressBar: false,
        progress: undefined,
        toastId: message
      });

      return Promise.reject(message);
    }
  );
};

export default axiosDefaults;
