/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getUserOrdersAction } from '../../redux/user-orders-slice/userOrdersSliceThunkApi';
import queryString from 'query-string';
import './UserOrdersPage.scss';
import userRouterLinks from '../../components/app/user-routes/uesRouterLinks';
import {
  LoadingOutlined,
  RightOutlined,
  LeftOutlined
} from '@ant-design/icons';
import { userOrderStatusArr } from '../../const-values/orderStatus';
import { t } from 'i18next';
import CustomBreadcrumb from '../../common/bread-crumb/Breadcrumb';
import routerLinks from '../../components/app/routerLinks';
import UserOrderCard from './UserOrderCard';
import { useTranslation } from 'react-i18next';
import CustomEmpty from '../../common/custom-empty/CustomEmpty';
import { Pagination } from 'antd';

const UserOrdersPage = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const user = useSelector((store) => store.user.user);
  const isLoadingUserOrders = useSelector(
    (store) => store.userOrders.isLoadingUserOrders
  );
  const fetchedUserOrders = useSelector(
    (store) => store.userOrders.fetchedUserOrders
  );
  const userOrdersPagination = useSelector(
    (store) => store.userOrders.userOrdersPagination
  );

  useEffect(() => {
    dispatch(
      getUserOrdersAction({
        filterObj: {
          ...values
        }
      })
    );
  }, [search, i18n.language]);

  const renderSideNavigation = () => {
    return (
      userOrderStatusArr(user, t, null)?.length > 0 &&
      userOrderStatusArr(user, t, null).map((obj) => {
        return (
          <Link
            className={`order-link ${
              values?.type == obj?.status ? 'active' : ''
            }`}
            key={obj?.objKey}
            to={obj?.url}
          >
            {obj?.name}
          </Link>
        );
      })
    );
  };

  const renderOrderCards = () => {
    if (isLoadingUserOrders) {
      return (
        <div
          style={{
            height: 332,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <LoadingOutlined />
        </div>
      );
    } else if (!fetchedUserOrders || fetchedUserOrders?.length === 0) {
      return (
        <CustomEmpty>
          <h2
            style={{
              margin: '18px auto',
              textAlign: 'center'
            }}
          >
            No Orders found
          </h2>
        </CustomEmpty>
      );
    } else if (fetchedUserOrders?.length > 0) {
      return (
        <div className="user-orders-list">
          {fetchedUserOrders.map((card) => {
            return (
              <UserOrderCard
                key={card?.id}
                card={{
                  ...card,
                  user,
                  to: userRouterLinks?.userSingleOrderRoute(card?.id)
                }}
              />
            );
          })}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="user-orders-page">
      <CustomBreadcrumb
        arr={[
          {
            title: t('breadcrumbs.home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: t('breadcrumbs.userOrders'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <p className="page-title">{t('ordersPage.pageTitle')}</p>
        <div className="page-layout">
          <div className="side-navigation">{renderSideNavigation()}</div>

          <div className="outside-content">
            <div className="page-content">
              {renderOrderCards()}

              {userOrdersPagination?.total > 0 && !isLoadingUserOrders && (
                <div className="pagination-wrapper">
                  <Pagination
                    nextIcon={
                      i18n.dir() === 'ltr' ? (
                        <RightOutlined />
                      ) : (
                        <LeftOutlined />
                      )
                    }
                    prevIcon={
                      i18n.dir() === 'ltr' ? (
                        <LeftOutlined />
                      ) : (
                        <RightOutlined />
                      )
                    }
                    locale={{
                      jump_to: i18n?.language === 'ar' ? 'إذهب إلى' : 'Go to',
                      // page: i18n?.language === 'ar' ? 'الصفحة' : 'Page'
                      page: ''
                    }}
                    showSizeChanger={false}
                    defaultCurrent={1}
                    current={userOrdersPagination.current_page}
                    pageSize={userOrdersPagination.per_page}
                    total={userOrdersPagination.total}
                    // itemRender={itemRender}
                    onChange={(page, pageSize) => {
                      navigate(
                        `${userRouterLinks?.userOrdersRoute}?type=${
                          values?.type || ''
                        }&page=${page || 1}`
                      );
                    }}
                    hideOnSinglePage={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserOrdersPage;
