import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ExploreContractorsSection from './ExploreContractorsSection';
import ExploreSuppliersSection from './ExploreSuppliersSection';
import FaqSection from './FaqSection';
import HomeContactSection from './HomeContactSection';
import HomeCountersSection from './HomeCountersSection';
import HomeDevelopmentSection from './HomeDevelopmentSection';
import HomeRequestsSection from './HomeRequestsSection';
import HomeSlider from './HomeSlider';
import TopRatedSection from './TopRatedSection';
import WorksSection from './WorksSection';
import successCheckImg from '../../assets/imgs/success-check.png';
import SuccessCard from '../../components/success-card/SuccessCard';
import { setCurrentUserAction } from '../../redux/user-slice/userSlice';

const HomePage = () => {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user.user);
  const { t } = useTranslation();
  const sharedSuccessT = (key) => t(`successSignup.${key}`);
  return (
    <div className="home-page">
      {user?.firstTime ? (
        <div className="success-card-container">
          <div className="custom-container">
            <div className="success-card-content">
              <img src={successCheckImg} alt="success" />
              <SuccessCard
                mainTitle={sharedSuccessT('mainTitle')}
                subTitle={sharedSuccessT('userubTitle')}
                welcomeText={`${sharedSuccessT('welcome')}`}
                coloredText={`${sharedSuccessT('benaApp')}`}
                btnText={sharedSuccessT('btnText')}
                // btnUrl={routerLinks?.homePage}
                onClick={() => {
                  dispatch(
                    setCurrentUserAction({
                      ...user,
                      firstTime: false
                    })
                  );
                }}
              />
              <p className="copyright-p">{t('copyRights')}</p>
            </div>
          </div>
        </div>
      ) : (
        <>
          <HomeSlider />
          <HomeDevelopmentSection />
          <WorksSection />
          <TopRatedSection />
          <ExploreSuppliersSection />
          <ExploreContractorsSection />
          <FaqSection />
          <HomeCountersSection />
          <HomeRequestsSection />
          <HomeContactSection />
        </>
      )}
    </div>
  );
};

export default HomePage;
