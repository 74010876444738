import React, { useState } from 'react';
import { useController } from 'react-hook-form';
import { Empty, Form, Select } from 'antd';
// import ArrowDownIcon from '../icons/ArrowDownIcon';
import DropdownIcon from '../icons/DropdownIcon';
import { t } from 'i18next';
import { UserOutlined, LoadingOutlined } from '@ant-design/icons';

const AntdSelectOption = ({
  control,
  name,
  label,
  placeholder,
  setValue,
  validateStatus,
  errorMsg,
  options,
  showSearch,
  onSearch,
  filterOption,
  className,
  formClassName,
  prefix,
  // onSelect
  onChange,
  mode,
  disabled,
  withPagination = false,
  loadingMore = false,
  handleClickMore
}) => {
  const {
    // field: { ...inputProps }
    field
  } = useController({
    name,
    control
  });
  const { Option } = Select;

  const hanldeClear = () => {
    setValue(name, '');
  };
  const [focused, setFocused] = useState(false);

  const checkValue = (v) => {
    let hasValue = false;
    if (v) {
      if (typeof v === 'object' && Object.keys(v)?.length === 0) {
        hasValue = false;
      } else hasValue = true;
    } else hasValue = false;

    return hasValue;
  };

  return (
    <Form.Item
      label={label}
      help={errorMsg}
      validateStatus={validateStatus}
      // className={focused || checkValue(field.value) ? 'focused' : 'blured'}
      className={`${focused || checkValue(field.value) ? 'focused' : 'blured'}`}
    >
      <Select
        popupClassName={`${withPagination ? 'scrollable' : ''}`}
        dropdownRender={(menu) => {
          return (
            <div>
              {loadingMore ? (
                <div>
                  {menu}
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <LoadingOutlined />
                  </div>
                </div>
              ) : (
                menu
              )}
              {withPagination && (
                <div className="select-load-more-btn-wrapper">
                  <button
                    disabled={loadingMore}
                    className="select-load-more-btn"
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClickMore();
                    }}
                  >
                    {loadingMore && <LoadingOutlined />}
                    {t('loadMore')}
                  </button>
                </div>
              )}
            </div>
          );
        }}
        disabled={disabled ? disabled : false}
        onClear={hanldeClear}
        onChange={onChange && onChange()}
        mode={mode ? mode : null}
        // onChange={field.onChange}
        // onBlur={field.onBlur}
        notFoundContent={
          <Empty description={false}>لا يوجد بيانات متاحة</Empty>
        }
        {...field}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={(e) => {
          setFocused(false);
          field.onBlur(e);
        }}
        placeholder={placeholder}
        size="large"
        suffixIcon={<DropdownIcon />}
        // allowClear
        // getPopupContainer={() => document.querySelector(`.${formClassName}`)}
        showSearch={showSearch ? showSearch : false}
        filterOption={filterOption ? filterOption : true}
        onSearch={onSearch ? onSearch : null}
        className={className}
      >
        {options?.length > 0 &&
          options.map((op, index) => (
            <Option key={index} value={String(op.value)}>
              {op?.title ?? op?.name}
            </Option>
          ))}
      </Select>
    </Form.Item>
  );
};

export default AntdSelectOption;
