/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import useDigitInput from 'react-digit-input';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import imgSrc from '../../assets/imgs/send-telegram-img.png';
import ActivationCodeForm from '../../common/activation-code-form/ActivationCodeForm';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import merchentRouterLinks from '../../components/app/merchent-routes/merchentRouterLinks';
import routerLinks from '../../components/app/routerLinks';
import accountTypes from '../../const-values/accountTypes';
import { setCurrentUserAction } from '../../redux/user-slice/userSlice';
import {
  checkSignupActiveCodeAction,
  resendSignupCodeAction
} from '../../redux/user-slice/userSliceThunkApis';
import checkRes from '../../utils/checkRes';
import './ActivateUserPage.scss';
import { toast } from 'react-toastify';

const btnTypes = {
  confirmCode: 1,
  resendCode: 2
};

const ActivateAccountPage = () => {
  const { t, i18n } = useTranslation();
  const user = useSelector((store) => store.user.user);

  const navigate = useNavigate();
  const [submitCodeCount, setSubmitCodeCount] = React.useState(0);
  const [value, onChange] = React.useState('');
  const [err, setErr] = useState({});
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 4,
    value,
    onChange
  });

  // useEffect(() => {
  //   if (user?.active_code) {
  //     onChange(String(user.active_code));
  //   }
  // }, [user]);

  const [isLoadingState, setIsLoadingState] = React.useState({
    type: '',
    isLoading: false
  });
  const dispatch = useDispatch();
  const submitForm = async () => {
    if (value?.trim().length === 4) {
      setErr({});
      setIsLoadingState({
        type: btnTypes.confirmCode,
        isLoading: true
      });

      const formData = new FormData();
      formData.append('code', value);
      try {
        const action = await dispatch(
          checkSignupActiveCodeAction({
            dataToBeSubmitted: formData
          })
        );
        const res = action?.payload?.res;
        setIsLoadingState({
          type: btnTypes.confirmCode,
          isLoading: false
        });
        if (checkRes(res)) {
          dispatch(
            setCurrentUserAction({
              ...user,
              activated: true,
              firstTime: true
            })
          );
          sessionStorage.removeItem('currentUser');

          if (user?.accountType == accountTypes()?.user)
            navigate(routerLinks?.homePage);
          else navigate(merchentRouterLinks?.merchentDashboard);
        } else {
          setSubmitCodeCount((prev) => prev + 1);
        }
      } catch (error) {
        setIsLoadingState({
          type: btnTypes.confirmCode,
          isLoading: true
        });
        setSubmitCodeCount((prev) => prev + 1);
      }
    } else {
      setErr({
        message: 'الكود غير صحيح'
      });
    }
  };

  const resendCode = async () => {
    setIsLoadingState({
      type: btnTypes.resendCode,
      isLoading: true
    });
    // check == 1 <= check activation code after registeration
    // check == 2 <= check activation code after resetting Password
    const formData = new FormData();
    formData.append('check', 1);

    try {
      const action = await dispatch(
        resendSignupCodeAction({
          dataToBeSubmitted: formData
        })
      );
      // const res = action?.payload?.res;
      // if (checkRes(res)) {
      //   toast.success('تم ارسال الكود الي الهاتف', {
      //     position: 'bottom-left',
      //     hideProgressBar: false,
      //     progress: undefined,
      //     autoClose: 2000,
      //     toastId: 'تم ارسال الكود الي الهاتف'
      //   });
      // }
      setIsLoadingState({
        type: btnTypes.confirmCode,
        isLoading: false
      });
    } catch (error) {
      setIsLoadingState({
        type: btnTypes.confirmCode,
        isLoading: false
      });
    }
  };

  useEffect(() => {
    if (user?.resendCode) {
      resendCode();
    }
  }, [JSON.stringify(user)]);

  return (
    <div className="activate-user-page ">
      <div className="mfa-container">
        {/* <Logo className="logo-wrap" /> */}
        <div className="img-form-wrap">
          <div className="logo-wrap">
            <img src={imgSrc} alt="activate" />
          </div>
          <div className="title-form-wrap">
            <div className="modal-title">{t('activateCodeForm.ver')}</div>
            <div className="form-title">{t('activateCodeForm.check')}</div>
            <ActivationCodeForm
              value={value}
              digits={digits}
              err={err}
              setErr={setErr}
              submitCodeCount={submitCodeCount}
              onChange={onChange}
              isLoadingState={isLoadingState}
              submitForm={submitForm}
              resendCode={resendCode}
              btnTypes={btnTypes}
            />
          </div>
        </div>
      </div>

      {isLoadingState?.isLoading && <LoadingModal />}
    </div>
  );
};

export default ActivateAccountPage;
