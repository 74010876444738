import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConfigProvider } from 'antd';

// import "antd/dist/antd.css";
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import 'antd/dist/antd.less';
import 'antd/dist/reset.css';
import 'react-phone-number-input/style.css';
import './scss/index.scss';
import App from './components/app/App';
import { Provider as ReactReduxProvider } from 'react-redux';
import store from './redux/store';
import { ForgetPasswordProvider } from './contexts/forget-password-context/ForgetPasswordContext';
import { SharedAppProvider } from './contexts/shared-app-context/SharedAppContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ReactReduxProvider store={store}>
    <ForgetPasswordProvider>
      <Router>
        <SharedAppProvider>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#246db4',
                fontFamily: "'Quicksand_Book', 'DroidKufi-Regular' !important"
              }
            }}
            >
            <App />
          </ConfigProvider>
          </SharedAppProvider>
      </Router>
    </ForgetPasswordProvider>
  </ReactReduxProvider>
);

reportWebVitals();
