/* eslint-disable react-hooks/exhaustive-deps */
import {
  LeftOutlined,
  LoadingOutlined,
  RightOutlined
} from '@ant-design/icons';
import { Pagination } from 'antd';
import { t } from 'i18next';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomBreadcrumb from '../../common/bread-crumb/Breadcrumb';
import CustomEmpty from '../../common/custom-empty/CustomEmpty';
import generalRouterLinks from '../../components/app/general-routes/gerneralRouterLinks';
import routerLinks from '../../components/app/routerLinks';
import { getPublicQuotesAction } from '../../redux/general-slice/generalSliceThunkApis';
import AdvancedSearchForm from '../advanced-search-page/AdvancedSearchForm';
import PublicQuoteCard from './PublicQuoteCard';
import './PublicQuotesPage.scss';

const PublicQuotesPage = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const navigate = useNavigate();
  const isLoadingPublicQuotes = useSelector(
    (store) => store.general.isLoadingPublicQuotes
  );
  const fetchedPublicQuotes = useSelector(
    (store) => store.general.fetchedPublicQuotes
  );
  const publicQuotesPagination = useSelector(
    (store) => store.general.publicQuotesPagination
  );

  useEffect(() => {
    dispatch(
      getPublicQuotesAction({
        filterObj: {
          ...values
        }
      })
    );
  }, [search]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [fetchedPublicQuotes]);

  const renderQuotesList = () => {
    return fetchedPublicQuotes.map((quote) => {
      return (
        <PublicQuoteCard
          key={quote.id}
          card={quote}
          to={generalRouterLinks?.singlePublicQuoteRoute(quote?.id)}
        />
      );
    });
  };

  const renderMainContent = () => {
    if (isLoadingPublicQuotes) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 332
          }}
        >
          <LoadingOutlined style={{ fontSize: 20 }} />
        </div>
      );
    } else if (fetchedPublicQuotes?.length === 0) {
      return (
        <CustomEmpty>
          <h2
            style={{
              margin: '42px auto'
            }}
          >
            No Quotes Found!!
          </h2>
        </CustomEmpty>
      );
    } else if (fetchedPublicQuotes?.length > 0) {
      return (
        <>
          <ul className="quotes-list">{renderQuotesList()}</ul>

          {publicQuotesPagination?.total > 0 && (
            <div className="pagination-wrapper">
              <Pagination
                nextIcon={
                  i18n.dir() === 'ltr' ? <RightOutlined /> : <LeftOutlined />
                }
                prevIcon={
                  i18n.dir() === 'ltr' ? <LeftOutlined /> : <RightOutlined />
                }
                locale={{
                  jump_to: i18n?.language === 'ar' ? 'إذهب إلى' : 'Go to',
                  // page: i18n?.language === 'ar' ? 'الصفحة' : 'Page'
                  page: ''
                }}
                showSizeChanger={false}
                defaultCurrent={1}
                current={publicQuotesPagination.current_page}
                pageSize={publicQuotesPagination.per_page}
                total={publicQuotesPagination.total}
                // itemRender={itemRender}
                onChange={(page, pageSize) => {
                  navigate(
                    `${generalRouterLinks?.publicQuotesRoute}?page=${page}`
                  );
                }}
                hideOnSinglePage={true}
              />
            </div>
          )}
        </>
      );
    }

    return null;
  };

  return (
    <div className="public-quotes-page">
      <CustomBreadcrumb
        arr={[
          {
            title: t('breadcrumbs.home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: t('breadcrumbs.publicQuotes'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        {/* <div className="page-title">{t('breadcrumbs.publicQuotes')}</div> */}

        <AdvancedSearchForm
          onSubmit={(data) => {
            navigate(
              `${generalRouterLinks.publicQuotesRoute}?companyType=${
                data.companyType || ''
              }&service_id=${data.subService_id || ''}&country_id=${
                data.country_id || ''
              }&city_id=${data?.city_id || ''}&page=1`
            );
          }}
        />

        {renderMainContent()}
        {/* {isLoadingPublicQuotes && <LoadingModal />} */}
      </div>
    </div>
  );
};

export default PublicQuotesPage;
