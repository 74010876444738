import axios from 'axios';
import apisUrls from '../apisUrls';

const contractorAnswerOfferApi = async (values) => {
  return axios.post(apisUrls.contractorAnswerOffer, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export default contractorAnswerOfferApi;
