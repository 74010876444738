import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import CustomImage from '../../common/custom-image/CustomImage';
import imgsSrcs from '../../helpers/imgsSrcs';
import {
  incDecProductQty,
  removeCartProductAction,
  setProductQty
} from '../../redux/cart-slice/cartSlice';
import './CartCard.scss';

const CartCard = ({ card }) => {
  const dispatch = useDispatch();
  let productPrice = card.price ? parseFloat(card.price) : 0;
  let productOptionPrice = card?.option?.price
    ? parseFloat(card.option.price)
    : 0;
  let productQty = card.quantity || 0;
  const handleQtyInputChange = (e) => {
    if (
      e?.target?.value &&
      Number(e.target.value) &&
      Number.isInteger(Number(e.target.value)) &&
      !isNaN(Number(e.target.value))
    ) {
      dispatch(
        setProductQty({
          qty: parseInt(e.target.value),
          product_id: card?.product_id,
          option_id: card?.option?.id
        })
      );
    } else if (!e.target.value) {
      dispatch(
        removeCartProductAction({
          product_id: card?.product_id,
          option_id: card?.option?.id
        })
      );
    }
  };
  return (
    <div className="cart-card">
      <div className="img-data-wrap">
        <CustomImage className="card-img" src={card?.image} />
        <div className="data-wrap">
          <div className="p-name">
            <p className="name">
              {card?.name}{' '}
              {card?.option?.name ? (
                <span style={{ fontSize: '80%' }}>( {card.option.name} )</span>
              ) : null}
            </p>
            <p className="comp">
              {t('cartPage.by')} {card?.companyName}
            </p>
          </div>
          <p className="p-price">
            {productQty * (productPrice + productOptionPrice)}
            {t('currency.sar')}
          </p>
        </div>
      </div>

      <div className="card-actions">
        <button
          onClick={() => {
            dispatch(
              removeCartProductAction({
                product_id: card?.product_id,
                option_id: card?.option?.id
              })
            );
          }}
          type="button"
          className="remove-btn"
        >
          <img src={imgsSrcs?.trashImg} alt="trash" />
        </button>

        <div className="action-btns">
          <button
            disabled={card?.quantity === 1}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                incDecProductQty({
                  btnType: 'dec',
                  product_id: card?.product_id,
                  option_id: card?.option?.id
                })
              );
            }}
            type="button"
            className="action-btn dec-btn"
          >
            -
          </button>
          <input
            className="input-qty"
            type="text"
            value={card?.quantity}
            onChange={handleQtyInputChange}
          />
          {/* <div className="qty">{card?.quantity}</div> */}
          <button
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                incDecProductQty({
                  btnType: 'inc',
                  product_id: card?.product_id,
                  option_id: card?.option?.id
                })
              );
            }}
            type="button"
            className="action-btn inc-btn"
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartCard;
