import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SuccessCard from '../../components/success-card/SuccessCard';
import successCheckImg from '../../assets/imgs/success-check.png';
import './CompanyDashobardPage.scss';
import { setCurrentUserAction } from '../../redux/user-slice/userSlice';
import CompanyOrdersPage from './CompanyOrdersPage';

const CompanyDashobardPage = () => {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user.user);
  const { t } = useTranslation();
  const sharedSuccessT = (key) => t(`successSignup.${key}`);

  return (
    <div className="company-dashboard-page">
      {user?.firstTime ? (
        <div className="success-card-container">
          <div className="custom-container">
            <div className="success-card-content">
              <img src={successCheckImg} alt="success" />
              <SuccessCard
                mainTitle={sharedSuccessT('mainTitle')}
                subTitle={sharedSuccessT('compSubTitle')}
                welcomeText={`${sharedSuccessT('welcome')}`}
                coloredText={`${sharedSuccessT('benaApp')}`}
                btnText={sharedSuccessT('btnText')}
                // btnUrl={routerLinks?.homePage}
                onClick={() => {
                  dispatch(
                    setCurrentUserAction({
                      ...user,
                      firstTime: false
                    })
                  );
                }}
              />
              <p className="copyright-p">{t('copyRights')}</p>
            </div>
          </div>
        </div>
      ) : (
        <CompanyOrdersPage />
      )}
    </div>
  );
};

export default CompanyDashobardPage;
