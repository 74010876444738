import React from 'react';

const NotificationsIcon = ({ color }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 6.93945V10.2695"
        stroke={color ? color : '#6D6D6D'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12.52 2.5C8.84002 2.5 5.86002 5.48 5.86002 9.16V11.26C5.86002 11.94 5.58002 12.96 5.23002 13.54L3.96002 15.66C3.18002 16.97 3.72002 18.43 5.16002 18.91C9.94002 20.5 15.11 20.5 19.89 18.91C21.24 18.46 21.82 16.88 21.09 15.66L19.82 13.54C19.47 12.96 19.19 11.93 19.19 11.26V9.16C19.18 5.5 16.18 2.5 12.52 2.5Z"
        stroke={color ? color : '#6D6D6D'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M15.83 19.3203C15.83 21.1503 14.33 22.6503 12.5 22.6503C11.59 22.6503 10.75 22.2703 10.15 21.6703C9.55004 21.0703 9.17004 20.2303 9.17004 19.3203"
        stroke={color ? color : '#6D6D6D'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default NotificationsIcon;
