/* eslint-disable react-hooks/exhaustive-deps */
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import imgsSrcs from '../../helpers/imgsSrcs';
import {
  setAcceptCompOfferModalOpenedAction,
  setRejectCompOfferModalOpenedAction,
  setSelectedCompanyOfferAction,
  setSelectedCompOffersModalOpenedAction,
  setOffermodalop,
  setUserOfferModalOpenedAction
} from '../../redux/user-orders-slice/userOrdersSlice';
import { CloudDownloadOutlined } from '@ant-design/icons';
import './OrderOffersModal.scss';
import {
  contractorOfferStatus,
  isAbleNegotiateValues,
  neogtiationTypes,
  userOfferStatus
} from '../../const-values/orderStatus';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { userGetOrderCompanyOffersAction } from '../../redux/user-orders-slice/userOrdersSliceThunkApi';
import CustomImage from '../../common/custom-image/CustomImage';

const OrderOffersModal = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user.user);
  const selectedCompOffersModalOpened = useSelector(
    (store) => store.userOrders.selectedCompOffersModalOpened
  );
  const fetchedUserSingleOrder = useSelector(
    (store) => store.userOrders.fetchedUserSingleOrder
  );
  const fetchSingleOrderCount = useSelector(
    (store) => store.userOrders.fetchSingleOrderCount
  );
  const isLoadingCompanyOffers = useSelector(
    (store) => store.userOrders.isLoadingCompanyOffers
  );
  const fetchedCompanyOffers = useSelector(
    (store) => store.userOrders.fetchedCompanyOffers
  );
  const selectedCompany = useSelector(
    (store) => store.userOrders.selectedCompany
  );

  const { i18n, t } = useTranslation();
  const sharedT = (key) => t(`offersModal.${key}`);

  useEffect(() => {
    if (selectedCompOffersModalOpened) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [selectedCompOffersModalOpened]);
  useEffect(() => {
    let isMounted = true;
    if (selectedCompOffersModalOpened && isMounted)
      dispatch(
        userGetOrderCompanyOffersAction({
          filterObj: {
            order_id: params?.order_id || '',
            company_id: selectedCompany?.id
          }
        })
      );

    return () => (isMounted = false);
  }, [i18n.language, fetchSingleOrderCount, selectedCompOffersModalOpened]);

  const renderBoxBody = () => {
    if (isLoadingCompanyOffers) {
      return (
        <div
          style={{
            height: 200,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <LoadingOutlined />
        </div>
      );
    } else if (fetchedCompanyOffers?.offers?.length === 0) {
      return (
        <h2
          style={{
            textAlign: 'center',
            fontSize: 20,
            margin: '42px 0'
          }}
        >
          {sharedT('noOffers')}
        </h2>
      );
    } else if (fetchedCompanyOffers?.history?.length > 0) {
      return fetchedCompanyOffers?.history.map((offer) => (
        <div
          key={offer?.id}
          className={`order-offer-card ${
            offer?.type === neogtiationTypes?.user ? 'mine' : 'other'
          }`}
        >
          {renderProfileCard(offer)}
          {renderOfferCard(offer)}

          {renderAcceptRejectOffer(offer)}
        </div>
      ));
    }
    return null;
  };

  const renderAcceptRejectOffer = (offer) => {
    if (
      offer?.type === neogtiationTypes?.company &&
      offer?.status === userOfferStatus?.newOffer
    ) {
      return (
        <div className="accept-reject-btns">
          <button
            onClick={() => {
              dispatch(setSelectedCompanyOfferAction(offer));
              dispatch(setRejectCompOfferModalOpenedAction(true));
            }}
            className="reject-btn"
          >
            {sharedT('reject')}
          </button>
          <button
            onClick={() => {
              dispatch(setSelectedCompanyOfferAction(offer));
              dispatch(setAcceptCompOfferModalOpenedAction(true));
            }}
            className="accept-btn"
          >
            {sharedT('accept')}
          </button>
        </div>
      );
    } else if (offer?.status === userOfferStatus?.accepted) {
      return (
        <div className="accepted-wrap">
          <img src={imgsSrcs?.checkGreenImg} alt="" />
          <p className="accepted-p">{sharedT('accepted')}</p>
        </div>
      );
    } else if (offer?.status === userOfferStatus?.rejected) {
      return (
        <div className="rejected-wrap">
          <img src={imgsSrcs?.wrongRedImg} alt="" />
          <p className="rejected-p">{sharedT('rejected')}</p>
        </div>
      );
    }
    return null;
  };

  const renderCompType = (type) => {
    if (type === neogtiationTypes?.user)
      return i18n.language === 'ar' ? 'شخصى' : 'Personal';
    return i18n.language === 'ar' ? 'شركة' : 'Company';
  };

  const renderProfileCard = (obj) => {
    if (obj?.type === neogtiationTypes?.company) {
      // supplier card
      return (
        <div className="prof-card other">
          <div className="img-name-date-wrap">
            <div className="img-name">
              <CustomImage className="prof-img" src={obj?.company?.image} />
              <div className="name">
                <p>{obj?.company?.companyData?.companyName}</p>
                <p>{obj?.company?.name}</p>
              </div>
            </div>

            {renderCompType(obj?.type)}
          </div>
        </div>
      );
    } else if (obj?.type === neogtiationTypes?.user) {
      // user card
      return (
        <div className="prof-card mine">
          <div className="img-name-date-wrap">
            <div className="img-name">
              <CustomImage className="prof-img" src={user?.image} />
              <div className="name">{user?.name}</div>
            </div>

            {renderCompType(obj?.type)}
          </div>
        </div>
      );
    }
  };

  const renderOfferCard = (offer) => {
    if (offer?.prices?.length > 0) {
      return (
        <div className="card-prices-list">
          {offer.prices.map((price) => (
            <div key={price?.id} className="offer-price-wrap">
              <div className="labels">
                <div className="name">
                  {sharedT('materialName')} : {price?.product?.productName}
                </div>

                <div className="unit-q">
                  <div className="unit">
                    <div className="label">{sharedT('unit')} : </div>
                    <div className="value">{price?.product?.unit?.name}</div>
                  </div>
                  <span className="v-line-span"></span>
                  <div className="q">
                    <div className="label">{sharedT('quantity')} : </div>
                    <div className="value">{price?.product?.quantity}</div>
                  </div>
                </div>
              </div>
              <div className="price-wrap">
                <div className="price-label">{sharedT('price')}</div>

                {
                  <div className="price-v">
                    {price?.price}{' '}
                    <span
                      style={{
                        fontSize: 13
                      }}
                    >
                      {t('currency.sar')}
                    </span>
                  </div>
                }
              </div>
            </div>
          ))}
        </div>
      );
    } else if (offer?.orderFile) {
      return (
        <a
          key={offer?.orderFile.id}
          download={true}
          href={offer?.orderFile?.filename}
          target="_blank"
          rel="noreferrer"
        >
          <CloudDownloadOutlined />
          {sharedT('download')}
        </a>
      );
    }

    return null;
  };

  return (
    <AnimatePresence>
      {selectedCompOffersModalOpened && (
        <motion.div
          onClick={() => {
            dispatch(setSelectedCompOffersModalOpenedAction(null));
          }}
          initial={{
            opacity: 0
          }}
          animate={{
            opacity: 1,
            transition: {
              opacity: { ease: 'linear', duration: 0.4 }
            }
          }}
          exit={{
            opacity: 0,
            transitionDelay: 1,
            animationDelay: 1,

            transition: {
              delay: 0.2,
              opacity: { ease: 'easeOut', duration: 0.4 }
            }
          }}
          className={`selected-supplier-product-wrapper order-offers-modal contractor-offers-modal ${i18n.dir()}`}
        >
          <motion.div
            // ref={menuRef}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            initial={{
              translateX: i18n.dir() === 'ltr' ? 600 : -600
            }}
            animate={{
              translateX: 0,
              transition: {
                delay: 0.2,
                duration: 0.4,
                ease: 'linear'
              }
            }}
            exit={{
              translateX: i18n.dir() === 'ltr' ? 600 : -600,
              transition: {
                duration: 0.4,
                ease: 'linear'
              }
            }}
            className="selected-supplier-product"
            // ref={menuRef}
          >
            <div className="selected-supplier-product-with-overflow">
              <div className="box-header">
                <div className="titles-wrap">
                  <p>
                    {selectedCompany?.companyData?.companyName ||
                      selectedCompany?.name}
                  </p>
                  <p
                    style={{
                      marginTop: 8,
                      marginInlineStart: 4,
                      color: '#668199',
                      fontSize: 14
                    }}
                  >{`${sharedT('order')} #${fetchedUserSingleOrder?.id}`}</p>
                </div>

                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    // handleClose();
                    dispatch(setSelectedCompOffersModalOpenedAction(false));
                  }}
                  className="back-btn"
                >
                  {i18n.dir() === 'ltr' ? (
                    <img src={imgsSrcs?.arrowRightImg} alt="" />
                  ) : (
                    <img src={imgsSrcs?.arrowLeftImg} alt="" />
                  )}
                </button>
              </div>

              <div className="box-body">{renderBoxBody()}</div>
              {fetchedCompanyOffers?.lastOffer?.type ===
                neogtiationTypes?.company &&
                fetchedCompanyOffers?.lastOffer?.status ===
                  contractorOfferStatus?.newOffer &&
                fetchedCompanyOffers?.lastOffer?.isAbleNegotiate ===
                  isAbleNegotiateValues?.yes && (
                  <div className="box-footer">
                    <button
                      className="new-price-btn"
                      onClick={() => {
                        dispatch(setUserOfferModalOpenedAction(true));
                      }}
                    >
                      {t('offersModal.newPrice')}
                    </button>
                  </div>
                )}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default OrderOffersModal;
