import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { companyTypes } from '../../const-values/accountTypes';
import {
  orderTypesValues,
  threeOrderStatusValues,
  underNegotiationValues
} from '../../const-values/orderStatus';
import {
  setCancelOrderModalOpenedAction,
  setCompaniesModalOpenedAction,
  setNegotiationsModalOpenedAction,
  setSelectedCompOffersModalOpenedAction
} from '../../redux/user-orders-slice/userOrdersSlice';

const RenderOrderActionBtns = () => {
  const sharedSingleT = (key) => t(`singleOrderPage.${key}`);

  const dispatch = useDispatch();
  const fetchedUserSingleOrder = useSelector(
    (store) => store.userOrders.fetchedUserSingleOrder
  );

  const renderOrderActionBtn = () => {
    const type = fetchedUserSingleOrder?.type;
    const companyType = fetchedUserSingleOrder?.company?.companyType;
    // check if order is new then view cancel btn

    if (type === orderTypesValues?.public) {
      // public order
      // can view companies offers and accept or reject order
      return (
        <div className="main-btn-cancel-btns-wrap">
          <button
            onClick={() => {
              // dispatch(setSelectedCompOffersModalOpenedAction(true));
              dispatch(setCompaniesModalOpenedAction(true));
            }}
            className="public-offers-btn action-btn"
          >
            {sharedSingleT('viewOffers')}
          </button>
          {fetchedUserSingleOrder?.status === threeOrderStatusValues?.new && (
            <button
              onClick={() => {
                dispatch(setCancelOrderModalOpenedAction(true));
              }}
              className="cancel-order-btn"
            >
              Cancel
            </button>
          )}
        </div>
      );
    } else if (
      type === orderTypesValues?.contractor &&
      companyType === companyTypes()?.contractor
    ) {
      // order from contractor
      // can view contractor offer then accept or reject
      return (
        <div className="main-btn-cancel-btns-wrap">
          <button
            onClick={() => {
              dispatch(setCompaniesModalOpenedAction(true));
            }}
            className="compan-offer-btn action-btn"
          >
            {sharedSingleT('viewOffers')}
          </button>
          {fetchedUserSingleOrder?.status === threeOrderStatusValues?.new && (
            <button
              onClick={() => {
                dispatch(setCancelOrderModalOpenedAction(true));
              }}
              className="cancel-order-btn"
            >
              Cancel
            </button>
          )}
        </div>
      );
    } else if (
      type === orderTypesValues?.supplier &&
      companyType === companyTypes()?.supplier
    ) {
      // order from supplier
      // can view supplier negotiations
      // then can accept or reject or request a new negotiation
      return (
        <div className="main-btn-cancel-btns-wrap">
          {fetchedUserSingleOrder?.underNegotiate !==
            underNegotiationValues?.no && (
            <button
              onClick={() => {
                dispatch(setNegotiationsModalOpenedAction(true));
              }}
              className="supp-nego-btn action-btn"
            >
              {sharedSingleT('viewNegotiations')}
            </button>
          )}
          {fetchedUserSingleOrder?.status === threeOrderStatusValues?.new && (
            <button
              onClick={() => {
                dispatch(setCancelOrderModalOpenedAction(true));
              }}
              className="cancel-order-btn"
            >
              Cancel
            </button>
          )}
        </div>
      );
    }
  };

  return renderOrderActionBtn();
};

export default RenderOrderActionBtns;
