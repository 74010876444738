import { CheckOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import { useState } from 'react';
import subscribePlanApi from '../../apis/plans-apis/subscribePlanApi';
import checkRes from '../../utils/checkRes';
import LoadingModal from '../../common/loading-modal/LoadingModal';

const SubscriptionBox = ({ plan }) => {
  const sharedT = (key) => t(`pricesPage.${key}`);
  const customApiRequest = useCustomApiRequest();
  const [busy, setBusy] = useState(false);
  const handleSubscribe = async (id) => {
    let isMounted = true;
    setBusy(true);
    customApiRequest(
      subscribePlanApi({ id }),
      (res) => {
        if (isMounted) {
          setBusy(false);
          if (checkRes(res) && res?.data?.data) {
            if (res.data.data) {
              console.log('res : ', res.data.data);
            }
          }
        }
      },
      (error) => {
        setBusy(false);
      }
    );
  };

  return (
    <div className="subscription-box">
      <div className="box-header">
        <p className="basic-p">{plan.name}</p>
        <div className="price-wrap">
          <span className="price-span">${plan.price ?? 0}</span>

          {/* <div className="stirked-price">$200</div> */}

          {/* <span className="per-span">Per month</span> */}
        </div>
      </div>

      <div className="box-body">
        <div className="features-list">
          {plan?.options &&
            plan?.options?.length > 0 &&
            plan.options.map((op) => (
              <p key={op.id} className="list-p">
                <span className="check-icon-wrap">
                  <CheckOutlined />
                </span>
                <span>
                  {op?.name}
                  {!!op?.value && (
                    <span className="v-span"> {`( ${op.value} )`}</span>
                  )}
                </span>
              </p>
            ))}
        </div>
      </div>

      <div className="box-footer">
        <button
          onClick={() => {
            handleSubscribe(plan.id);
          }}
          className="get-started-btn"
        >
          {sharedT('getStarted')}
        </button>
      </div>

      {busy && <LoadingModal />}
    </div>
  );
};

export default SubscriptionBox;
