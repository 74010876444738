const CoinsIcon = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.03268 11.2833H7.78268C6.82435 11.2833 6.04935 10.4833 6.04935 9.49167C6.04935 9.15 6.33268 8.86667 6.67435 8.86667C7.01602 8.86667 7.29935 9.15 7.29935 9.49167C7.29935 9.79167 7.51602 10.0333 7.78268 10.0333H9.03268C9.22435 10.0333 9.37435 9.85833 9.37435 9.64167C9.37435 9.35 9.32435 9.33333 9.13268 9.26667L7.12435 8.56667C6.39935 8.31667 6.04102 7.80833 6.04102 7.01667C6.04102 6.11667 6.75768 5.375 7.63268 5.375H8.88268C9.84102 5.375 10.616 6.175 10.616 7.16667C10.616 7.50833 10.3327 7.79167 9.99102 7.79167C9.64935 7.79167 9.36602 7.50833 9.36602 7.16667C9.36602 6.86667 9.14935 6.625 8.88268 6.625H7.63268C7.44102 6.625 7.29102 6.8 7.29102 7.01667C7.29102 7.30833 7.34102 7.325 7.53268 7.39167L9.54102 8.09167C10.266 8.35 10.616 8.85833 10.616 9.64167C10.6243 10.55 9.90768 11.2833 9.03268 11.2833Z"
        fill={color || '#246DB4'}
      />
      <path
        d="M8.33398 11.9502C7.99232 11.9502 7.70898 11.6668 7.70898 11.3252V10.7085C7.70898 10.3668 7.99232 10.0835 8.33398 10.0835C8.67565 10.0835 8.95898 10.3668 8.95898 10.7085V11.3252C8.95898 11.6752 8.67565 11.9502 8.33398 11.9502Z"
        fill={color || '#246DB4'}
      />
      <path
        d="M8.33398 6.6168C7.99232 6.6168 7.70898 6.33346 7.70898 5.9918V5.3418C7.70898 5.00013 7.99232 4.7168 8.33398 4.7168C8.67565 4.7168 8.95898 5.00013 8.95898 5.3418V5.9918C8.95898 6.33346 8.67565 6.6168 8.33398 6.6168Z"
        fill={color || '#246DB4'}
      />
      <path
        d="M8.32435 15.5999C4.30768 15.5999 1.04102 12.3332 1.04102 8.31654C1.04102 4.29987 4.30768 1.0332 8.32435 1.0332C12.341 1.0332 15.6077 4.29987 15.6077 8.31654C15.6077 12.3332 12.3327 15.5999 8.32435 15.5999ZM8.32435 2.29154C4.99935 2.29154 2.29102 4.99987 2.29102 8.32487C2.29102 11.6499 4.99935 14.3499 8.32435 14.3499C11.6493 14.3499 14.3577 11.6415 14.3577 8.31654C14.3577 4.99154 11.6493 2.29154 8.32435 2.29154Z"
        fill={color || '#246DB4'}
      />
      <path
        d="M14.1842 18.9336C12.6425 18.9336 11.1925 18.1836 10.3008 16.9169C10.1008 16.6336 10.1675 16.2419 10.4508 16.0419C10.7342 15.8419 11.1258 15.9086 11.3258 16.1919C11.9842 17.1252 13.0508 17.6752 14.1842 17.6752C16.1175 17.6752 17.6842 16.1086 17.6842 14.1752C17.6842 13.0502 17.1342 11.9836 16.2175 11.3252C15.9342 11.1252 15.8758 10.7336 16.0758 10.4502C16.2758 10.1669 16.6675 10.1086 16.9508 10.3086C18.1925 11.2002 18.9342 12.6419 18.9342 14.1752C18.9342 16.8086 16.8092 18.9336 14.1842 18.9336Z"
        fill={color || '#246DB4'}
      />
    </svg>
  );
};

export default CoinsIcon;
