import { createSlice } from '@reduxjs/toolkit';
import { getUserFavs } from './cartSliceThunkApis';

const initialState = {
  isLoadingFavs: false,
  fetchedUserFavs: null,
  fetchFavCount: 0,
  userFavPagination: {
    current_page: 1,
    par_page: 0,
    total: 0
  },
};

const userFavSlice = createSlice({
  name: 'userFav',
  initialState,
  reducers: {
    setFavCountAction: (sliceState, action) => {
      sliceState.fetchFavCount = action.payload;
    }
  },
  extraReducers: {
     // get user favs
     [getUserFavs.pending]: (state) => {
      state.isLoadingFavs = true;
    },
    [getUserFavs.fulfilled]: (state, action) => {
      state.isLoadingFavs = false;
      const data = action?.payload?.res?.data;
      state.fetchedUserFavs = data?.data;
      if (data?.pagination) state.userFavPagination = data.pagination;
      // if (data?.pagination) state.tablePagination = data.pagination;
    },
    [getUserFavs.rejected]: (state, action) => {
      state.isLoadingFavs = false;
    },
  }
});

const userFavReducer = userFavSlice.reducer;

export const {setFavCountAction} = userFavSlice.actions;


export default userFavReducer;
