/* eslint-disable react-hooks/exhaustive-deps */
import { Tabs } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '../../common/logo/Logo';
import SharedUserSignupForm from './SharedUserSignupForm';
import './SignupPage.scss';

const UserSignupPage = () => {
  const { t } = useTranslation();
  const items = [
    {
      key: '1',
      label: t('individualsAccount'),
      children: <SharedUserSignupForm />
    },
    {
      key: '2',
      label: t('companyAccount'),
      children: <SharedUserSignupForm isCompany={true} />
    }
  ];

  return (
    <div className="shared-custom-page signup-page">
      <div className="custom-container">
        <section className="signup-form-section">
          <Logo className="logo" />

          <Tabs
            className="signin-tabs"
            style={{
              width: '100%'
            }}
            defaultActiveKey="1"
            items={items}
          />
        </section>
      </div>
    </div>
  );
};

export default UserSignupPage;
