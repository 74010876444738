import { Modal, Steps } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import imgsSrcs from '../../helpers/imgsSrcs';
import { setNegotiationPriceAction } from '../../redux/cart-slice/cartSlice';
import {
  setQuoteModalOpened,
  setQuoteType
} from '../../redux/quote-slice/quoteSlice';
import userRouterLinks from '../app/user-routes/uesRouterLinks';
import CongratsContent from '../congrats-content/CongratsContent';
import './QuoteModal.scss';
import OrderStepperForm from './QuoteStepperForm';
const QuoteModal = ({ title }) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const quoteModalOpened = useSelector((store) => store.quote.quoteModalOpened);
  const isSubmittingQuote = useSelector(
    (store) => store.quote.isSubmittingQuote
  );
  const submittedQuote = useSelector((store) => store.quote.submittedQuote);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <Modal
        destroyOnClose
        className="request-quote-modal"
        closeIcon={<img src={imgsSrcs.closeCircleImg} alt="close" />}
        width="96%"
        style={{ maxWidth: '762px' }}
        title={title}
        open={quoteModalOpened}
        onOk={() => {
          dispatch(setQuoteModalOpened(false));
          dispatch(setQuoteType(''));
          setCurrentStep(0);
        }}
        onCancel={() => {
          dispatch(setQuoteModalOpened(false));
          dispatch(setQuoteType(''));
          setCurrentStep(0);
        }}
        footer={false}
      >
        <Steps
          className="quote-steps-indicator"
          labelPlacement="vertical"
          // size="small"
          current={currentStep}
          items={[
            {
              title: t('stepOne')
            },
            {
              title: t('stepTwo')
            },
            {
              title: t('stepThree')
            }
          ]}
        />

        <AnimatePresence>
          {currentStep === 2 ? (
            <motion.div>
              <CongratsContent
                subTitleText={t('congrats.answers')}
                handleCancel={() => {
                  dispatch(setQuoteModalOpened(false));
                  dispatch(setQuoteType(''));
                  dispatch(setNegotiationPriceAction(''));
                  setCurrentStep(0);
                }}
                handleOk={() => {
                  dispatch(setQuoteModalOpened(false));
                  dispatch(setQuoteType(''));
                  setCurrentStep(0);
                  navigate(
                    userRouterLinks?.userSingleOrderRoute(submittedQuote?.id)
                  );
                }}
              />
            </motion.div>
          ) : (
            <OrderStepperForm step={currentStep} setStep={setCurrentStep} />
          )}
        </AnimatePresence>
      </Modal>

      {isSubmittingQuote && <LoadingModal />}
    </>
  );
};

export default QuoteModal;
