/* eslint-disable react-hooks/exhaustive-deps */
import SharedSlider from '../../components/shared-slider/SharedSlider';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import './HomeSlider.scss';
import CoinsIcon from '../../common/icons/CoinsIcon';
import { useDispatch, useSelector } from 'react-redux';
import {
  setQuoteModalOpened,
  setQuoteType
} from '../../redux/quote-slice/quoteSlice';
import quoteTypes from '../../const-values/quoteTypes';
import { setNotAuthedModalOpened } from '../../redux/user-slice/userSlice';
import { Link } from 'react-router-dom';
import generalRouterLinks from '../../components/app/general-routes/gerneralRouterLinks';
import { FilterOutlined } from '@ant-design/icons';

const HomeSlider = () => {
  const { i18n, t } = useTranslation();
  const [sliderDir, setSliderDir] = useState(i18n.dir());
  useEffect(() => {
    setSliderDir(i18n.dir());
  }, [i18n.dir()]);

  const sliderSettings = {
    fade: true,
    dots: true,
    arrows: true,
    rtl: sliderDir === 'rtl' ? true : false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0
  };

  const dispatch = useDispatch();
  const user = useSelector((store) => store.user.user);
  const isLoadingSlider = useSelector((store) => store.general.isLoadingSlider);
  const fetchedSliderData = useSelector(
    (store) => store.general.fetchedSliderData
  );

  const renderSingleSlide = (item) => {
    return (
      <div className="slide-wrap" key={item.id}>
        <img className="slide-img" src={item.image} alt="slide" />
        <div className="slide-text">
          <div className="custom-container">
            <div className={`content-wrap ${i18n.dir()}`}>
              {item?.title && (
                <div className="text-wrap">
                  {item?.title && <h2 className="slide-h2">{item.title}</h2>}
                  {item?.desc && <p className="sub-title">{item.desc}</p>}
                </div>
              )}

              <div className="btns-links">
                <button
                  onClick={() => {
                    if (user) {
                      dispatch(setQuoteType(quoteTypes?.publicQuote));
                      dispatch(setQuoteModalOpened(true));
                    } else {
                      dispatch(setNotAuthedModalOpened(true));
                    }
                  }}
                  className="shop-link"
                >
                  <CoinsIcon color="#fff" />
                  {t('hero_section.shop_btn')}
                </button>
                <Link
                  to={generalRouterLinks?.advancedSearch}
                  className="shop-link search-link"
                >
                  <FilterOutlined />
                  {t('hero_section.advancedSearch')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="home-hero-section">
      <SharedSlider
        className="custom-slick-slider main-app-slick-slider"
        slides={fetchedSliderData?.length > 0 ? fetchedSliderData : []}
        settings={sliderSettings}
        renderSingleSlide={renderSingleSlide}
        isLoading={isLoadingSlider}
      />
    </div>
  );
};

export default HomeSlider;
