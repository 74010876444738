const merchentRouterLinks = {
  // merchentDashboard: '/merchent-dashboard',
  merchentDashboard: '/',
  companyOrdersRoute: '/company/orders',
  companySingleOrderRoute: (order_id) =>
    order_id ? `/orders/${order_id}` : `/orders/:order_id`
  //

  //////////////////////////////////////
  //////////////////////////////////////
  //////////////////////////////////////
  //////////////////////////////////////
  //////////////////////////////////////
  //////////////////////////////////////
  //////////////////////////////////////
  //////////////////////////////////////
  //////////////////////////////////////
  //////////////////////////////////////
  //////////////////////////////////////
  //////////////////////////////////////
  //////////////////////////////////////
  //////////////////////////////////////
};

export default merchentRouterLinks;
