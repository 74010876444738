/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import updateProfileApi from '../../apis/auth/updateProfileApi';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import CountryCityOptions from '../../common/country-city-options/CountryCityOptions';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import {
  setCurrentUserAction,
  setUserToStateAction
} from '../../redux/user-slice/userSlice';
import checkRes from '../../utils/checkRes';
import './ProfileSettingsForm.scss';

const ProfileSettingsForm = ({ setSettingsFormOpened }) => {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user.user);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { i18n, t } = useTranslation();
  const countries = useSelector((store) => store.general.fetchedCountriesData);
  const generalLabelStr = (v) => t(`signup_form.${v}.label`);
  const generalrequiredErrStr = (v) => t(`signup_form.${v}.errors.required`);
  const generalTypeErrorStr = (v) => t(`signup_form.${v}.errors.type_error`);
  const generalMinErrorStr = (v, min) =>
    t(`signup_form.${v}.errors.min`, {
      min
    });
  const schema = Yup.object().shape({
    name: Yup.string().required(generalrequiredErrStr('name')),
    phone: Yup.string()
      .required(generalrequiredErrStr('phone'))
      // .matches(/^[0-9]+$/, generalTypeErrorStr('phone'))
      .min(10, generalMinErrorStr('phone', 10)),
    email: Yup.string()
      .required(generalrequiredErrStr('email'))
      .email(generalTypeErrorStr('email'))
  });
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      country_id: '',
      city_id: ''
    }
  });

  // handle initial values
  useEffect(() => {
    if (user) {
      setValue('name', user?.name || '');
      setValue('email', user?.email || '');
      setValue('phone', user?.phone || '');
      setValue('commercialRecord', user?.commercial_record || '');
      setValue('taxNumber', user?.tax_record || '');
      setValue('country_id', user?.country?.id ? String(user.country.id) : '');
      setValue('city_id', user?.city?.id ? String(user.city.id) : '');
    }
  }, [user]);

  const customApiRequest = useCustomApiRequest();
  const onSubmit = (data) => {
    // signMeUpWithEmailPassword({ ...data, accountType: 3 });
    setIsSubmitting(true);
    // const formatedIntlPhone = formatPhoneNumberIntl(data.phone); // '+966 50 123 4444'
    // const phoneStrArr = formatedIntlPhone.split(' '); // ['+966', '50', '123', '4444']
    // const countryCode = phoneStrArr[0];
    // let phone = '';
    // if (phoneStrArr?.length === 2) phone = phoneStrArr[1];
    // else if (phoneStrArr?.length > 2) {
    //   let array = phoneStrArr.filter((item, index) => index > 0); // ['50', '123', '4444']
    //   phone = array.join('');
    // }

    const formData = new FormData();
    if (data.name) formData.append('name', data.name);
    if (data.phone) formData.append('phone', data.phone);
    if (data.email) formData.append('email', data.email);
    if (data.commercialRecord)
      formData.append('commercial_record', data.commercialRecord);
    if (data.taxNumber) formData.append('tax_record', data.taxNumber);
    if (data?.country_id) formData.append('country_id', data.country_id);
    if (data?.city_id) formData.append('city_id', data.city_id);

    customApiRequest(
      updateProfileApi(formData),
      (res) => {
        setIsSubmitting(false);
        if (checkRes(res)) {
          setSettingsFormOpened(false);
          if (user?.remember) {
            dispatch(
              setCurrentUserAction({
                ...res?.data.data,
                token: user?.token || '',
                accountType: res.data.data.userType || user?.accountType,
                companyType: res.data.data.companyType || user?.companyType,
                activated: user?.activated,
                remember: false
              })
            );
          } else {
            dispatch(
              setUserToStateAction({
                ...res?.data.data,
                token: user?.token || '',
                accountType: res.data.data.userType || user?.accountType,
                companyType: res.data.data.companyType || user?.companyType,
                activated: user?.activated,
                remember: false
              })
            );
          }
        }
      },
      (error) => {
        setIsSubmitting(false);
      }
    );
  };

  const [form] = Form.useForm();
  return (
    <>
      <Form
        className="profile-settings-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="name"
              type="text"
              errorMsg={errors?.name?.message}
              validateStatus={errors?.name ? 'error' : ''}
              control={control}
            />
            <p className="custom-label">{generalLabelStr('name')}</p>
          </div>

          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="email"
              type="text"
              errorMsg={errors?.email?.message}
              validateStatus={errors?.email ? 'error' : ''}
              control={control}
            />
            <p className="custom-label">{generalLabelStr('email')}</p>
          </div>

          <div
            className={`custom-text-field-wrapper with-prefix ${i18n.dir()}`}
          >
            <AntdTextField
              className="custom-text-field"
              name="phone"
              type="text"
              errorMsg={errors?.phone?.message}
              validateStatus={errors?.phone ? 'error' : ''}
              control={control}
            />
            <p className="custom-label">
              {generalLabelStr('phone')}{' '}
              <span
                style={{
                  direction: 'ltr'
                }}
              >
                ( 558615134 )
              </span>
            </p>
            <span className="prefix-span">
              <span
                style={{
                  fontSize: 20,
                  borderRadius: 2
                }}
                className={`fi fi-sa`}
              />{' '}
              {i18n.language === 'ar' ? '(966+)' : '(+966)'}
            </span>
          </div>

          {user?.commercial_record ? (
            <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
              <AntdTextField
                className="custom-text-field"
                name="commercialRecord"
                type="text"
                errorMsg={errors?.commercialRecord?.message}
                validateStatus={errors?.commercialRecord ? 'error' : ''}
                control={control}
              />
              <p className="custom-label">
                {generalLabelStr('commercialRecord')}
              </p>
            </div>
          ) : null}

          {user?.tax_record ? (
            <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
              <AntdTextField
                className="custom-text-field"
                name="taxNumber"
                type="text"
                errorMsg={errors?.taxNumber?.message}
                validateStatus={errors?.taxNumber ? 'error' : ''}
                control={control}
              />
              <p className="custom-label">{generalLabelStr('taxNumber')}</p>
            </div>
          ) : null}

          <CountryCityOptions
            countries={countries}
            countryName="country_id"
            cityName="city_id"
            countryLabel={generalLabelStr('country_id')}
            cityLabel={generalLabelStr('city_id')}
            parentClassName="signup-form"
            //
            watch={watch}
            setValue={setValue}
            control={control}
            errors={errors}
          />

          {/* <AntdCheckbox name="remember" label="تذكرنى" control={control} /> */}

          <div className="form-action-btns">
            <Button
              className="submit-btn"
              htmlType="submit"
              type="primary"
              // icon={<LoginOutlined />}
              loading={isSubmitting}
            >
              {t('profileForm.saveChanges')}
            </Button>

            <button
              type="button"
              onClick={() => {
                reset({
                  name: user?.name || '',
                  phone: user?.phone || '',
                  email: user?.email || '',
                  country_id: user?.country?.id ? String(user.country.id) : '',
                  city_id: user?.city?.id ? String(user.city.id) : ''
                });
              }}
              className="cancel-btn"
            >
              {t('profileForm.cancel')}
            </button>
          </div>
        </div>
      </Form>

      {isSubmitting && <LoadingModal />}
    </>
  );
};

export default ProfileSettingsForm;
