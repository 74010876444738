/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { companyTypes } from '../../const-values/accountTypes';
import { getCompaniesAction } from '../../redux/companies-slice/companiesSliceThunkApis';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { setCompaniesAction } from '../../redux/companies-slice/companiesSlice';
import { LoadingOutlined } from '@ant-design/icons';
import CustomBreadcrumb from '../../common/bread-crumb/Breadcrumb';
import { t } from 'i18next';
import routerLinks from '../../components/app/routerLinks';
import TopRatedCard from '../../components/top-rated-card/TopRatedCard';
import './CompaniesPage.scss';
import CustomEmpty from '../../common/custom-empty/CustomEmpty';

const SupportiveServicesPage = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const isLoadingCompanies = useSelector(
    (store) => store.companies.isLoadingCompanies
  );
  const fetchedCompanies = useSelector(
    (store) => store.companies.fetchedCompanies
  );

  const values = queryString.parse(search);
  useEffect(() => {
    dispatch(
      getCompaniesAction({
        filterObj: {
          ...values,
          companyType: companyTypes()?.supportiveServices
        }
      })
    );

    return () => {
      dispatch(setCompaniesAction([]));
    };
  }, [search]);

  const renderCompList = () => {
    return fetchedCompanies.map((item) => {
      return <TopRatedCard view="grid" key={item?.id} card={item} />;
    });
  };

  if (isLoadingCompanies) {
    return (
      <div className="single-company-page">
        <div
          className="custom-container"
          style={{
            paddingTop: 92,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      </div>
    );
  } else if (fetchedCompanies?.length === 0) {
    return (
      <div className="companies-page">
        <CustomBreadcrumb
          arr={[
            {
              title: t('breadcrumbs.home'),
              isLink: true,
              to: routerLinks?.homePage
            },
            {
              title: t('breadcrumbs.companies'),
              isLink: false
            },
            {
              title: t('breadcrumbs.supportive'),
              isLink: false
            }
          ]}
        />
        <div className="custom-container">
          <CustomEmpty>
            <h2
              style={{
                margin: '18px auto',
                textAlign: 'center'
              }}
            >
              No companies found
            </h2>
          </CustomEmpty>
        </div>
      </div>
    );
  } else if (fetchedCompanies?.length > 0)
    return (
      <div className="companies-page">
        <CustomBreadcrumb
          arr={[
            {
              title: t('breadcrumbs.home'),
              isLink: true,
              to: routerLinks?.homePage
            },
            {
              title: t('breadcrumbs.companies'),
              isLink: false
            },
            {
              title: t('breadcrumbs.supportive'),
              isLink: false
            }
          ]}
        />
        <div className="custom-container">
          <div className="page-title">Supportive services</div>

          <ul className="comp-list">{renderCompList()}</ul>
        </div>
      </div>
    );

  return null;
};

export default SupportiveServicesPage;
