import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import './Breadcrumb.scss';
import { useTranslation } from 'react-i18next';

const CustomBreadcrumb = ({ arr }) => {
  const { i18n } = useTranslation();
  return (
    <div className="breadcrumb-section">
      <div className="custom-container">
        <div className="breadcrumb-content">
          <Breadcrumb separator={i18n.dir() === 'ltr' ? '>' : '>'}>
            {arr?.length > 0 &&
              arr.map((ele, index) => {
                return (
                  <Breadcrumb.Item key={index}>
                    {ele?.isLink ? (
                      <RouterLink to={ele.to}>{ele.title}</RouterLink>
                    ) : (
                      ele.title
                    )}
                  </Breadcrumb.Item>
                );
              })}
          </Breadcrumb>
        </div>
      </div>
    </div>
  );
};

export default CustomBreadcrumb;
