export const storePermissionsValues = (t) => {
  return {
    editStores: '4',
    deleteStores: '5',
    addStores: '6',
    reviewOrders: '7',
    addEmployees: '8',
    reviewStatistics: '9'
  };
};
export const storePermissionsArr = (t) => {
  const sharedT = (key) => t(`storePermissions.${key}`);
  return [
    {
      id: 4,
      value: 4,
      name: sharedT('editStores')
    },
    {
      id: 5,
      value: 5,
      name: sharedT('deleteStores')
    },
    {
      id: 6,
      value: 6,
      name: sharedT('addStores')
    },
    {
      id: 7,
      value: 7,
      name: sharedT('reviewOrders')
    },
    {
      id: 8,
      value: 8,
      name: sharedT('addEmployees')
    },
    {
      id: 9,
      value: 9,
      name: sharedT('reviewStatistics')
    }
  ];
};
////
////
////
////
export const employeesPermissionsValues = (t) => {
  return {
    editProducts: '1',
    deleteProducts: '2',
    addProducts: '3'
  };
};
export const employeesPermissionsArr = (t) => {
  const sharedT = (key) => t(`employeePermissions.${key}`);
  return [
    {
      id: 1,
      value: 1,
      name: sharedT('editProducts')
    },
    {
      id: 2,
      value: 2,
      name: sharedT('deleteProducts')
    },
    {
      id: 3,
      value: 3,
      name: sharedT('addProducts')
    }
  ];
};

export const allPermissionsArr = (t) => {
  return [...storePermissionsArr(t), ...employeesPermissionsArr(t)];
};
