import React, { useState, useContext } from 'react';
import { Button } from 'antd';
import useDigitInput from 'react-digit-input';
import {
  forgetPasswordEnterCodeApi,
  forgetPasswordResendCodeApi
} from '../../apis/auth/forgetPassApis';
import checkRes from '../../utils/checkRes';
import ForgetPasswordContext from '../../contexts/forget-password-context/ForgetPasswordContext';
import './ForgetPassword.scss';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import { useTranslation } from 'react-i18next';
import routerLinks from '../../components/app/routerLinks';
import { useNavigate } from 'react-router-dom';
import LoadingModal from '../../common/loading-modal/LoadingModal';

const btnTypes = {
  confirmCode: 1,
  resendCode: 2
};
const ForgetPasswordFormEnterCode = () => {
  const [submitCodeCount, setSubmitCodeCount] = React.useState(0);
  const [value, onChange] = React.useState('');
  const [err, setErr] = useState('');
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 4,
    value,
    onChange
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const sharedT = (key) => t(`forgetPasswordPage.${key}`);

  const {
    setForgetPasswordFormEnterEmailAppended,
    setForgetPasswordFormEnterCodeAppended,
    setForgetPasswordFormResetPasswordAppended,
    user,
    setUser
  } = useContext(ForgetPasswordContext);
  React.useEffect(() => {
    return () => {
      onChange('');
    };
  }, []);
  const [isLoadingState, setIsLoadingState] = React.useState({
    type: '',
    isLoading: false
  });

  const customApiRequest = useCustomApiRequest();
  const onSubmit = (e) => {
    e.preventDefault();
    if (value?.trim().length === 4) {
      setErr('');
      setIsLoadingState({
        type: btnTypes.confirmCode,
        isLoading: true
      });
      customApiRequest(
        forgetPasswordEnterCodeApi(user?.token, {
          code: value
        }),
        (res) => {
          setIsLoadingState({
            type: btnTypes.confirmCode,
            isLoading: false
          });
          if (checkRes(res)) {
            if (user && typeof user === 'object') {
              setUser({
                ...user,
                isUserEnteredForgetPasswordActivationCode: true
              });
            }
            setForgetPasswordFormEnterEmailAppended(false);
            setForgetPasswordFormEnterCodeAppended(false);
            setForgetPasswordFormResetPasswordAppended(true);
            navigate(routerLinks?.forgetPasswordNewPasswordRoute);
          } else {
            setSubmitCodeCount((prev) => prev + 1);
          }
        },
        (error) => {
          setIsLoadingState({
            type: btnTypes.confirmCode,
            isLoading: false
          });
          setSubmitCodeCount((prev) => prev + 1);
        }
      );
    } else {
      setErr('الكود غير صحيح');
    }
  };

  /////
  /////
  ///// RESEND CODE
  const onResendCode = (e) => {
    e.preventDefault();

    setIsLoadingState({
      type: btnTypes.resendCode,
      isLoading: true
    });
    customApiRequest(
      forgetPasswordResendCodeApi(user?.token, {
        check: 2 // 1 => in active user registeration resend code, 2 => in foreget password resend code
      }),
      (res) => {
        setIsLoadingState({
          type: btnTypes.resendCode,
          isLoading: false
        });
      },
      (error) => {
        setIsLoadingState({
          type: btnTypes.resendCode,
          isLoading: false
        });
      }
    );
  };

  return (
    <form onSubmit={onSubmit} className="forget-pass-digits-form">
      <div className="digits-wrap">
        <input inputMode="decimal" autoFocus {...digits[0]} />
        <input inputMode="decimal" {...digits[1]} />
        <input inputMode="decimal" {...digits[2]} />
        <input inputMode="decimal" {...digits[3]} />
      </div>
      {err && (
        <p style={{ color: 'red', textAlign: 'center', marginTop: 8 }}>{err}</p>
      )}

      <div className="dont-btn-wrap">
        <span>{sharedT('dont')}</span>
        <button
          // loading={
          //   isLoadingState?.type === btnTypes.resendCode &&
          //   isLoadingState?.isLoading
          // }
          type="button"
          className="resend-btn"
          onClick={onResendCode}
        >
          {sharedT('resend')}
        </button>
      </div>

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: 22
        }}
      >
        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={
            isLoadingState?.type === btnTypes.confirmCode &&
            isLoadingState?.isLoading
          }
        >
          {sharedT('send')}
        </Button>
        {/* {submitCodeCount > 0 && (
          <Button
            className="submit-btn"
            htmlType="button"
            type="primary"
            // icon={<LoginOutlined />}
            loading={
              isLoadingState?.type === btnTypes.resendCode &&
              isLoadingState?.isLoading
            }
            onClick={onResendCode}
          >
            {sharedT('resend')}
          </Button>
        )} */}
      </div>

      {isLoadingState?.isLoading && <LoadingModal />}
    </form>
  );
};

export default ForgetPasswordFormEnterCode;
