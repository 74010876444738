const AddIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 13.5576H6C5.59 13.5576 5.25 13.2176 5.25 12.8076C5.25 12.3976 5.59 12.0576 6 12.0576H18C18.41 12.0576 18.75 12.3976 18.75 12.8076C18.75 13.2176 18.41 13.5576 18 13.5576Z"
        fill={color || '#0B0D0F'}
      />
      <path
        d="M12 19.5576C11.59 19.5576 11.25 19.2176 11.25 18.8076V6.80762C11.25 6.39762 11.59 6.05762 12 6.05762C12.41 6.05762 12.75 6.39762 12.75 6.80762V18.8076C12.75 19.2176 12.41 19.5576 12 19.5576Z"
        fill={color || '#0B0D0F'}
      />
    </svg>
  );
};

export default AddIcon;
