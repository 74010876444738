import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AddIcon from '../../common/icons/AddIcon';
import { LoadingOutlined } from '@ant-design/icons';
// import PlusIcon from '../../common/icons/PlusIcon';
import './FaqSection.scss';

const { Panel } = Collapse;

const FaqSection = () => {
  const { t } = useTranslation();

  const isLoadingFaq = useSelector((store) => store.general.isLoadingFaq);
  const fetchedFaq = useSelector((store) => store.general.fetchedFaq);

  const renderQA = () => {
    if (isLoadingFaq) {
      return (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 332
            }}
          >
            <LoadingOutlined style={{ fontSize: 20 }} />
          </div>
        </div>
      );
    } else if (fetchedFaq?.length > 0) {
      return (
        <Collapse
          className="sec-collapse"
          // accordion
          bordered={false}
          expandIconPosition="end"
          expandIcon={() => <AddIcon />}
        >
          {fetchedFaq.map((q) => (
            <Panel header={q?.question || '--'} key={q?.id}>
              <p>{q?.answer || '---'}</p>
            </Panel>
          ))}
        </Collapse>
      );
    }
    return null;
  };

  return (
    <section className="faq-section">
      <div className="custom-container">
        <div className="sec-title">{t('faq.title')}</div>
        <div className="sec-sub-title">{t('faq.sub')}</div>

        <div className="sec-content">{renderQA()}</div>
      </div>
    </section>
  );
};

export default FaqSection;
