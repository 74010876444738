import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';
import './HomeCountersSection.scss';

const HomeCountersSection = () => {
  const { t } = useTranslation();
  const renderCount = (value, title) => {
    if (value)
      return (
        <div className="counter-wrap">
          <div className="value-plus-wrap">
            <CountUp
              // decimals={4}
              // decimal=","
              separator=","
              enableScrollSpy
              className="count-value"
              end={parseInt(value)}
            />
            +
          </div>
          <div className="count-label">{title}</div>
        </div>
      );
    return null;
  };
  return (
    <section className="home-counters-section">
      <div className="custom-container">
        <div className="counts-list">
          {renderCount(10, t('counts.projects'))}
          {renderCount(30, t('counts.suppliers'))}
          {renderCount(30, t('counts.contractors'))}
          {renderCount(1000, t('counts.requests'))}
        </div>
      </div>
    </section>
  );
};

export default HomeCountersSection;
