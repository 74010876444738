export const sharedDefaultValues = {
  subject: '',
  country_id: '',
  city_id: '',
  description: '',
  service_id: '',
  subService_id: '',
  subSubService_id: '',
  endUserType: '',
  orderFiles: null
};

export const publicQuotesDefaultValue = {
  ...sharedDefaultValues,
  companyType: '',
  products: [
    {
      productName: '',
      unit_id: '',
      quantity: ''
    }
  ]
};

export const contractorQuoteDefaultValues = {
  ...sharedDefaultValues,
  products: [
    {
      productName: '',
      unit_id: '',
      quantity: ''
    }
  ],
  company_id: ''
};

export const supplierQuoteDefaultValues = {
  ...sharedDefaultValues,
  products: [
    {
      productName: '',
      product_id: '',
      unit_id: '',
      quantity: '',
      option_id: ''
    }
  ],
  company_id: '',
  negotiatePrice: ''
};
