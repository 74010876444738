// create quote action

import { createAsyncThunk } from '@reduxjs/toolkit';
import createPublicContractorQuoteApi from '../../apis/user-quotes-api/createPublicContractorQuoteApi';
import createSupplierQuoteApi from '../../apis/user-quotes-api/createSupplierQuoteApi';
import customApiRequest from '../../helpers/customApiRequest';

// create pblic or contractor quote action
export const createPublicContractorQuoteAction = createAsyncThunk(
  'quote/createPublicContractorQuoteAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createPublicContractorQuoteApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

// create supplier quote action
export const createSupplierQuoteAction = createAsyncThunk(
  'quote/createSupplierQuoteAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createSupplierQuoteApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
