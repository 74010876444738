/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form } from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import mapImg from '../../assets/imgs/test/Map.png';
import './HomeContactSection.scss';
import { useTranslation } from 'react-i18next';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { useState } from 'react';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import AntdTextarea from '../../common/antd-form-components/AntdTextarea';
import { useEffect } from 'react';
import CustomMap from '../../components/custom-map/CustomMap';
import { useDispatch, useSelector } from 'react-redux';
import { contactUsAction } from '../../redux/general-slice/generalSliceThunkApis';
import checkRes from '../../utils/checkRes';

const HomeContactSection = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [isLoading] = useState(false);
  const fetchedAboutData = useSelector(
    (store) => store.general.fetchedAboutData
  );
  const isSubmittingContact = useSelector(
    (store) => store.general.isSubmittingContact
  );
  const generalLabelStr = (v) => t(`contactForm.${v}.label`);
  const generalrequiredErrStr = (v) => t(`contactForm.${v}.errors.required`);
  const generalTypeErrorStr = (v) => t(`contactForm.${v}.errors.type_error`);
  const generalMinErrorStr = (v, min) =>
    t(`contactForm.${v}.errors.min`, {
      min
    });

  const [form] = Form.useForm();
  const {
    control,
    handleSubmit,
    reset,
    // setError,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required(generalrequiredErrStr('name')),
        phone: Yup.string()
          .required(generalrequiredErrStr('phone'))
          // .matches(/^[0-9]+$/, generalTypeErrorStr('phone'))
          .min(10, generalMinErrorStr('phone', 10)),
        email: Yup.string()
          .required(generalrequiredErrStr('email'))
          .email(generalTypeErrorStr('email')),
        message: Yup.string().required(generalrequiredErrStr('message'))
      })
    ),
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      message: ''
    }
  });

  useEffect(() => {
    if (errors && Object.keys(errors)?.length > 0) {
      reset({
        name: '',
        phone: '',
        email: '',
        message: ''
      });
    }
  }, [i18n.language]);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('name', data.name || '');
    formData.append('phone', data.phone || '');
    formData.append('email', data.email || '');
    formData.append('message', data.message || '');
    const action = await dispatch(
      contactUsAction({
        dataToBeSubmitted: formData
      })
    );
    if (checkRes(action?.payload?.res)) {
      reset({
        name: '',
        phone: '',
        email: '',
        message: ''
      });
    }
  };

  const [selectedAddress, setSelectedAddress] = useState('');
  const [selectedLocation, setSelectedLocation] = useState({
    lat: '',
    lng: ''
  });

  useEffect(() => {
    if (fetchedAboutData?.lat && fetchedAboutData?.lng) {
      setSelectedLocation({
        lat: parseFloat(fetchedAboutData.lat),
        lng: parseFloat(fetchedAboutData?.lng)
      });
    }
  }, [fetchedAboutData?.lat, fetchedAboutData?.lgn]);

  return (
    <section className="home-contact-section">
      <div className="custom-container">
        <div className="section-content-wrap">
          <div className="contact-form">
            <p className="form-t">{t('contactForm.formTitle')}</p>
            <Form
              className="custom-shared-form saloon-settings-form"
              form={form}
              layout="vertical"
              onFinish={handleSubmit(onSubmit)}
            >
              <div className="form-body">
                <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
                  <AntdTextField
                    className="custom-text-field"
                    name="name"
                    type="text"
                    errorMsg={errors?.name?.message}
                    validateStatus={errors?.name ? 'error' : ''}
                    control={control}
                  />
                  <p className="custom-label">{generalLabelStr('name')}</p>
                </div>
                <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
                  <AntdTextField
                    className="custom-text-field"
                    name="phone"
                    type="text"
                    errorMsg={errors?.phone?.message}
                    validateStatus={errors?.phone ? 'error' : ''}
                    control={control}
                  />
                  <p className="custom-label">{generalLabelStr('phone')}</p>
                </div>
                <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
                  <AntdTextField
                    className="custom-text-field"
                    name="email"
                    type="text"
                    errorMsg={errors?.email?.message}
                    validateStatus={errors?.email ? 'error' : ''}
                    control={control}
                  />
                  <p className="custom-label">{generalLabelStr('email')}</p>
                </div>

                <div className={`custom-text-area-wrapper ${i18n.dir()}`}>
                  <AntdTextarea
                    rows={6}
                    className="custom-text-area"
                    name="message"
                    errorMsg={errors?.message?.message}
                    validateStatus={errors?.message ? 'error' : ''}
                    control={control}
                  />
                  <p className="custom-label">{generalLabelStr('message')}</p>
                </div>

                <div className="save-cancel-btns">
                  <Button
                    className="submit-btn"
                    htmlType="submit"
                    type="primary"
                    // icon={<LoginOutlined />}
                    loading={isLoading}
                  >
                    {t('contactForm.submitBtn.label')}
                  </Button>
                </div>
              </div>

              {isLoading && <LoadingModal />}
            </Form>
          </div>
          <div className="map-wrap">
            {/* <img src={mapImg} alt="map" /> */}
            <CustomMap
              width="100%"
              height="500px"
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
              selectedAddress={selectedAddress}
              setSelectedAddress={setSelectedAddress}
              isDraggable={false}
            />
          </div>
        </div>
      </div>
      {isSubmittingContact && <LoadingModal />}
    </section>
  );
};

export default HomeContactSection;
