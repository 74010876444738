import { t } from 'i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import CustomBreadcrumb from '../../common/bread-crumb/Breadcrumb';
import CustomImage from '../../common/custom-image/CustomImage';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import routerLinks from '../../components/app/routerLinks';
import ProfileChangePasswordForm from './ProfileChangePasswordForm';
import './ProfilePage.scss';
import ProfileSettingsForm from './ProfileSettingsForm';
import ProfileUploadImageModal from './ProfileUploadImageModal';

const ProfilePage = () => {
  const sharedT = (key) => t(`profilePage.${key}`);

  const user = useSelector((store) => store.user.user);
  const [settingsFormOpened, setSettingsFormOpened] = useState(false);
  const [passFormOpened, setPassFormOpened] = useState(false);
  const [uploadImgModalOpened, setUploadImgModalOpened] = useState(false);
  const [isSubmittingImg, setIsSubmittingImg] = useState(false);

  const renderProfileSettings = () => {
    return (
      <div className="profile-settings">
        <div className="sec-content">
          <div className="label-value-row">
            <p className="row-p">{sharedT('fullName')}</p>
            <p className="row-v">{user?.name || '---'}</p>
          </div>
          <div className="label-value-row">
            <p className="row-p">{sharedT('email')}</p>
            <p className="row-v">{user?.email || '---'}</p>
          </div>
          <div className="label-value-row">
            <p className="row-p">{sharedT('phone')}</p>
            <p className="row-v">{user?.phone || '---'}</p>
          </div>
          <div className="two-cols-row">
            <div className="label-value-row">
              <p className="row-p">{sharedT('country')}</p>
              <p className="row-v">{user?.country?.name || '---'}</p>
            </div>
            <div className="label-value-row">
              <p className="row-p">{sharedT('city')}</p>
              <p className="row-v">{user?.city?.name || '---'}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="profile-page">
      <CustomBreadcrumb
        arr={[
          {
            title: t('breadcrumbs.home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: t('breadcrumbs.profile'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <h2 className="page-title">{sharedT('pageTitle')}</h2>
        <div className="page-body">
          <div className="profile-card">
            <CustomImage src={user?.image} className="profile-img" />
            <p className="profile-name">{user?.name}</p>
            <p className="profile-email">{user?.email}</p>
            <button
              onClick={() => {
                setUploadImgModalOpened(true);
              }}
              className="upload-btn"
            >
              {sharedT('uploadPhoto')}
            </button>
          </div>

          <div className="profile-main-content">
            <div className="settings-wrapper">
              <div className="sec-title-btn-wrapper">
                <div className="sec-title-sub">
                  <p className="title-p">{sharedT('profileSettings')}</p>
                  <p className="sub-title-p">{sharedT('manageAccount')}</p>
                </div>

                <button
                  onClick={() => {
                    setSettingsFormOpened((prev) => !prev);
                  }}
                  className="edit-btn"
                >
                  {settingsFormOpened ? sharedT('cancel') : sharedT('edit')}
                </button>
              </div>

              {settingsFormOpened ? (
                <ProfileSettingsForm
                  setSettingsFormOpened={setSettingsFormOpened}
                />
              ) : (
                renderProfileSettings()
              )}
            </div>

            <div className="change-pass-wrapper">
              <div className="profile-change-pass-sec">
                <div className="sec-title-btn-wrapper">
                  <div className="sec-title-sub">
                    <p className="title-p">{sharedT('changePassword')}</p>
                    <p className="sub-title-p">{sharedT('changeText')}</p>
                  </div>

                  <button
                    onClick={() => {
                      setPassFormOpened((prev) => !prev);
                    }}
                    className="edit-btn"
                  >
                    {passFormOpened ? sharedT('cancel') : sharedT('edit')}
                  </button>
                </div>
              </div>

              {passFormOpened && (
                <ProfileChangePasswordForm
                  setPassFormOpened={setPassFormOpened}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {
        <ProfileUploadImageModal
          modalOpened={uploadImgModalOpened}
          setModalOpened={setUploadImgModalOpened}
          isSubmitting={isSubmittingImg}
          setIsSubmittingImg={setIsSubmittingImg}
        />
      }

      {isSubmittingImg && <LoadingModal />}
    </div>
  );
};

export default ProfilePage;
