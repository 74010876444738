/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AntdSelectOption from '../antd-form-components/AntdSelectOption';

const CountryCityOptions = ({
  countryName,
  cityName,
  countries,
  countryLabel,
  cityLabel,
  parentClassName,
  //
  watch,
  setValue,
  control,
  errors
}) => {
  const { i18n } = useTranslation();

  // useEffect(() => {
  //   if (watch(countryName)) {
  //     setValue(cityName, '');
  //   }
  // }, [watch(countryName)]);

  const handleCityOptions = (countryId) => {
    if (countryId && countries?.length > 0) {
      const foundCountry = countries.find((c) => c.id == countryId);
      if (foundCountry && foundCountry.cities?.length > 0) {
        return foundCountry.cities.map((city) => ({
          title: city?.name,
          value: String(city?.id)
        }));
      }
    }
    return [];
  };

  return (
    <>
      <div className={`custom-select-field-wrapper without-icon ${i18n.dir()}`}>
        <AntdSelectOption
          className="custom-select-field"
          name={countryName}
          errorMsg={errors[countryName] && errors[countryName].message}
          validateStatus={errors[countryName] ? 'error' : ''}
          control={control}
          setValue={setValue}
          options={
            countries?.length > 0 &&
            countries.map((obj) => ({
              title: obj?.name,
              value: obj?.id
            }))
          }
          showSearch={true}
          // onSearch={onSearch}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          formClassName={parentClassName}
        />
        <p className="custom-label">{countryLabel}</p>
      </div>

      <div className={`custom-select-field-wrapper without-icon ${i18n.dir()}`}>
        <AntdSelectOption
          className="custom-select-field"
          name={cityName}
          errorMsg={errors[cityName] && errors[cityName].message}
          validateStatus={errors[cityName] ? 'error' : ''}
          control={control}
          setValue={setValue}
          options={handleCityOptions(watch(countryName))}
          showSearch={true}
          // onSearch={onSearch}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          formClassName={parentClassName}
        />
        <p className="custom-label">{cityLabel}</p>
      </div>
    </>
  );
};

export default CountryCityOptions;
