/* eslint-disable react-hooks/exhaustive-deps */
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Pagination } from 'antd';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import getAllPlansApi from '../../apis/plans-apis/getAllPlansApi';
import generalRouterLinks from '../../components/app/general-routes/gerneralRouterLinks';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import checkRes from '../../utils/checkRes';
import SubscriptionBox from './SubscriptionBox';
import './PricesPage.scss';
import LoadingModal from '../../common/loading-modal/LoadingModal';

const PricesPage = () => {
  const sharedT = (key) => t(`pricesPage.${key}`);
  const { t, i18n } = useTranslation();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const customApiRequest = useCustomApiRequest();

  const user = useSelector((store) => store.user.user);
  console.log({ user });

  const [busy, setBusy] = useState(false);
  const [plans, setPlans] = useState([]);
  const [pagination, setPagination] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    setBusy(true);
    customApiRequest(
      getAllPlansApi({ ...values }),
      (res) => {
        if (isMounted) {
          setBusy(false);
          if (checkRes(res) && res?.data?.data) {
            if (res.data.data.data?.length > 0) setPlans(res.data.data.data);
            if (res.data.data.pagination) {
              setPagination(res.data.data.pagination);
            }
          } else {
            setPlans([]);
          }
        }
      },
      (error) => {
        setBusy(false);
      }
    );

    return () => (isMounted = false);
  }, [i18n.language, search]);

  if (busy) {
    return <LoadingModal />;
  } else if (plans?.length === 0) {
    return (
      <div className="prices-page">
        <div className="custom-container">
          <h2>{sharedT('subscriptionPlans')}</h2>
        </div>
        <div className="page-body">
          <h2>{sharedT('no_plans')}</h2>
        </div>
      </div>
    );
  } else if (plans?.length > 0)
    return (
      <div className="prices-page">
        <div className="custom-container">
          <h2>{sharedT('subscriptionPlans')}</h2>
          <div className="page-body">
            <div className="sub-boxs-wrapper">
              {plans.map((p) => (
                <SubscriptionBox key={p.id} plan={p} />
              ))}
            </div>

            {pagination && pagination?.total > 0 && (
              <div className="pagination-wrapper">
                <Pagination
                  nextIcon={
                    i18n.dir() === 'ltr' ? <RightOutlined /> : <LeftOutlined />
                  }
                  prevIcon={
                    i18n.dir() === 'ltr' ? <LeftOutlined /> : <RightOutlined />
                  }
                  locale={{
                    jump_to: i18n?.language === 'ar' ? 'إذهب إلى' : 'Go to',
                    // page: i18n?.language === 'ar' ? 'الصفحة' : 'Page'
                    page: ''
                  }}
                  showSizeChanger={false}
                  defaultCurrent={1}
                  current={pagination.current_page}
                  pageSize={pagination.per_page}
                  total={pagination.total}
                  // itemRender={itemRender}
                  onChange={(page, pageSize) => {
                    navigate(
                      `${generalRouterLinks?.pricesRoute}?page=${page || 1}`
                    );
                  }}
                  hideOnSinglePage={true}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );

  return null;
};

export default PricesPage;
