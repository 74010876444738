import { useTranslation } from 'react-i18next';
import './HomeRequestsSection.scss';
import appStoreImg from '../../assets/imgs/icons/app-store.png';
import googlePlayImg from '../../assets/imgs/icons/google-play.png';
import reqMockupImg from '../../assets/imgs/patterns/home-req-mockup.png';
import { useSelector } from 'react-redux';
import isUrl from 'is-url-superb';

const HomeRequestsSection = () => {
  const { t } = useTranslation();
  const fetchedAboutData = useSelector(
    (store) => store.general.fetchedAboutData
  );

  return (
    <section className="home-req-section">
      <div className="custom-container">
        <div className="sec-content">
          <div className="text-wrap">
            <div className="main-t">{t('homeReq.title')}</div>
            <div className="sub-t">{t('homeReq.subTitle')}</div>

            <div className="get-app">
              <p className="get-t">{t('homeReq.getApp')}</p>
              <div className="stores-wrap">
                {fetchedAboutData?.google && isUrl(fetchedAboutData.google) && (
                  <a
                    href={fetchedAboutData.google}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={googlePlayImg} alt="" />
                  </a>
                )}

                {fetchedAboutData?.apple && isUrl(fetchedAboutData?.apple) && (
                  <a
                    href={fetchedAboutData.apple}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={appStoreImg} alt="" />
                  </a>
                )}
              </div>
            </div>
          </div>

          <div className="mockup-wrap">
            <img src={reqMockupImg} alt="send request" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeRequestsSection;
