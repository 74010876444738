

import axios from 'axios';
import apisUrls from '../apisUrls';

const getContractorOffersApi = async (filterObj) => {
  return axios.get(apisUrls.getContractorOffers(filterObj));
};

export default getContractorOffersApi;
