import { createSlice } from '@reduxjs/toolkit';
import checkRes from '../../utils/checkRes';

import {
  addCompanyToFavAction,
  getCompaniesAction,
  getRateCompanyAction,
  getSingleCompanyAction,
  getSupplierProductsAction,
  rateCompanyAction
} from './companiesSliceThunkApis';

const initialState = {
  //
  isLoadingCompanies: false,
  fetchedCompanies: [],
  fetchSingleCompanyCount: 0,
  isSubmittingFav: false,
  fetchAllCompaniesCount: 0,
  companiesPagination: {
    current_page: 1,
    par_page: 0,
    total: 0,
    total_pages: 1
  },
  //
  //
  isLoadingSingleCompany: false,
  fetchedSingleCompany: null,
  //
  isLoadingCompanyRate: false,
  companyRateModalOpened: false,
  ratesModalOpened: false,
  fetchedCompanyRate: [],
  fetchCompanyRateCount: 0,
  isSubmittingCompanyRate: false,
  //
  //
  isLoadingSupplierProducts: false,
  fetchedSupplierProducts: [],
  supplierProductsPagination: {
    current_page: 1,
    par_page: 0,
    total: 0,
    total_pages: 1
  },
  //
  selectedSupplierProduct: null,
  cart: !localStorage.getItem('cart')
    ? {
        cartSupplierId: '',
        products: []
      }
    : JSON.parse(localStorage.getItem('cart'))
};

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCompaniesAction: (sliceState, action) => {
      sliceState.fetchedCompanies = action.payload;
    },
    setCompaniesPaginationAction: (sliceState, action) => {
      sliceState.companiesPagination = action.payload;
    },
    // //
    // //
    // //
    //
    setCompanyRateModalOpened: (sliceState, action) => {
      sliceState.companyRateModalOpened = action.payload;
    },
    setRatesModalOpened: (sliceState, action) => {
      sliceState.ratesModalOpened = action.payload;
    },
    //
    setSelectedSupplierProduct: (sliceState, action) => {
      sliceState.selectedSupplierProduct = action.payload;
    },
    addProductToCart: (sliceState, action) => {
      const supplier_id = action.payload.supplier_id;
      const pro = action.payload.product;
      // first check if the cart is empty
      if (sliceState.cart?.products?.length === 0) {
      } else {
        sliceState.cart = {
          supplier_id: action.payload.supplier_id
        };
      }
    },
    ////////////////
    // reset cart
    ///////////////
    resetCart: (sliceState, action) => {
      sliceState.cart = {
        cartSupplierId: '',
        products: []
      };
    }
  },
  extraReducers: {
    // companies
    [getCompaniesAction.pending]: (sliceState, action) => {
      sliceState.isLoadingCompanies = true;
    },
    [getCompaniesAction.fulfilled]: (sliceState, action) => {
      sliceState.isLoadingCompanies = false;
      const data = action?.payload?.res?.data?.data;
      sliceState.fetchedCompanies = data?.data;
      if (data?.pagination) sliceState.companiesPagination = data.pagination;
    },
    [getCompaniesAction.rejected]: (sliceState, action) => {
      sliceState.isLoadingCompanies = false;
    },
    ///////////////////
    // single company
    [getSingleCompanyAction.pending]: (sliceState, action) => {
      sliceState.isLoadingSingleCompany = true;
    },
    [getSingleCompanyAction.fulfilled]: (sliceState, action) => {
      sliceState.isLoadingSingleCompany = false;
      const data = action?.payload?.res?.data?.data;
      sliceState.fetchedSingleCompany = data;
    },
    [getSingleCompanyAction.rejected]: (sliceState, action) => {
      sliceState.isLoadingSingleCompany = false;
    },
    ///////////////////
    // get company rate
    [getRateCompanyAction.pending]: (sliceState, action) => {
      sliceState.isLoadingCompanyRate = true;
    },
    [getRateCompanyAction.fulfilled]: (sliceState, action) => {
      sliceState.isLoadingCompanyRate = false;
      const data = action?.payload?.res?.data?.data;
      sliceState.fetchedCompanyRate = data;
    },
    [getRateCompanyAction.rejected]: (sliceState, action) => {
      sliceState.isLoadingCompanyRate = false;
    },
    ///////////////////////
    // rate company action
    [rateCompanyAction.pending]: (sliceState, action) => {
      sliceState.isSubmittingCompanyRate = true;
    },
    [rateCompanyAction.fulfilled]: (sliceState, action) => {
      sliceState.isSubmittingCompanyRate = false;
      if (checkRes(action?.payload?.res)) {
        sliceState.fetchSingleCompanyCount += 1;
        // sliceState.fetchCompanyRateCount += 1;
        sliceState.companyRateModalOpened = false;
      }
    },
    [rateCompanyAction.rejected]: (sliceState, action) => {
      sliceState.isSubmittingCompanyRate = false;
    },
    ///////////////////
    // supplier products
    [getSupplierProductsAction.pending]: (sliceState, action) => {
      sliceState.isLoadingSupplierProducts = true;
    },
    [getSupplierProductsAction.fulfilled]: (sliceState, action) => {
      sliceState.isLoadingSupplierProducts = false;
      const data = action?.payload?.res?.data?.data;
      sliceState.fetchedSupplierProducts = data?.data;
      if (data?.pagination)
        sliceState.supplierProductsPagination = data.pagination;
    },
    [getSupplierProductsAction.rejected]: (sliceState, action) => {
      sliceState.isLoadingSupplierProducts = false;
    },
    ///////////////////////
    // add comp to fav
    [addCompanyToFavAction.pending]: (sliceState, action) => {
      sliceState.isSubmittingFav = true;
    },
    [addCompanyToFavAction.fulfilled]: (sliceState, action) => {
      sliceState.isSubmittingFav = false;
      if (checkRes(action?.payload?.res)) {
        // sliceState.fetchSingleCompanyCount += 1;
        if (
          sliceState.fetchedSingleCompany &&
          typeof sliceState.fetchedSingleCompany
        )
          sliceState.fetchedSingleCompany = {
            ...sliceState.fetchedSingleCompany,
            is_fav: !sliceState.fetchedSingleCompany?.is_fav
          };
      }
    },
    [addCompanyToFavAction.rejected]: (sliceState, action) => {
      sliceState.isSubmittingFav = false;
    }

    ///////////////////////
  }
});

const companiesReducer = companiesSlice.reducer;

export const {
  setCompaniesAction,
  setSelectedSupplierProduct,
  setCompanyRateModalOpened,
  setRatesModalOpened
} = companiesSlice.actions;

export default companiesReducer;
