import { Modal } from 'antd';
import { t } from 'i18next';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import imgsSrcs from '../../helpers/imgsSrcs';
import MakeOfferForm from './MakeOfferForm';
import './MakeOfferModal.scss';

const MakeOfferModal = ({
  productsArr,
  modalOpened,
  setModalOpened,
  isSubmitting,
  formAction,
  dataObj
}) => {
  const sharedT = (key) => t(`makeOfferModal.${key}`);

  return (
    <>
      <Modal
        maskStyle={{
          zIndex: 99999
        }}
        wrapClassName="make-offer-modal-wrapper"
        // destroyOnClose
        forceRender
        className="make-offer-modal"
        closeIcon={<img src={imgsSrcs.closeCircleImg} alt="close" />}
        width="96%"
        style={{ maxWidth: '762px' }}
        title={
          <div className="modal-h">
            <p className="main-header-t">{sharedT('title')}</p>
          </div>
        }
        open={modalOpened}
        onOk={() => {
          setModalOpened(false);
        }}
        onCancel={() => {
          setModalOpened(false);
        }}
        footer={false}
      >
        <MakeOfferForm
          modalOpened={modalOpened}
          setModalOpened={setModalOpened}
          productsArr={productsArr}
          formAction={formAction}
          dataObj={dataObj}
        />
      </Modal>
      {isSubmitting && <LoadingModal />}
    </>
  );
};

export default MakeOfferModal;
