import { Modal } from 'antd';
import { companyTypes } from '../../const-values/accountTypes';
import { Link } from 'react-router-dom';
import generalRouterLinks from '../../components/app/general-routes/gerneralRouterLinks';
import CustomImage from '../../common/custom-image/CustomImage';
import { useTranslation } from 'react-i18next';
import './SubServicesModal.scss';

const SubServicesModal = ({
  modalOpened,
  setModalOpened,
  modalTitle,
  fetchedSingleCompany,
  setSelectedService,
  subServArr
}) => {
  const { i18n } = useTranslation();
  const SubServiceCard = ({ subServ }) => {
    if (fetchedSingleCompany?.companyType === companyTypes()?.supplier) {
      if (subServ?.subServices && subServ?.subServices?.length > 0) {
        return (
          <button
            type="button"
            onClick={() => {
              setSelectedService(subServ);
            }}
            className="service-wrapper"
          >
            <CustomImage className="service-img" src={subServ?.logo} />
            <p className="serv-name">{subServ?.name}</p>
          </button>
        );
      }
      return (
        <Link
          to={generalRouterLinks?.supplierProductsRoute(
            fetchedSingleCompany.id,
            subServ?.id
          )}
          className="service-wrapper"
        >
          <CustomImage className="service-img" src={subServ?.logo} />
          <p className="serv-name">{subServ?.name}</p>
        </Link>
      );
    } else {
      if (subServ?.subServices && subServ?.subServices?.length > 0) {
        return (
          <button
            type="button"
            onClick={() => {
              setSelectedService(subServ);
            }}
            className="service-wrapper"
          >
            <CustomImage className="service-img" src={subServ?.logo} />
            <p className="serv-name">{subServ?.name}</p>
          </button>
        );
      }
      return (
        <div className="service-wrapper">
          <CustomImage className="service-img" src={subServ?.logo} />
          <p className="serv-name">{subServ?.name}</p>
        </div>
      );
    }
  };
  const renderContent = () => {
    if (!subServArr || subServArr?.length === 0)
      return i18n.language === 'ar'
        ? 'لا توجد خدمات متاحه!!'
        : 'No services found!!';
    else if (subServArr?.length > 0) {
      return (
        <div className="services-list">
          {subServArr.map((item) => (
            <SubServiceCard key={item?.id} subServ={item} />
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <Modal
      className="shared-custom-modal sub-services-modal"
      width="96%"
      style={{ maxWidth: '842px' }}
      title={modalTitle}
      open={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedService(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedService(null);
      }}
      footer={false}
    >
      {renderContent()}
    </Modal>
  );
};

export default SubServicesModal;
