import { createSlice } from '@reduxjs/toolkit';
import checkRes from '../../utils/checkRes';
import {
  createPublicContractorQuoteAction,
  createSupplierQuoteAction
} from './quoteSliceThunkApi';

const initialState = {
  quoteType: '',
  quoteModalOpened: false,
  isSubmittingQuote: false,
  submittedQuote: null
};

const quoteSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    setQuoteType: (sliceState, action) => {
      sliceState.quoteType = action.payload;
    },
    setQuoteModalOpened: (sliceState, action) => {
      sliceState.quoteModalOpened = action.payload;
    }
  },
  extraReducers: {
    // create public or contractor quote
    [createPublicContractorQuoteAction.pending]: (state) => {
      state.isSubmittingQuote = true;
    },
    [createPublicContractorQuoteAction.fulfilled]: (state, action) => {
      state.isSubmittingQuote = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        // state.quoteModalOpened = false;
        state.submittedQuote = res?.data?.data;
      }
    },
    [createPublicContractorQuoteAction.rejected]: (state) => {
      state.isSubmittingQuote = false;
    },
    //
    // create supplier quote
    [createSupplierQuoteAction.pending]: (state) => {
      state.isSubmittingQuote = true;
    },
    [createSupplierQuoteAction.fulfilled]: (state, action) => {
      state.isSubmittingQuote = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        // state.quoteModalOpened = false;
        state.submittedQuote = res?.data?.data;
      }
    },
    [createSupplierQuoteAction.rejected]: (state) => {
      state.isSubmittingQuote = false;
    }
    //
  }
});

const quoteReducer = quoteSlice.reducer;

export const { setQuoteType, setQuoteModalOpened } = quoteSlice.actions;

export default quoteReducer;
