/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { forgetPasswordEnterEmailApi } from '../../apis/auth/forgetPassApis';
import checkRes from '../../utils/checkRes';
import ForgetPasswordContext from '../../contexts/forget-password-context/ForgetPasswordContext';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import routerLinks from '../../components/app/routerLinks';

const ForgetPasswordFormEnterEmail = () => {
  const { t, i18n } = useTranslation();
  const sharedT = (key) => t(`forgetPasswordPage.${key}`);

  const schema = Yup.object().shape({
    phone: Yup.string().required(sharedT('phone.errors.required'))
    // .email(sharedT('email.errors.typeError'))
  });

  const {
    setForgetPasswordFormEnterEmailAppended,
    setForgetPasswordFormEnterCodeAppended,
    setUser,
    setIsSubmitting
  } = useContext(ForgetPasswordContext);
  const {
    control,
    handleSubmit,
    reset,
    // watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      phone: ''
    }
  });

  React.useEffect(() => {
    return () => {
      reset({ phone: '' });
    };
  }, []);
  const customApiRequest = useCustomApiRequest();
  const navigate = useNavigate();
  const onSubmit = (data) => {
    setIsSubmitting(true);
    customApiRequest(
      forgetPasswordEnterEmailApi(data),
      (res) => {
        setIsSubmitting(false);
        if (checkRes(res)) {
          setForgetPasswordFormEnterEmailAppended(false);
          setForgetPasswordFormEnterCodeAppended(true);
          setUser({
            ...res?.data?.data,
            isUserEnteredForgetPasswordActivationCode: false,
            isUserChangedForgettedPassword: false
          });
          navigate(routerLinks?.forgetPasswordActivateCodeRoute);
        }
      },
      (error) => {
        setIsSubmitting(false);
      }
    );
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        {/* <p className="label-p">البريد الاكترونى</p> */}

        <div className={`custom-text-field-wrapper with-prefix ${i18n.dir()}`}>
          <AntdTextField
            className="custom-text-field"
            name="phone"
            type="text"
            errorMsg={errors?.phone?.message}
            validateStatus={errors?.phone ? 'error' : ''}
            control={control}
          />
          <p className="custom-label">
            {sharedT('phone.label')}{' '}
            <span
              style={{
                direction: 'ltr'
              }}
            >
              ( 558615134 )
            </span>
          </p>
          <span className="prefix-span">
            <span
              style={{
                fontSize: 20,
                borderRadius: 2
              }}
              className={`fi fi-sa`}
            />{' '}
            {i18n.language === 'ar' ? '(966+)' : '(+966)'}
          </span>
        </div>

        {/* <AntdCheckbox name="remember" label="تذكرنى" control={control} /> */}
        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          // loading={isLoading}
        >
          {sharedT('send')}
        </Button>
      </div>
    </Form>
  );
};

export default ForgetPasswordFormEnterEmail;
