/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import isUrl from 'is-url-superb';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import footerFrameImg from '../../assets/imgs/footer-frame.svg';
import appStoreImg from '../../assets/imgs/icons/app-store-white.png';
import googlePlayImg from '../../assets/imgs/icons/google-play-white.png';
import m3roofImg from '../../assets/imgs/icons/m3roof-img.png';
import fbImg from '../../assets/imgs/icons/social-icons/facebook-img.png';
import instagramImg from '../../assets/imgs/icons/social-icons/instagram-img.png';
import linkedinImg from '../../assets/imgs/icons/social-icons/linkedin-img.png';
import twitterImg from '../../assets/imgs/icons/social-icons/twitter-img.png';
import Logo from '../../common/logo/Logo';
import accountTypes from '../../const-values/accountTypes';
import generalRouterLinks from '../app/general-routes/gerneralRouterLinks';
import './MainAppFooter.scss';

const MainAppFooter = () => {
  const user = useSelector((store) => store.user.user);
  const { t } = useTranslation();
  const fetchedAboutData = useSelector(
    (store) => store.general.fetchedAboutData
  );
  return (
    !user?.firstTime && (
      <footer
        style={{
          backgroundImage: `url(${footerFrameImg})`
        }}
        className="main-app-footer"
      >
        {(true || user?.accountType == accountTypes()?.user || !user) && (
          <div className="custom-container">
            <div className="main-content">
              <div className="logo-about-wrapper">
                <Logo className="footer-logo" colored />

                <p className="about-text">{t('footer.aboutText')}</p>
              </div>
              <div className="list-wrap useful-links-list">
                <div className="list-title">{t('footer.usefulLinks')}</div>
                <div className="list-itself">
                  <RouterLink to={generalRouterLinks?.aboutUsRoute}>
                    {t('footer.about')}
                  </RouterLink>
                  <RouterLink to={generalRouterLinks?.termsRoute}>
                    {t('footer.terms')}
                  </RouterLink>
                  <RouterLink to={generalRouterLinks?.privacyRoute}>
                    {t('footer.privacy')}
                  </RouterLink>
                  <RouterLink to={generalRouterLinks?.refundTermsRoute}>
                    {t('footer.refundTerms')}
                  </RouterLink>
                </div>
              </div>
              <div className="list-wrap useful-links-list">
                <div className="list-title">{t('footer.support')}</div>
                <div className="list-itself">
                  {/* <RouterLink to={generalRouterLinks?.blogsRoute}>
                    {t('footer.help')}
                  </RouterLink> */}
                  <RouterLink to={generalRouterLinks?.termsRoute}>
                    {t('footer.terms')}
                  </RouterLink>
                  <RouterLink to={generalRouterLinks?.privacyRoute}>
                    {t('footer.privacy')}
                  </RouterLink>
                  <RouterLink to={generalRouterLinks?.contactUsRoute}>
                    {t('footer.contact')}
                  </RouterLink>
                </div>
              </div>
              <div className="contact-us-list">
                <div className="list-title">{t('footer.installApp')}</div>

                <div className="stores-links">
                  <div className="links">
                    {fetchedAboutData?.google &&
                      isUrl(fetchedAboutData.google) && (
                        <a
                          href={fetchedAboutData.google}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={googlePlayImg} alt="" />
                        </a>
                      )}

                    {fetchedAboutData?.apple &&
                      isUrl(fetchedAboutData?.apple) && (
                        <a
                          href={fetchedAboutData.apple}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={appStoreImg} alt="" />
                        </a>
                      )}
                  </div>
                </div>

                <div className="m3roof-link">
                  <img src={m3roofImg} alt="m3roof" />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="footer-bottom-content">
          <div className="custom-container">
            {/* <Logo className="footer-logo" /> */}
            <div className="content">
              <p>{t('copyRights')}</p>
              <ul className="footer-payment-methods">
                <li>
                  {fetchedAboutData?.facebook &&
                    isUrl(fetchedAboutData.facebook) && (
                      <a
                        href={fetchedAboutData.facebook}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={fbImg} alt="mada" />
                      </a>
                    )}
                </li>
                <li>
                  {fetchedAboutData?.twitter &&
                    isUrl(fetchedAboutData.twitter) && (
                      <a
                        href={fetchedAboutData.twitter}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={twitterImg} alt="visa" />
                      </a>
                    )}
                </li>
                <li>
                  {fetchedAboutData?.instagram &&
                    isUrl(fetchedAboutData.instagram) && (
                      <a
                        href={fetchedAboutData.instagram}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={instagramImg} alt="master-card" />
                      </a>
                    )}
                </li>
                <li>
                  {fetchedAboutData?.linkedin &&
                    isUrl(fetchedAboutData.linkedin) && (
                      <a
                        href={fetchedAboutData.linkedin}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={linkedinImg} alt="master-card" />
                      </a>
                    )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    )
  );
};

export default MainAppFooter;
