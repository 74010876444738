import React from 'react';
import { useNavigate } from 'react-router-dom';
import routerLinks from '../components/app/routerLinks';
import { useDispatch } from 'react-redux';
import { userSignoutAction } from '../redux/user-slice/userSliceThunkApis';
import { removeCurrentUserAction } from '../redux/user-slice/userSlice';

const useSignout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoadingSignout, setIsLoadingSignout] = React.useState(false);
  const signMeOut = () => {
    dispatch(userSignoutAction()).then((obj) => {
      dispatch(removeCurrentUserAction());
      navigate(routerLinks?.signinPage);
    });
  };

  return { signMeOut, isLoadingSignout, setIsLoadingSignout };
};

export default useSignout;
