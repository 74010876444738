/* eslint-disable react-hooks/exhaustive-deps */
import { t } from 'i18next';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../common/bread-crumb/Breadcrumb';
import routerLinks from '../../components/app/routerLinks';
import './PublicSingleQuotePage.scss';
import { LoadingOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import imgsSrcs from '../../helpers/imgsSrcs';
import {
  companyOrderStatusArr,
  threeOrderStatusArr
} from '../../const-values/orderStatus';
import CustomImage from '../../common/custom-image/CustomImage';
import merchentRouterLinks from '../../components/app/merchent-routes/merchentRouterLinks';
import {
  getCompanySingleOrdersAction,
  makeOfferAction
} from '../../redux/company-orders-slice/companyOrdersSliceThunkApi';
import {
  setFetchedCompanySingleOrderAction,
  setOfferModalOpenedAction
} from '../../redux/company-orders-slice/companyOrdersSlice';
import accountTypes, { companyTypes } from '../../const-values/accountTypes';
import { getSinglePublicQuoteAction } from '../../redux/general-slice/generalSliceThunkApis';
import MakeOfferModal from '../../components/make-offer/MakeOfferModal';

const PublicSingleQuotePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user.user);

  const isLoadingPublicSingleQuote = useSelector(
    (store) => store.general.isLoadingPublicSingleQuote
  );
  const fetchedPublicSingleQuote = useSelector(
    (store) => store.general.fetchedPublicSingleQuote
  );
  const fetchPublicSingleQuoteCount = useSelector(
    (store) => store.general.fetchPublicSingleQuoteCount
  );

  const offerModalOpened = useSelector(
    (store) => store.companyOrders.offerModalOpened
  );
  const isSubmittingOffer = useSelector(
    (store) => store.companyOrders.isSubmittingOffer
  );

  const params = useParams();
  useEffect(() => {
    dispatch(
      getSinglePublicQuoteAction({
        filterObj: {
          order_id: params?.order_id || ''
        }
      })
    );
  }, [i18n.language, fetchPublicSingleQuoteCount]);

  useEffect(() => {
    dispatch(setFetchedCompanySingleOrderAction(fetchedPublicSingleQuote));
  }, [fetchedPublicSingleQuote]);

  const sharedT = (key) => t(`ordersPage.${key}`);

  const renderOrderStatus = () => {
    const found = threeOrderStatusArr(t).find((obj) => {
      return obj?.value === fetchedPublicSingleQuote?.status;
    });
    if (found) {
      return (
        <div
          className={`order-status-tag ${
            !fetchedPublicSingleQuote?.status
              ? 'all'
              : `_${fetchedPublicSingleQuote.status}`
          }`}
        >
          {found?.name}
        </div>
      );
    }
    return null;
  };

  const renderSideCard = () => {
    return (
      <div className="side-card card-with-header">
        <div className="card-header">{sharedT('orderClientInfo')}</div>

        <div className="id-status">
          <div className="id-wrap">
            <p className="id-label">{sharedT('orderId')}</p>
            <p className="id-value">#{fetchedPublicSingleQuote.id}</p>
          </div>

          <div className="order-status">{renderOrderStatus()}</div>
        </div>

        {fetchedPublicSingleQuote?.client && (
          <div className="comp-info">
            <div className="name-location">
              <p className="comp-name">
                {fetchedPublicSingleQuote?.client?.name || '---'}
              </p>
              {fetchedPublicSingleQuote?.client?.country && (
                <p className="comp-location">
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.9999 12.3089C8.2249 12.3089 6.7749 10.8672 6.7749 9.08385C6.7749 7.30052 8.2249 5.86719 9.9999 5.86719C11.7749 5.86719 13.2249 7.30885 13.2249 9.09219C13.2249 10.8755 11.7749 12.3089 9.9999 12.3089ZM9.9999 7.11719C8.91657 7.11719 8.0249 8.00052 8.0249 9.09219C8.0249 10.1839 8.90824 11.0672 9.9999 11.0672C11.0916 11.0672 11.9749 10.1839 11.9749 9.09219C11.9749 8.00052 11.0832 7.11719 9.9999 7.11719Z"
                      fill="#246DB4"
                    />
                    <path
                      d="M9.9999 19.466C8.76657 19.466 7.5249 18.9993 6.55824 18.0744C4.0999 15.7077 1.38324 11.9327 2.40824 7.44102C3.33324 3.36602 6.89157 1.54102 9.9999 1.54102C9.9999 1.54102 9.9999 1.54102 10.0082 1.54102C13.1166 1.54102 16.6749 3.36602 17.5999 7.44935C18.6166 11.941 15.8999 15.7077 13.4416 18.0744C12.4749 18.9993 11.2332 19.466 9.9999 19.466ZM9.9999 2.79102C7.5749 2.79102 4.45824 4.08268 3.63324 7.71602C2.73324 11.641 5.1999 15.0243 7.43324 17.166C8.8749 18.5577 11.1332 18.5577 12.5749 17.166C14.7999 15.0243 17.2666 11.641 16.3832 7.71602C15.5499 4.08268 12.4249 2.79102 9.9999 2.79102Z"
                      fill="#58B030"
                    />
                  </svg>
                  {fetchedPublicSingleQuote?.client?.country?.name &&
                    `${fetchedPublicSingleQuote.client.country.name}`}
                  {fetchedPublicSingleQuote?.client?.city?.name &&
                    ` / ${fetchedPublicSingleQuote.client.city.name}`}
                </p>
              )}
            </div>
            <CustomImage
              className="comp-img"
              src={fetchedPublicSingleQuote?.company?.logo}
            />
          </div>
        )}
      </div>
    );
  };

  const renderOrderDetails = () => {
    return (
      <>
        <div className="card-with-header details-card">
          <div className="card-header">
            {sharedT('orderDetails')}

            {user?.companyType === companyTypes()?.supplier && (
              <span className="price-span">
                {fetchedPublicSingleQuote?.totalPrice}

                <span className="curr-span">{t('currency.sar')}</span>
              </span>
            )}
          </div>
          <div className="loc-user-type">
            <div className="label-value-row">
              <p className="label-p">{sharedT('locationInfo')}</p>
              <div className="label-v">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 4
                  }}
                  className="address"
                >
                  <svg
                    style={{
                      marginBottom: -2
                    }}
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.9999 12.3089C8.2249 12.3089 6.7749 10.8672 6.7749 9.08385C6.7749 7.30052 8.2249 5.86719 9.9999 5.86719C11.7749 5.86719 13.2249 7.30885 13.2249 9.09219C13.2249 10.8755 11.7749 12.3089 9.9999 12.3089ZM9.9999 7.11719C8.91657 7.11719 8.0249 8.00052 8.0249 9.09219C8.0249 10.1839 8.90824 11.0672 9.9999 11.0672C11.0916 11.0672 11.9749 10.1839 11.9749 9.09219C11.9749 8.00052 11.0832 7.11719 9.9999 7.11719Z"
                      fill="#246DB4"
                    />
                    <path
                      d="M9.9999 19.466C8.76657 19.466 7.5249 18.9993 6.55824 18.0744C4.0999 15.7077 1.38324 11.9327 2.40824 7.44102C3.33324 3.36602 6.89157 1.54102 9.9999 1.54102C9.9999 1.54102 9.9999 1.54102 10.0082 1.54102C13.1166 1.54102 16.6749 3.36602 17.5999 7.44935C18.6166 11.941 15.8999 15.7077 13.4416 18.0744C12.4749 18.9993 11.2332 19.466 9.9999 19.466ZM9.9999 2.79102C7.5749 2.79102 4.45824 4.08268 3.63324 7.71602C2.73324 11.641 5.1999 15.0243 7.43324 17.166C8.8749 18.5577 11.1332 18.5577 12.5749 17.166C14.7999 15.0243 17.2666 11.641 16.3832 7.71602C15.5499 4.08268 12.4249 2.79102 9.9999 2.79102Z"
                      fill="#58B030"
                    />
                  </svg>

                  {fetchedPublicSingleQuote?.country?.name &&
                    `${fetchedPublicSingleQuote.country.name}`}
                  {fetchedPublicSingleQuote?.city?.name &&
                    ` / ${fetchedPublicSingleQuote.city.name}`}
                </div>
              </div>
            </div>

            <div className="label-value-row">
              <div className="label-p">{sharedT('userType')}</div>
              <div className="label-v">{sharedT('user')}</div>
            </div>
          </div>

          <div className="label-value-row">
            <p className="label-p">{sharedT('subject')}</p>
            <p className="label-v">{fetchedPublicSingleQuote?.subject}</p>
          </div>

          {fetchedPublicSingleQuote?.description && (
            <div className="label-value-row">
              <p className="label-p">{sharedT('requirements')}</p>
              <p
                style={{
                  lineHeight: 1.6
                }}
                className="label-v"
              >
                {fetchedPublicSingleQuote?.description}
              </p>
            </div>
          )}
        </div>

        <div className="card-with-header products-card">
          <div className="card-header">{sharedT('productQuantity')}</div>

          {fetchedPublicSingleQuote?.products?.length > 0 ? (
            fetchedPublicSingleQuote.products.map((p) => (
              <div key={p.id} className="p-row">
                <div className="label-value-row">
                  <div className="label-p">{sharedT('materialName')}</div>
                  <div className="label-v">{p?.productName || '---'}</div>
                </div>
                <div className="p-data">
                  <div className="label-value-row">
                    <div className="label-p">{sharedT('qty')}</div>
                    <div className="label-v">{p?.quantity || '---'}</div>
                  </div>
                  <div className="label-value-row">
                    <div className="label-p">{sharedT('unitType')}</div>
                    <div className="label-v">{p?.unit?.name || '---'}</div>
                  </div>
                  {!p?.option && (
                    <div className="label-value-row">
                      <div className="label-p">{sharedT('option')}</div>
                      <div className="label-v">{p?.option?.name || '---'}</div>
                    </div>
                  )}
                  <div className="label-value-row">
                    <div className="label-p">{sharedT('price')}</div>
                    <div className="label-v">
                      {`${p?.totalPrice}` || '---'}{' '}
                      <span
                        style={{
                          color: '#668199',
                          fontSize: 12
                        }}
                      >
                        {t('currency.sar')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <h2>No proudcts !!!</h2>
          )}
        </div>

        <div className="card-with-header products-card">
          <div className="card-header">{sharedT('attatcehments')}</div>

          {fetchedPublicSingleQuote?.files?.length > 0 ? (
            <div className="files-list">
              {fetchedPublicSingleQuote.files.map((file) => (
                <a
                  key={file.id}
                  download={true}
                  href={file?.filename}
                  target="_blank"
                  rel="noreferrer"
                >
                  <CloudDownloadOutlined />
                  {sharedT('download')}
                </a>
              ))}
            </div>
          ) : (
            <h2>{sharedT('noAttachs')}</h2>
          )}
        </div>
      </>
    );
  };

  const renderPageLayout = () => {
    if (isLoadingPublicSingleQuote) {
      return (
        <div
          style={{
            height: 332,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <LoadingOutlined />
        </div>
      );
    } else if (!fetchedPublicSingleQuote) {
      return (
        <div
          style={{
            height: 300,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img
            style={{
              width: '100%',
              maxWidth: '300px'
            }}
            src={imgsSrcs?.emptyResultsImg}
            alt="empty"
          />
        </div>
      );
    } else if (fetchedPublicSingleQuote) {
      return (
        <div className="page-layout">
          {renderSideCard()}

          <div className="page-content">{renderOrderDetails()}</div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="company-single-order-page">
      <CustomBreadcrumb
        arr={[
          {
            title: t('breadcrumbs.home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: t('breadcrumbs.userOrders'),
            isLink: true,
            to: merchentRouterLinks?.companyOrdersRoute
          },
          {
            title: fetchedPublicSingleQuote?.id
              ? `#${fetchedPublicSingleQuote.id}`
              : t('breadcrumbs.orderDetails'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <div className="title-offer-btn-wrap">
          <p className="page-title">
            {t('ordersPage.pageTitle')} / #{fetchedPublicSingleQuote?.id}
          </p>

          {user &&
            user?.accountType === accountTypes()?.company &&
            user?.companyType !== companyTypes()?.supplier && (
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(setOfferModalOpenedAction(true));
                }}
                className="offer-btn"
              >
                {sharedT('sendOffer')}
              </button>
            )}
        </div>

        {renderPageLayout()}
      </div>

      <MakeOfferModal
        productsArr={fetchedPublicSingleQuote?.products}
        formAction={makeOfferAction}
        modalOpened={offerModalOpened}
        setModalOpened={(v) => dispatch(setOfferModalOpenedAction(v))}
        isSubmitting={isSubmittingOffer}
      />
    </div>
  );
};

export default PublicSingleQuotePage;
