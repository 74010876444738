/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomBreadcrumb from '../../common/bread-crumb/Breadcrumb';
import CustomImage from '../../common/custom-image/CustomImage';
import generalRouterLinks from '../../components/app/general-routes/gerneralRouterLinks';
import routerLinks from '../../components/app/routerLinks';
import { companyTypes } from '../../const-values/accountTypes';
import {
  getRateCompanyAction,
  getSingleCompanyAction
} from '../../redux/companies-slice/companiesSliceThunkApis';
import checkRes from '../../utils/checkRes';
import CompanyInfoCard from './CompanyInfoCard';
import SubServicesModal from './SubServicesModal';
import './SingleCompanyPage.scss';

const SingleCompanyPage = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const navigate = useNavigate();
  const [selectedService, setSelectedService] = useState(null);
  const [subServicesModalOpened, setSubServicesModalOpened] = useState(false);
  const isLoadingSingleCompany = useSelector(
    (store) => store.companies.isLoadingSingleCompany
  );
  const user = useSelector((store) => store.user.user);

  const fetchCompanyRateCount = useSelector(
    (store) => store.companies.fetchCompanyRateCount
  );
  const fetchSingleCompanyCount = useSelector(
    (store) => store.companies.fetchSingleCompanyCount
  );
  const fetchedSingleCompany = useSelector(
    (store) => store.companies.fetchedSingleCompany
  );

  useEffect(() => {
    async function getSingleComp() {
      try {
        const action = await dispatch(
          getSingleCompanyAction({
            filterObj: { ...values }
          })
        );
        const res = action?.payload?.res;
        if (!checkRes(res)) navigate(routerLinks?.homePage);
      } catch (error) {}
    }

    getSingleComp();
  }, [search, fetchSingleCompanyCount, i18n.language]);

  useEffect(() => {
    if (user?.token) {
      async function getRates() {
        if (fetchedSingleCompany?.id)
          try {
            const action = await dispatch(
              getRateCompanyAction({
                filterObj: {
                  id: fetchedSingleCompany?.id
                }
              })
            );
            const res = action?.payload?.res;
            if (!checkRes(res)) navigate(routerLinks?.homePage);
          } catch (error) {}
      }

      getRates();
    }
  }, [search, fetchCompanyRateCount, fetchedSingleCompany, i18n.language]);

  const ServiceCard = ({ serv }) => {
    // if (fetchedSingleCompany?.companyType === companyTypes()?.supplier) {
    //   return (
    //     <Link
    //       to={generalRouterLinks?.supplierProductsRoute(
    //         fetchedSingleCompany.id,
    //         serv?.id
    //       )}
    //       className="service-wrapper"
    //     >
    //       <CustomImage className="service-img" src={serv?.logo} />
    //       <p className="serv-name">{serv?.name}</p>
    //     </Link>
    //   );
    // }
    return (
      <div
        onClick={() => {
          setSelectedService(serv);
          setSubServicesModalOpened(true);
        }}
        style={{
          cursor: 'pointer'
        }}
        className="service-wrapper"
      >
        <CustomImage className="service-img" src={serv?.logo} />
        <p className="serv-name">{serv?.name}</p>
      </div>
    );
  };
  const renderServicesList = useMemo(() => {
    return () => {
      if (
        !fetchedSingleCompany?.companyData?.services ||
        fetchedSingleCompany?.companyData?.services?.length === 0
      ) {
        return i18n.language === 'ar'
          ? 'لا توجد خدمات متاحه!!'
          : 'No services found!!';
      } else if (fetchedSingleCompany?.companyData?.services?.length > 0)
        return fetchedSingleCompany?.companyData?.services?.map((item) => (
          <ServiceCard key={item?.id} serv={item} />
        ));
      return null;
    };
  }, [fetchedSingleCompany]);

  //
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  if (isLoadingSingleCompany) {
    return (
      <div className="single-company-page">
        <div
          className="custom-container"
          style={{
            paddingTop: 92,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      </div>
    );
  }
  return (
    <div className="single-company-page">
      <CustomBreadcrumb
        arr={[
          {
            title: t('breadcrumbs.home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: t('breadcrumbs.companies'),
            isLink: fetchedSingleCompany?.companyType ? true : false,
            to:
              fetchedSingleCompany?.companyType === companyTypes()?.supplier
                ? generalRouterLinks?.suppliersRoute
                : generalRouterLinks?.contractorsRoute
          },
          {
            title: fetchedSingleCompany?.companyData?.companyName || '',
            isLink: false
          }
        ]}
      />
      <div className="custom-container">
        {!fetchedSingleCompany ? (
          <h1>{t('noData')}</h1>
        ) : (
          <div className="page-content">
            {fetchedSingleCompany?.companyData?.companyName && (
              <div className="page-title">
                {fetchedSingleCompany.companyData.companyName}
              </div>
            )}

            <CompanyInfoCard
              id={fetchedSingleCompany.id}
              image={fetchedSingleCompany.image}
              name={fetchedSingleCompany.companyData?.companyName}
              compTypeName={fetchedSingleCompany.companyData?.companyType?.name}
              rate={fetchedSingleCompany.rate}
              is_fav={fetchedSingleCompany.is_fav}
              cardCompType={fetchedSingleCompany.companyType}
              desc={fetchedSingleCompany.companyData?.overView}
            />

            <section className="about-map-section">
              {fetchedSingleCompany?.companyData?.overView && (
                <div className="about-wrapper">
                  <div className="sec-header">
                    <p className="main-p">{t('singleCompPage.aboutComp')}</p>
                    <p className="sub-p">{t('singleCompPage.someInfo')}</p>
                  </div>

                  <p className="desc-text">
                    {fetchedSingleCompany?.companyData?.overView}
                  </p>
                </div>
              )}

              {(fetchedSingleCompany.companyData?.address ||
                (fetchedSingleCompany.companyData?.lat &&
                  fetchSingleCompanyCount.companyData?.lng)) && (
                <div className="map-wrapper">
                  <div className="sec-header">
                    <p className="main-p">{t('singleCompPage.address')}</p>
                    {fetchedSingleCompany.companyData?.address && (
                      <p className="sub-p">
                        {fetchedSingleCompany.companyData?.address}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </section>

            {fetchedSingleCompany?.companyData?.services?.length > 0 && (
              <section className="comp-services-sec">
                <div className="sec-header">
                  <p className="main-p">{t('singleCompPage.ourServices')}</p>
                  <p className="sub-p">{t('singleCompPage.servInfo')}</p>
                </div>

                <div className="services-list">
                  {renderServicesList(
                    fetchedSingleCompany.companyData.services
                  )}
                </div>
              </section>
            )}
          </div>
        )}
      </div>

      <SubServicesModal
        modalOpened={subServicesModalOpened}
        setModalOpened={setSubServicesModalOpened}
        modalTitle={selectedService?.name}
        setSelectedService={setSelectedService}
        fetchedSingleCompany={fetchedSingleCompany}
        subServArr={selectedService?.subServices}
      />
    </div>
  );
};

export default SingleCompanyPage;
