import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  checkSignupActiveCodeApi,
  resendCodeApi
} from '../../apis/auth/checkActiveCodeApi';
import userInfoApi from '../../apis/auth/myInfoApi';
import signinApi from '../../apis/auth/signinApi';
import signoutApi from '../../apis/auth/signoutApi';
import signupApi from '../../apis/auth/signupApi';
import customApiRequest from '../../helpers/customApiRequest';
// import checkRes from '../../utils/checkRes';
// import successNotification from '../../utils/successNotification';

export const userInfoAction = createAsyncThunk(
  'user/userInfoAction',
  async (v, thunkAPI) => {
    const x = customApiRequest({
      apiCall: await userInfoApi(),
      thunkAPI
    });
    return x;
  }
);
export const signinAction = createAsyncThunk(
  'user/signinAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await signinApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
// signup action
export const signupAction = createAsyncThunk(
  'user/signupAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await signupApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
// check signup active code action
export const checkSignupActiveCodeAction = createAsyncThunk(
  'user/checkSignupActiveCodeAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await checkSignupActiveCodeApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

// resend signup code action
export const resendSignupCodeAction = createAsyncThunk(
  'user/resendSignupCodeAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await resendCodeApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

// signout action
export const userSignoutAction = createAsyncThunk(
  'user/userSignoutAction',
  async (v, thunkAPI) => {
    return customApiRequest({
      apiCall: await signoutApi(),
      thunkAPI
    });
  }
);
