/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Logo from '../../common/logo/Logo';
import './SignupPage.scss';
import MerchentSignupForm from './MerchentSignupForm';

const MerchentSignupPage = () => {
  return (
    <div className="shared-custom-page signup-page">
      <div className="custom-container">
        <section className="signup-form-section">
          <Logo className="logo" />

          <MerchentSignupForm />
        </section>
      </div>
    </div>
  );
};

export default MerchentSignupPage;
