import { createAsyncThunk } from '@reduxjs/toolkit';
import userFavApi from '../../apis/auth/userFavApi';
import customApiRequest from '../../helpers/customApiRequest';

// favs
export const getUserFavs = createAsyncThunk(
  'userFav/getUserFavs',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await userFavApi(filterObj),
      thunkAPI
    });
  }
);
