import { createSlice } from '@reduxjs/toolkit';
import {
  checkSignupActiveCodeAction,
  resendSignupCodeAction,
  signinAction,
  signupAction,
  userInfoAction,
  userSignoutAction
} from './userSliceThunkApis';

const getUserFromSession = () => {
  if (sessionStorage.getItem('currentUser')) {
    return JSON.parse(sessionStorage.getItem('currentUser'));
  } else if (localStorage.getItem('currentUser')) {
    return JSON.parse(localStorage.getItem('currentUser'));
  }
  return null;
};

const initialState = {
  notAuthedModalOpened: false,
  isLoadingUser: false,
  isSubmitting: false,
  loggedIn:
    localStorage.getItem('currentUser') || sessionStorage.getItem('currentUser')
      ? true
      : false,
  user: getUserFromSession()
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsLoadingUser: (stateSlice, action) => {
      stateSlice.isLoadingUser = action.payload;
    },
    setUserToStateAction: (sliceState, action) => {
      sliceState.user = action.payload;
      sliceState.loggedIn = true;
      sessionStorage.setItem('currentUser', JSON.stringify(action.payload));
    },
    setCurrentUserAction: (sliceState, action) => {
      sliceState.user = action.payload;
      sliceState.loggedIn = true;
      localStorage.setItem('currentUser', JSON.stringify(action.payload));
    },
    removeCurrentUserAction: (sliceState, action) => {
      sliceState.user = null;
      sliceState.loggedIn = false;
      sessionStorage.removeItem('currentUser');
      localStorage.removeItem('currentUser');
    },
    setNotAuthedModalOpened: (sliceState, action) => {
      sliceState.notAuthedModalOpened = action.payload;
    }
  },
  extraReducers: {
    // signin
    [signinAction.pending]: (state, action) => {
      state.isSubmitting = true;
    },
    [signinAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
    },
    [signinAction.rejected]: (state, action) => {
      state.isSubmitting = false;
    },
    //
    // signup action
    [signupAction.pending]: (state, action) => {
      state.isSubmitting = true;
    },
    [signupAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
    },
    [signupAction.rejected]: (state, action) => {
      state.isSubmitting = false;
    },
    // check signup active code
    [checkSignupActiveCodeAction.pending]: (state, action) => {
      state.isSubmitting = true;
    },
    [checkSignupActiveCodeAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
    },
    [checkSignupActiveCodeAction.rejected]: (state, action) => {
      state.isSubmitting = false;
    },
    //
    // resend signup active code
    [resendSignupCodeAction.pending]: (state, action) => {
      state.isSubmitting = true;
    },
    [resendSignupCodeAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
    },
    [resendSignupCodeAction.rejected]: (state, action) => {
      state.isSubmitting = false;
    },
    //
    // user info
    [userInfoAction.pending]: (state, action) => {
      state.isLoadingUser = true;
    },
    [userInfoAction.fulfilled]: (state, action) => {
      state.isLoadingUser = false;
    },
    [userInfoAction.rejected]: (state, action) => {
      state.isLoadingUser = false;
    },
    // user signout
    [userSignoutAction.pending]: (state, action) => {
      state.isLoadingUser = true;
    },
    [userSignoutAction.fulfilled]: (state, action) => {
      state.isLoadingUser = false;
    },
    [userSignoutAction.rejected]: (state, action) => {
      state.isLoadingUser = false;
    }
  }
  //
});

const userReducer = userSlice.reducer;

export const {
  setIsLoadingUser,
  setUserToStateAction,
  setCurrentUserAction,
  removeCurrentUserAction,
  setNotAuthedModalOpened
} = userSlice.actions;

export default userReducer;
