import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './TermsModal.scss';
import { LoadingOutlined } from '@ant-design/icons';
const TermsModal = ({ modalOpened, setModalOpened }) => {
  const { t } = useTranslation();
  const isLoadingAboutData = useSelector(
    (store) => store.general.isLoadingAboutData
  );
  const fetchedAboutData = useSelector(
    (store) => store.general.fetchedAboutData
  );

  const renderData = () => {
    if (isLoadingAboutData) {
      return (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 332
            }}
          >
            <LoadingOutlined style={{ fontSize: 20 }} />
          </div>
        </div>
      );
    } else if (!fetchedAboutData?.terms) {
      return <p>{t('noData')}</p>;
    } else if (fetchedAboutData.terms) {
      return (
        <p
          style={{
            whiteSpace: 'pre-line'
          }}
        >
          {fetchedAboutData.terms}
        </p>
      );
    }
    return null;
  };

  return (
    <Modal
      className="shared-custom-modal signup-terms-modal"
      width="96%"
      style={{ maxWidth: '842px' }}
      title={t('signup_form.terms')}
      open={modalOpened}
      onOk={() => {
        setModalOpened(false);
      }}
      onCancel={() => {
        setModalOpened(false);
      }}
      footer={false}
    >
      {renderData()}
    </Modal>
  );
};

export default TermsModal;
