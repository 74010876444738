/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import updateProfileApi from '../../apis/auth/updateProfileApi';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import CountryCityOptions from '../../common/country-city-options/CountryCityOptions';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import ServicesSelectOption from '../../common/services-select-option/ServicesSelectOption';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import { getServicesAction } from '../../redux/general-slice/generalSliceThunkApis';
import {
  setCurrentUserAction,
  setUserToStateAction
} from '../../redux/user-slice/userSlice';
import checkRes from '../../utils/checkRes';
import './CompanyProfileSettingsForm.scss';

const CompanyProfileSettingsForm = ({ setSettingsFormOpened }) => {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user.user);
  const isLoadingServices = useSelector(
    (store) => store.general.isLoadingServices
  );
  const services = useSelector((store) => store.general.fetchedServicesData);
  const servicesPagination = useSelector(
    (store) => store.general.servicesPagination
  );
  const handleClickMore = () => {
    if (servicesPagination.current_page < servicesPagination.total_pages)
      dispatch(
        getServicesAction({
          filterObj: {
            page: servicesPagination.current_page + 1
          }
        })
      );
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { i18n, t } = useTranslation();
  const countries = useSelector((store) => store.general.fetchedCountriesData);
  const generalLabelStr = (v) => t(`signup_form.${v}.label`);
  const generalrequiredErrStr = (v) => t(`signup_form.${v}.errors.required`);
  const generalTypeErrorStr = (v) => t(`signup_form.${v}.errors.type_error`);
  const generalMinErrorStr = (v, min) =>
    t(`signup_form.${v}.errors.min`, {
      min
    });
  const schema = Yup.object().shape({
    managerName: Yup.string().required(generalrequiredErrStr('name')),
    phone: Yup.string()
      .required(generalrequiredErrStr('phone'))
      // .matches(/^[0-9]+$/, generalTypeErrorStr('phone'))
      .min(10, generalMinErrorStr('phone', 10)),
    email: Yup.string()
      .required(generalrequiredErrStr('email'))
      .email(generalTypeErrorStr('email'))
  });
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      country_id: '',
      city_id: '',
      commercialRecord: '',
      taxNumber: ''
    }
  });

  const [allSubServicesArr, setAllSubServicesArr] = useState([]);
  useEffect(() => {
    if (watch('services')?.length >= 0 && services?.length > 0) {
      let foundServObj = null;
      const arr = [];
      for (let v of watch('services')) {
        foundServObj = services.find((obj) => obj?.id == v);
        if (
          foundServObj &&
          typeof foundServObj === 'object' &&
          foundServObj?.subServices?.length >= 0
        ) {
          arr.push(...foundServObj.subServices);
        }
      }
      setAllSubServicesArr(arr);
      // setValue('subServices', []);
    }
  }, [watch('services')]);

  // handle initial values
  useEffect(() => {
    if (user) {
      setValue('managerName', user?.name || '');
      setValue('companyName', user?.companyData?.companyName || '');
      setValue('email', user?.email || '');
      setValue('phone', user?.phone || '');
      setValue('commercialRecord', user?.commercial_record || '');
      setValue('taxNumber', user?.tax_record || '');
      setValue('country_id', user?.country?.id ? String(user.country.id) : '');
      setValue('city_id', user?.city?.id ? String(user.city.id) : '');
      setValue(
        'services',
        user?.companyData?.services?.length > 0
          ? user.companyData.services.map((serv) => ({
              ...serv,
              label: serv?.name,
              value: serv?.id ? String(serv.id) : ''
            }))
          : []
      );
      if (user?.companyData?.services?.length > 0) {
        const servArr = user.companyData.services;
        let subServArr = [];
        for (let obj of servArr) {
          if (obj?.subServices?.length > 0) {
            subServArr.push(...obj.subServices);
          }
        }
        if (subServArr?.length > 0) {
          setValue(
            'subServices',
            subServArr.map((s) => ({
              ...s,
              label: s?.name,
              value: s?.id ? String(s.id) : ''
            }))
          );
        }
      }
    }
  }, [user]);

  const customApiRequest = useCustomApiRequest();
  const onSubmit = (data) => {
    // signMeUpWithEmailPassword({ ...data, accountType: 3 });
    setIsSubmitting(true);
    // const countryCode = phoneStrArr[0];
    // let phone = '';
    // if (phoneStrArr?.length === 2) phone = phoneStrArr[1];
    // else if (phoneStrArr?.length > 2) {
    //   let array = phoneStrArr.filter((item, index) => index > 0); // ['50', '123', '4444']
    //   phone = array.join('');
    // }

    const formData = new FormData();
    if (data.managerName) formData.append('name', data.name);
    if (data.phone)
      // formData.append('phone', phone ? `${countryCode}${phone}` : data.phone);
      formData.append('phone', data.phone);
    if (data.email) formData.append('email', data.email);
    if (data.commercialRecord)
      formData.append('commercial_record', data.commercialRecord);
    if (data.taxNumber) formData.append('tax_record', data.taxNumber);
    if (data?.country_id) formData.append('country_id', data.country_id);
    if (data?.city_id) formData.append('city_id', data.city_id);
    if (data.managerName) formData.append('name', data.managerName);
    if (data.companyName) formData.append('companyName', data.companyName);
    if (data?.subServices?.length > 0) {
      for (let item of data.subServices)
        formData.append(
          'services[]',
          item && typeof item === 'object' ? item.value : item
        );
    }

    customApiRequest(
      updateProfileApi(formData),
      (res) => {
        setIsSubmitting(false);
        if (checkRes(res)) {
          setSettingsFormOpened(false);
          if (user?.remember) {
            dispatch(
              setCurrentUserAction({
                ...res?.data.data,
                token: user?.token || '',
                accountType: res.data.data.userType || user?.accountType,
                companyType: res.data.data.companyType || user?.companyType,
                activated: user?.activated,
                remember: false
              })
            );
          } else {
            dispatch(
              setUserToStateAction({
                ...res?.data.data,
                token: user?.token || '',
                accountType: res.data.data.userType || user?.accountType,
                companyType: res.data.data.companyType || user?.companyType,
                activated: user?.activated,
                remember: false
              })
            );
          }
        }
      },
      (error) => {
        setIsSubmitting(false);
      }
    );
  };

  console.log('watch: ', watch());

  const [form] = Form.useForm();
  return (
    <>
      <Form
        className="comp-profile-settings-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="managerName"
              type="text"
              errorMsg={errors?.managerName?.message}
              validateStatus={errors?.managerName ? 'error' : ''}
              control={control}
            />
            <p className="custom-label">{generalLabelStr('managerName')}</p>
          </div>
          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="companyName"
              type="text"
              errorMsg={errors?.companyName?.message}
              validateStatus={errors?.companyName ? 'error' : ''}
              control={control}
            />
            <p className="custom-label">{generalLabelStr('companyName')}</p>
          </div>

          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="email"
              type="text"
              errorMsg={errors?.email?.message}
              validateStatus={errors?.email ? 'error' : ''}
              control={control}
            />
            <p className="custom-label">{generalLabelStr('email')}</p>
          </div>

          <div
            className={`custom-text-field-wrapper with-prefix ${i18n.dir()}`}
          >
            <AntdTextField
              className="custom-text-field"
              name="phone"
              type="text"
              errorMsg={errors?.phone?.message}
              validateStatus={errors?.phone ? 'error' : ''}
              control={control}
            />
            <p className="custom-label">
              {generalLabelStr('phone')}{' '}
              <span
                style={{
                  direction: 'ltr'
                }}
              >
                ( 558615134 )
              </span>
            </p>
            <span className="prefix-span">
              <span
                style={{
                  fontSize: 20,
                  borderRadius: 2
                }}
                className={`fi fi-sa`}
              />{' '}
              {i18n.language === 'ar' ? '(966+)' : '(+966)'}
            </span>
          </div>

          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="commercialRecord"
              type="text"
              errorMsg={errors?.commercialRecord?.message}
              validateStatus={errors?.commercialRecord ? 'error' : ''}
              control={control}
            />
            <p className="custom-label">
              {generalLabelStr('commercialRecord')}
            </p>
          </div>

          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="taxNumber"
              type="text"
              errorMsg={errors?.taxNumber?.message}
              validateStatus={errors?.taxNumber ? 'error' : ''}
              control={control}
            />
            <p className="custom-label">{generalLabelStr('taxNumber')}</p>
          </div>

          <CountryCityOptions
            countries={countries}
            countryName="country_id"
            cityName="city_id"
            countryLabel={generalLabelStr('country_id')}
            cityLabel={generalLabelStr('city_id')}
            parentClassName="comp-profile-settings-form"
            //
            watch={watch}
            setValue={setValue}
            control={control}
            errors={errors}
          />

          <ServicesSelectOption
            control={control}
            setValue={setValue}
            errors={errors}
            name="services"
            label={generalLabelStr('services')}
            mode="multiple"
            selectOptionArr={services}
            selectOptionPagination={servicesPagination}
            isLoadingSelectOption={isLoadingServices}
            handleClickMore={handleClickMore}
            parentClassName="comp-profile-settings-form"
          />

          <ServicesSelectOption
            control={control}
            setValue={setValue}
            errors={errors}
            name="subServices"
            label={generalLabelStr('subServices')}
            mode="multiple"
            parentClassName="comp-profile-settings-form"
            selectOptionArr={allSubServicesArr}
          />

          <div className="form-action-btns">
            <Button
              className="submit-btn"
              htmlType="submit"
              type="primary"
              // icon={<LoginOutlined />}
              loading={isSubmitting}
            >
              {t('profileForm.saveChanges')}
            </Button>

            <button
              type="button"
              onClick={() => {
                reset({
                  name: user?.name || '',
                  phone: user?.phone || '',
                  email: user?.email || '',
                  country_id: user?.country?.id ? String(user.country.id) : '',
                  city_id: user?.city?.id ? String(user.city.id) : ''
                });
              }}
              className="cancel-btn"
            >
              {t('profileForm.cancel')}
            </button>
          </div>
        </div>
      </Form>

      {isSubmitting && <LoadingModal />}
    </>
  );
};

export default CompanyProfileSettingsForm;
