/* eslint-disable eqeqeq */
import accountTypes from './accountTypes';
import userRouterLinks from '../components/app/user-routes/uesRouterLinks';
import merchentRouterLinks from '../components/app/merchent-routes/merchentRouterLinks';

// isOrder
// 1=>new
// 3=>canceld by user
// 4=>accepted
// 5=>rejected
// 6=>completed

const baseLabelTransString = (user, t, key, strType = 'filter') => {
  if (user?.accountType == accountTypes()?.user) {
    if (strType === 'filter') return t(`userOrderStatus.${key}`);
    else if (strType === 'tag') return t(`userOrderStatusTags.${key}`);
  } else if (
    user?.accountType == accountTypes()?.company ||
    user?.accountType == accountTypes()?.employee
  )
    if (strType === 'filter') return t(`companyOrderStatus.${key}`);
    else if (strType === 'tag') return t(`companyOrderStatusTags.${key}`);
};

const getUrl = (user, urlOptions) => {
  if (user?.accountType == accountTypes()?.user) {
    return `${userRouterLinks?.userOrdersRoute}?type=${
      urlOptions?.status || ''
    }&page=1`;
  } else if (
    user?.accountType == accountTypes()?.company ||
    user?.accountType == accountTypes()?.employee
  )
    return `${merchentRouterLinks?.companyOrdersRoute}?type=${
      urlOptions?.status || ''
    }&page=1`;
};

export const orderStatusValues = {
  all: '',
  new: 1,
  discussion: 2,
  history: 3,
  public: 4
};
export const userOrderStatusArr = (user, t, arrOptions) => {
  return [
    {
      name: baseLabelTransString(user, t, 'all', arrOptions?.strType),
      status: orderStatusValues?.all,
      objKey: 'all',
      id: 'all_orders',
      url: getUrl(user, {
        status: orderStatusValues?.all,
        storeId: arrOptions?.storeId || ''
      })
    },
    {
      name: baseLabelTransString(user, t, 'new', arrOptions?.strType),
      objKey: 'new',
      status: orderStatusValues?.new,
      id: orderStatusValues?.new,
      url: getUrl(user, {
        status: orderStatusValues?.new,
        storeId: arrOptions?.storeId || ''
      })
    },
    {
      name: baseLabelTransString(user, t, 'discussion', arrOptions?.strType),
      objKey: 'discussionKey',
      status: orderStatusValues?.discussion,
      id: orderStatusValues?.discussion,
      url: getUrl(user, {
        status: orderStatusValues?.discussion,
        storeId: arrOptions?.storeId || ''
      })
    },
    {
      name: baseLabelTransString(user, t, 'history', arrOptions?.strType),
      objKey: 'historyKey',
      status: orderStatusValues?.history,
      id: orderStatusValues?.history,
      url: getUrl(user, {
        status: orderStatusValues?.history,
        storeId: arrOptions?.storeId || ''
      })
    }
  ];
};

export const companyOrderStatusArr = (user, t, arrOptions) => {
  return [
    ...userOrderStatusArr(user, t, arrOptions),
    {
      name: baseLabelTransString(user, t, 'public', arrOptions?.strType),
      objKey: 'publicKey',
      status: orderStatusValues?.public,
      id: orderStatusValues?.public,
      url: getUrl(user, {
        status: orderStatusValues?.public,
        storeId: arrOptions?.storeId || ''
      })
    }
  ];
};

//

export const orderTypesValues = {
  public: 1,
  contractor: 2,
  supplier: 3
};

export const threeOrderStatusValues = {
  new: 1,
  accepted: 2,
  rejected: 3,
  canceledByUser: 5,
  completed: 6
};
export const threeOrderStatusArr = (t) => {
  const trans = (key) => t(`singleOrderPage.${key}`);
  return [
    {
      id: 1,
      name: trans('new'),
      value: threeOrderStatusValues.new
    },
    {
      id: 2,
      name: trans('accepted'),
      value: threeOrderStatusValues.accepted
    },
    {
      id: 3,
      name: trans('rejected'),
      value: threeOrderStatusValues.rejected
    },
    {
      id: 4,
      name: trans('canceledByUser'),
      value: threeOrderStatusValues.canceledByUser
    },
    {
      id: 5,
      name: trans('completed'),
      value: threeOrderStatusValues.completed
    }
  ];
};

export const underNegotiationValues = {
  yes: 1,
  no: 2,
  canNotNegotiate: 3
};

export const neogtiationTypes = {
  user: 1,
  company: 2
};

export const negotiationStatus = {
  inReview: 1,
  accepted: 2,
  rejected: 3
};

export const isAbleNegotiateValues = {
  yes: 1,
  no: 2
};

export const answerNegotiateStatus = {
  confirm: 1,
  anotherPrice: 2,
  reject: 3 // reject for user but is not negotiable for supplier
};

// offers
export const userOfferStatus = {
  newOffer: 1,
  accepted: 2,
  rejected: 3
};

export const contractorOfferStatus = {
  newOffer: 1,
  accepted: 2,
  notNegotiable: 3
};
