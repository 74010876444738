import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CustomImage from '../../common/custom-image/CustomImage';
import MapIcon from '../../common/icons/MapIcon';
import RatePreview from '../../common/rate-preview/RatePreview';
import generalRouterLinks from '../app/general-routes/gerneralRouterLinks';
import { LoadingOutlined } from '@ant-design/icons';
import './TopRatedCard.scss';
import { addCompanyToFavAction } from '../../redux/companies-slice/companiesSliceThunkApis';
import HeartIconFilled from '../../common/icons/HeartIconFIlled';
import HeartIcon from '../../common/icons/HeartIcon';
import checkRes from '../../utils/checkRes';
import { setFavCountAction } from '../../redux/user-fav-slice/userFavSlice';

const TopRatedCard = ({ card, view, ...props }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const isSubmittingFav = useSelector(
    (store) => store.companies.isSubmittingFav
  );
  const fetchFavCount = useSelector((store) => store.userFav.fetchFavCount);

  const renderCompanyServices = (arr) => {
    if (arr?.length > 0) {
      return arr.map((obj) => (
        <div key={obj.id} className="serv-name">
          {obj?.name}
        </div>
      ));
    }
    return null;
  };
  // const renderCompanyServices = (arr) => {
  //   if (arr?.length > 0) {
  //     const array = arr.slice(0, 4);
  //     const str =
  //       i18n.dir() === 'ltr'
  //         ? array.map((item) => item?.name).join(', ')
  //         : array.map((item) => item?.name).join(', ');

  //     return str;
  //   }
  //   return null;
  // };

  const renderFavBtn = () => {
    if (isSubmittingFav) {
      return (
        <div className="submitting-fav-wrapper">
          <LoadingOutlined />
        </div>
      );
    }

    return (
      <button
        className="fav-btn"
        onClick={async (e) => {
          e.stopPropagation();
          e.preventDefault();
          const formData = new FormData();
          formData.append('company_id', card?.id);
          const action = await dispatch(
            addCompanyToFavAction({
              dataToBeSubmitted: formData
            })
          );
          console.log('action : ', action);
          if (checkRes(action.payload.res)) {
            dispatch(setFavCountAction(fetchFavCount + 1));
          }
        }}
      >
        {card?.is_fav ? (
          <HeartIconFilled color="#246db4" />
        ) : (
          <HeartIcon color="#98A6B3" />
        )}
      </button>
    );
  };

  return (
    <Link
      to={`${generalRouterLinks?.singleCompanyRoute}?company_id=${
        card?.id || ''
      }`}
      className={`top-rated-card ${i18n.dir()} ${
        view === 'grid' ? 'grid' : ''
      }`}
    >
      <div className="img-title-address-wrap">
        <CustomImage className="img-wrap" src={card?.image} alt={card?.name} />
        <div>
          <div className="title-sub-rate-wrap">
            <div className="title-sub-wrap">
              <div className="title">
                {card?.companyData?.companyType?.name}
              </div>
              <div className="sub">{card?.name}</div>
            </div>
            {props?.withFav ? (
              renderFavBtn()
            ) : (
              <div className="rate-wrap">
                <RatePreview value={card?.rate || 0} />
              </div>
            )}
          </div>
          {card?.companyData?.address && (
            <div className="address">
              <MapIcon />
              {card.companyData.address}
            </div>
          )}
        </div>
      </div>
      {card?.companyData?.services?.length > 0 && (
        <div className="desc">
          {renderCompanyServices(card?.companyData?.services)}
        </div>
      )}
    </Link>
  );
};

export default TopRatedCard;
