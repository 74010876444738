import React from 'react';
import { useTranslation } from 'react-i18next';
import MainAppFooter from '../../main-app-footer/MainAppFooter';
import MainAppBar from '../../main-app-bar/MainAppBar';
import { MainAppBarProvider } from '../../../contexts/main-app-bar-context/MainAppBarProvider';
import './DefaultAppLayout.scss';
import QuoteModal from '../../quote-modal/QuoteModal';
import { useLocation } from 'react-router-dom';
import routerLinks from '../routerLinks';
import NotAuthedModal from '../../../common/not-authed-modal/NotAuthedModal';

const DefaultAppLayout = ({ children }) => {
  const { i18n, t } = useTranslation();
  // const { Header, Content, Footer, Sider } = AntdLayout;

  const { pathname } = useLocation();

  if (
    pathname === routerLinks?.signinPage ||
    pathname === routerLinks?.signupPage ||
    pathname === routerLinks?.userSignupRoute ||
    pathname === routerLinks?.merchentSignupRoute ||
    pathname === routerLinks?.employeeSignupRoute ||
    pathname === routerLinks?.activateAccountRoute ||
    pathname === routerLinks?.forgetPasswordRoute ||
    pathname === routerLinks?.forgetPasswordActivateCodeRoute ||
    pathname === routerLinks?.forgetPasswordNewPasswordRoute ||
    pathname === routerLinks?.forgetPasswordChangedSuccessfullyRoute
  )
    return children;

  return (
    <div className={`app-layout app-${i18n.dir()}`}>
      <MainAppBarProvider>
        <MainAppBar />
      </MainAppBarProvider>

      <div className="layout-content">{children}</div>

      <MainAppFooter />
      {/* <ReactNotification className={i18n.dir()} /> */}

      <QuoteModal
        title={
          <div className="modal-h">
            <p className="main-header-t">{t('reqQuo')}</p>
            <p className="sub-header-t">{t('reqFromVendors')}</p>
          </div>
        }
      />

      <NotAuthedModal />
    </div>
  );
};

export default DefaultAppLayout;
