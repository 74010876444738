/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import EyeOnIcon from '../../common/icons/EyeOnIcon';
import EyeOffIcon from '../../common/icons/EyeOffIcon';
import './ProfileChagePasswordForm.scss';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import changePasswordApi from '../../apis/auth/changePasswordApi';
import checkRes from '../../utils/checkRes';
import LoadingModal from '../../common/loading-modal/LoadingModal';

const ProfileChangePasswordForm = ({ setPassFormOpened }) => {
  const { t, i18n } = useTranslation();
  const sharedT = (key) => t(`profileForm.${key}`);
  const schema = Yup.object().shape({
    oldPassword: Yup.string().required(sharedT('oldPassword.errors.required')),
    newPassword: Yup.string()
      .required(sharedT('newPassword.errors.required'))
      .min(
        8,
        t('profileForm.newPassword.errors.min', {
          min: 8
        })
      ),
    newPasswordConfirmation: Yup.string()
      .required(sharedT('newPasswordConfirmation.errors.required'))
      .oneOf(
        [Yup.ref('newPassword')],
        sharedT('newPasswordConfirmation.errors.type_error')
      )
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirmation: ''
    }
  });

  React.useEffect(() => {
    return () => {
      reset({ email: '' });
    };
  }, []);
  const [passwrodVisible, setPasswordVisible] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const customApiRequest = useCustomApiRequest();
  const onSubmit = (data) => {
    setIsSubmitting(true);
    customApiRequest(
      changePasswordApi(data),
      (res) => {
        setIsSubmitting(false);
        if (checkRes(res)) {
          reset({
            oldPassword: '',
            newPassword: '',
            newPasswordConfirmation: ''
          });
          setPassFormOpened(false);
        }
      },
      (error) => {
        setIsSubmitting(false);
      }
    );
  };

  const [form] = Form.useForm();

  return (
    <Form
      className="custom-shared-form user-change-pass-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-content">
        <div className="form-body">
          <div className="login-password-field-wrap">
            <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
              <AntdTextField
                className="custom-text-field"
                name="oldPassword"
                type={passwrodVisible ? 'text' : 'password'}
                errorMsg={errors?.oldPassword?.message}
                validateStatus={errors?.oldPassword ? 'error' : ''}
                control={control}
              />
              <p className="custom-label">{sharedT('oldPassword.label')}</p>
            </div>

            {watch('oldPassword') && (
              <button
                type="button"
                className="eye-icon-btn"
                style={{
                  left: i18n.dir() === 'rtl' ? '14px' : 'auto',
                  right: i18n.dir() === 'ltr' ? '14px' : 'auto'
                }}
                onClick={() => {
                  setPasswordVisible((prevState) => !prevState);
                }}
              >
                {passwrodVisible ? <EyeOnIcon /> : <EyeOffIcon />}
              </button>
            )}
          </div>

          <div className="login-password-field-wrap">
            <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
              <AntdTextField
                className="custom-text-field"
                name="newPassword"
                type={passwrodVisible ? 'text' : 'password'}
                errorMsg={errors?.newPassword?.message}
                validateStatus={errors?.newPassword ? 'error' : ''}
                control={control}
              />
              <p className="custom-label">{sharedT('newPassword.label')}</p>
            </div>

            {watch('newPassword') && (
              <button
                type="button"
                className="eye-icon-btn"
                style={{
                  left: i18n.dir() === 'rtl' ? '14px' : 'auto',
                  right: i18n.dir() === 'ltr' ? '14px' : 'auto'
                }}
                onClick={() => {
                  setPasswordVisible((prevState) => !prevState);
                }}
              >
                {passwrodVisible ? <EyeOnIcon /> : <EyeOffIcon />}
              </button>
            )}
          </div>
          <div className="login-password-field-wrap">
            <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
              <AntdTextField
                className="custom-text-field"
                name="newPasswordConfirmation"
                type={passwrodVisible ? 'text' : 'password'}
                errorMsg={errors?.newPasswordConfirmation?.message}
                validateStatus={errors?.newPasswordConfirmation ? 'error' : ''}
                control={control}
              />
              <p className="custom-label">
                {sharedT('newPasswordConfirmation.label')}
              </p>
            </div>

            {watch('newPasswordConfirmation') && (
              <button
                type="button"
                className="eye-icon-btn"
                style={{
                  left: i18n.dir() === 'rtl' ? '14px' : 'auto',
                  right: i18n.dir() === 'ltr' ? '14px' : 'auto'
                }}
                onClick={() => {
                  setPasswordVisible((prevState) => !prevState);
                }}
              >
                {passwrodVisible ? <EyeOnIcon /> : <EyeOffIcon />}
              </button>
            )}
          </div>

          <div className="action-btns">
            <button
              onClick={() => {
                reset({
                  oldPassword: '',
                  newPassword: '',
                  newPasswordConfirmation: ''
                });
              }}
              type="button"
              className="cancel-btn"
            >
              {sharedT('cancel')}
            </button>
            <Button
              className="submit-btn"
              htmlType="submit"
              type="primary"
              // icon={<LoginOutlined />}
              // loading={isLoading}
            >
              {sharedT('saveChanges')}
            </Button>
          </div>
        </div>

        <div className="rules-wrap">
          <p className="rules-title">{t('profilePage.rules.title')}</p>
          <p className="rules-subTitle">{t('profilePage.rules.subTitle')}</p>

          <ul className="rules-list">
            <li>{t('profilePage.rules.one')}</li>
            <li>{t('profilePage.rules.two')}</li>
            <li>{t('profilePage.rules.three')}</li>
            <li>{t('profilePage.rules.four')}</li>
          </ul>
        </div>
      </div>

      {isSubmitting && <LoadingModal />}
    </Form>
  );
};

export default ProfileChangePasswordForm;
