/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import AntdCheckbox from '../../common/antd-form-components/AntdCheckbox';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import CountryCityOptions from '../../common/country-city-options/CountryCityOptions';
import EyeOffIcon from '../../common/icons/EyeOffIcon';
import EyeOnIcon from '../../common/icons/EyeOnIcon';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import ServicesSelectOption from '../../common/services-select-option/ServicesSelectOption';
import generalRouterLinks from '../../components/app/general-routes/gerneralRouterLinks';
import routerLinks from '../../components/app/routerLinks';
import accountTypes, {
  getCompanyTypesArr
} from '../../const-values/accountTypes';
import ForgetPasswordContext from '../../contexts/forget-password-context/ForgetPasswordContext';
import { getServicesAction } from '../../redux/general-slice/generalSliceThunkApis';
import useSignupEmailPassword from '../signin-page/useSignupEmailPassword';
import './SignupForm.scss';
import TermsModal from './TermsModal';
// import useFirebaseDeviceToken from '../../custom-hooks/useFirebaseDeviceToken';

const MerchentSignupForm = () => {
  const { i18n, t } = useTranslation();
  const countries = useSelector((store) => store.general.fetchedCountriesData);
  //
  const isSubmitting = useSelector((store) => store.user.isSubmitting);
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  useContext(ForgetPasswordContext);
  const generalLabelStr = (v) => t(`signup_form.${v}.label`);
  const generalrequiredErrStr = (v) => t(`signup_form.${v}.errors.required`);
  const generalTypeErrorStr = (v) => t(`signup_form.${v}.errors.type_error`);
  const generalMinErrorStr = (v, min) =>
    t(`signup_form.${v}.errors.min`, {
      min
    });
  const schema = Yup.object().shape({
    managerName: Yup.string().required(generalrequiredErrStr('managerName')),
    companyName: Yup.string().required(generalrequiredErrStr('companyName')),
    companyType_id: Yup.string().required(
      generalrequiredErrStr('companyType_id')
    ),
    phone: Yup.string()
      .required(generalrequiredErrStr('phone'))
      // .matches(/^[0-9]+$/, generalTypeErrorStr('phone'))
      .min(9, generalMinErrorStr('phone', 9)),
    services: Yup.array()
      .min(1, generalrequiredErrStr('services'))
      .required(generalrequiredErrStr('services')),
    subServices: Yup.array()
      .min(1, generalrequiredErrStr('subServices'))
      .required(generalrequiredErrStr('subServices')),
    subSubServices: Yup.array()
      .min(1, generalrequiredErrStr('subServices'))
      .required(generalrequiredErrStr('subServices')),
    email: Yup.string()
      .required(generalrequiredErrStr('email'))
      .email(generalTypeErrorStr('email')),
    password: Yup.string().required(generalrequiredErrStr('password')),
    password_confirmation: Yup.string()
      .required(generalrequiredErrStr('password_confirmation'))
      .oneOf(
        [Yup.ref('password')],
        generalTypeErrorStr('password_confirmation')
      ),
    terms: Yup.boolean()
      .required('يجب الموافقة على الشروط والاحكام اولا')
      .oneOf([true], 'يجب الموافقة على الشروط الاحكام لاستكمال التسجيبل')
  });
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      managerName: '',
      phone: '',
      email: '',
      password: '',
      password_confirmation: '',
      country_id: '',
      city_id: '',
      commercialRecord: '',
      taxNumber: '',
      companyName: '',
      companyType_id: '',
      services: [],
      subServices: [],
      subSubServices: [],
      remember: true
    }
  });
  const [termsModalOpened, setTermsModalOpened] = useState(false);
  // console.log('watch :, ', watch());
  // console.log('errors : ', errors);
  const { signMeUpWithEmailPassword } = useSignupEmailPassword();
  const onSubmit = (data) => {
    // signMeUpWithEmailPassword({ ...data, accountType: 3 });
    // const formatedIntlPhone = formatPhoneNumberIntl(data.phone); // '+966 50 123 4444'
    // const phoneStrArr = formatedIntlPhone.split(' '); // ['+966', '50', '123', '4444']
    // const countryCode = phoneStrArr[0];
    // let phone = '';
    // if (phoneStrArr?.length === 2) phone = phoneStrArr[1];
    // else if (phoneStrArr?.length > 2) {
    //   let array = phoneStrArr.filter((item, index) => index > 0); // ['50', '123', '4444']
    //   phone = array.join('');
    // }
    signMeUpWithEmailPassword({
      ...data,
      userType: accountTypes()?.company,
      // phone,
      // countryCode,
      accountType: accountTypes()?.company
    });
  };

  const dispatch = useDispatch();
  const isLoadingServices = useSelector(
    (store) => store.general.isLoadingServices
  );
  const services = useSelector((store) => store.general.fetchedServicesData);
  const servicesPagination = useSelector(
    (store) => store.general.servicesPagination
  );

  const handleClickMore = () => {
    if (servicesPagination.current_page < servicesPagination.total_pages)
      dispatch(
        getServicesAction({
          filterObj: {
            page: servicesPagination.current_page + 1
          }
        })
      );
  };

  const [allSubServicesArr, setAllSubServicesArr] = useState([]);
  const [allSubSubServicesArr, setAllSubSubServicesArr] = useState([]);
  useEffect(() => {
    if (watch('services')?.length >= 0 && services?.length > 0) {
      let foundObj = null;
      const arr = [];
      for (let v of watch('services')) {
        foundObj = services.find((obj) => obj?.id == v);
        if (
          foundObj &&
          typeof foundObj === 'object' &&
          foundObj?.subServices?.length >= 0
        ) {
          arr.push(...foundObj.subServices);
        }
      }
      setAllSubServicesArr(arr);
      setValue('subServices', []);
      setAllSubSubServicesArr([]);
      setValue('subSubServices', []);
    }
  }, [watch('services')]);

  useEffect(() => {
    if (watch('subServices')?.length > 0 && allSubServicesArr?.length > 0) {
      let foundObj = null;
      const arr = [];

      for (let v of watch('subServices')) {
        foundObj = allSubServicesArr.find((obj) => obj?.id == v);

        if (
          foundObj &&
          typeof foundObj === 'object' &&
          foundObj?.subServices?.length >= 0
        ) {
          arr.push(...foundObj.subServices);
        }
      }

      setAllSubSubServicesArr(arr);
      setValue('subSubServices', []);
    }
  }, [watch('subServices'), allSubServicesArr]);

  // console.log('watch : ', watch());
  // console.log('all sub : ', allSubServicesArr);
  const [form] = Form.useForm();
  return (
    <>
      <Form
        className="signup-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-header">
          <p className="bold-font main-title">{t('signup_form.newAcc')}</p>
          <div>
            <p className="sub-title">{t('signup_form.subTitle')}</p>
          </div>
        </div>

        <div className="form-body">
          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="managerName"
              type="text"
              errorMsg={errors?.managerName?.message}
              validateStatus={errors?.managerName ? 'error' : ''}
              control={control}
            />
            <p className="custom-label">{generalLabelStr('managerName')}</p>
          </div>
          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="companyName"
              type="text"
              errorMsg={errors?.companyName?.message}
              validateStatus={errors?.companyName ? 'error' : ''}
              control={control}
            />
            <p className="custom-label">{generalLabelStr('companyName')}</p>
          </div>

          <div
            className={`custom-select-field-wrapper without-icon ${i18n.dir()}`}
          >
            <AntdSelectOption
              className="custom-select-field"
              name={`companyType_id`}
              errorMsg={errors?.companyType_id && errors.companyType_id.message}
              validateStatus={errors?.companyType_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              options={
                getCompanyTypesArr(t)?.length > 0 &&
                getCompanyTypesArr(t).map((obj) => ({
                  title: obj?.name,
                  value: obj?.value
                }))
              }
              showSearch={true}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              formClassName="signup-form"
            />
            <p className="custom-label">{generalLabelStr('companyType_id')}</p>
          </div>

          <ServicesSelectOption
            control={control}
            setValue={setValue}
            errors={errors}
            name="services"
            label={generalLabelStr('services')}
            mode="multiple"
            parentClassName="signup-form"
            selectOptionArr={services}
            selectOptionPagination={servicesPagination}
            isLoadingSelectOption={isLoadingServices}
            handleClickMore={handleClickMore}
          />

          <ServicesSelectOption
            control={control}
            setValue={setValue}
            errors={errors}
            name="subServices"
            label={generalLabelStr('subServices')}
            mode="multiple"
            parentClassName="signup-form"
            selectOptionArr={allSubServicesArr}
          />

          <ServicesSelectOption
            control={control}
            setValue={setValue}
            errors={errors}
            name="subSubServices"
            label={generalLabelStr('subServices')}
            mode="multiple"
            parentClassName="signup-form"
            selectOptionArr={allSubSubServicesArr}
          />

          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="email"
              type="text"
              errorMsg={errors?.email?.message}
              validateStatus={errors?.email ? 'error' : ''}
              control={control}
            />
            <p className="custom-label">{generalLabelStr('email')}</p>
          </div>
          <div
            className={`custom-text-field-wrapper with-prefix ${i18n.dir()}`}
          >
            <AntdTextField
              className="custom-text-field"
              name="phone"
              type="text"
              errorMsg={errors?.phone?.message}
              validateStatus={errors?.phone ? 'error' : ''}
              control={control}
            />
            <p className="custom-label">
              {generalLabelStr('phone')}{' '}
              <span
                style={{
                  direction: 'ltr'
                }}
              >
                ( 558615134 )
              </span>
            </p>
            <span className="prefix-span">
              <span
                style={{
                  fontSize: 20,
                  borderRadius: 2
                }}
                className={`fi fi-sa`}
              />{' '}
              {i18n.language === 'ar' ? '(966+)' : '(+966)'}
            </span>
          </div>

          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="commercialRecord"
              type="text"
              errorMsg={errors?.commercialRecord?.message}
              validateStatus={errors?.commercialRecord ? 'error' : ''}
              control={control}
            />
            <p className="custom-label">
              {generalLabelStr('commercialRecord')}
            </p>
          </div>

          <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
            <AntdTextField
              className="custom-text-field"
              name="taxNumber"
              type="text"
              errorMsg={errors?.taxNumber?.message}
              validateStatus={errors?.taxNumber ? 'error' : ''}
              control={control}
            />
            <p className="custom-label">{generalLabelStr('taxNumber')}</p>
          </div>

          <CountryCityOptions
            countries={countries}
            countryName="country_id"
            cityName="city_id"
            countryLabel={generalLabelStr('country_id')}
            cityLabel={generalLabelStr('city_id')}
            parentClassName="signup-form"
            //
            watch={watch}
            setValue={setValue}
            control={control}
            errors={errors}
          />

          <div className="login-password-field-wrap">
            <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
              <AntdTextField
                className="custom-text-field"
                name="password"
                type={passwordVisible ? 'text' : 'password'}
                errorMsg={errors?.password?.message}
                validateStatus={errors?.password ? 'error' : ''}
                control={control}
              />
              <p className="custom-label">{generalLabelStr('password')}</p>
            </div>

            {watch('password') && (
              <button
                type="button"
                className="eye-icon-btn"
                style={{
                  left: i18n.dir() === 'rtl' ? '14px' : 'auto',
                  right: i18n.dir() === 'ltr' ? '14px' : 'auto'
                }}
                onClick={() => {
                  setPasswordVisible((prevState) => !prevState);
                }}
              >
                {passwordVisible ? <EyeOnIcon /> : <EyeOffIcon />}
              </button>
            )}
          </div>

          <div className="login-password-field-wrap">
            <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
              <AntdTextField
                className="custom-text-field"
                name="password_confirmation"
                type={passwordVisible ? 'text' : 'password'}
                errorMsg={errors?.password_confirmation?.message}
                validateStatus={errors?.password_confirmation ? 'error' : ''}
                control={control}
              />
              <p className="custom-label">
                {generalLabelStr('password_confirmation')}
              </p>
            </div>

            {watch('password_confirmation') && (
              <button
                type="button"
                className="eye-icon-btn"
                style={{
                  left: i18n.dir() === 'rtl' ? '14px' : 'auto',
                  right: i18n.dir() === 'ltr' ? '14px' : 'auto'
                }}
                onClick={() => {
                  setPasswordVisible((prevState) => !prevState);
                }}
              >
                {passwordVisible ? <EyeOnIcon /> : <EyeOffIcon />}
              </button>
            )}
          </div>

          <div className="terms-checkbox-wrap">
            <AntdCheckbox
              name="terms"
              control={control}
              label={t('signup_form.accept')}
              errorMsg={errors?.remember?.message}
              validateStatus={errors?.remember ? 'error' : ''}
            />

            <button
              className="terms-btn"
              onClick={(e) => {
                setTermsModalOpened(true);
                e.preventDefault();
              }}
              to={generalRouterLinks?.refundTermsRoute}
            >
              {t('signup_form.terms')}
            </button>
          </div>
          {/* <AntdCheckbox name="remember" label="تذكرنى" control={control} /> */}
          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={isSubmitting}
          >
            {isSubmitting
              ? t('signup_form.submit_btn.loading')
              : t('signup_form.submit_btn.label')}
          </Button>
        </div>
        <div className="already-have-account">
          <span>{t('signup_form.haveAccount')} </span>{' '}
          <RouterLink to={routerLinks?.signinPage}>
            {t('signup_form.signin')}
          </RouterLink>
        </div>

        <Link
          style={{
            textAlign: 'center',
            padding: '0 10px',
            marginTop: 12,
            color: '#246db4',
            fontWeight: 'bold'
          }}
          to={routerLinks?.userSignupRoute}
          className="antother-signup"
        >
          {t('signup_form.registerUser')}
        </Link>
      </Form>

      {termsModalOpened && (
        <TermsModal
          modalOpened={termsModalOpened}
          setModalOpened={setTermsModalOpened}
        />
      )}

      {isSubmitting && <LoadingModal />}
    </>
  );
};

export default MerchentSignupForm;
