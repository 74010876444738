import favImg from '../assets/imgs/profile-dropdown/fav-img.svg';
import ordersImg from '../assets/imgs/profile-dropdown/orders-img.svg';
import profileSettingsImg from '../assets/imgs/profile-dropdown/profile-settings-img.svg';
import subImg from '../assets/imgs/profile-dropdown/sub-img.svg';
import signoutImg from '../assets/imgs/profile-dropdown/signout-img.svg';
import congratsOrderImg from '../assets/imgs/congrats-order.svg';
import closeCircleImg from '../assets/imgs/icons/close-circle.svg';
import deleteBoxImg from '../assets/imgs/icons/form-icons/delete-box-img.svg';
import plusImg from '../assets/imgs/icons/form-icons/plus-img.svg';
import uploadCloudImg from '../assets/imgs/icons/form-icons/upload-cloud.svg';
import arrowRightImg from '../assets/imgs/icons/arrow-right.png';
import arrowLeftImg from '../assets/imgs/icons/arrow-left.png';
import cartImg from '../assets/imgs/icons/cart-img.png';
import trashImg from '../assets/imgs/icons/trash.png';
import emptyUserImg from '../assets/imgs/icons/login-signup-img.png';
import userAvatarImg from '../assets/imgs/avatar.png';
import emptyResultsImg from '../assets/imgs/empty-result.png';
import checkGreenImg from '../assets/imgs/icons/check-green.png';
import wrongRedImg from '../assets/imgs/icons/wrong-red.png';

const imgsSrcs = {
  favImg,
  ordersImg,
  profileSettingsImg,
  subImg,
  signoutImg,
  congratsOrderImg,
  closeCircleImg,
  deleteBoxImg,
  plusImg,
  uploadCloudImg,
  arrowLeftImg,
  arrowRightImg,
  cartImg,
  trashImg,
  userAvatarImg,
  emptyResultsImg,
  emptyUserImg,
  checkGreenImg,
  wrongRedImg
};

export default imgsSrcs;
