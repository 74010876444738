import { createAsyncThunk } from '@reduxjs/toolkit';
import getUserOrderCompaniesApi from '../../apis/user-quotes-api/getUserOrderCompaniesApi';
import getUserOrderCompanyOffersApi from '../../apis/user-quotes-api/getUserOrderCompanyOffersApi';
import getUserOrdersApi from '../../apis/user-quotes-api/getUserOrdersApi';
import getUserSingleOrderApi from '../../apis/user-quotes-api/getUserSingleOrderApi';
import userAnswerNegotiationApi from '../../apis/user-quotes-api/userAnswerNegotiationApi';
import userAnswerOfferApi from '../../apis/user-quotes-api/userAnswerOfferApi';
import userCancelOrderApi from '../../apis/user-quotes-api/userCancelOrderApi';
import userNegotiationHistoryApi from '../../apis/user-quotes-api/userNegotiationHistoryApi';
import customApiRequest from '../../helpers/customApiRequest';

// get user Orders action
export const getUserOrdersAction = createAsyncThunk(
  'userOrders/getUserOrdersAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getUserOrdersApi(filterObj),
      thunkAPI
    });
  }
);

// get user single order action
export const getUserSingleOrdersAction = createAsyncThunk(
  'userOrders/getUserSingleOrdersAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getUserSingleOrderApi(filterObj),
      thunkAPI
    });
  }
);

// uesr cancel order action
export const userCancelOrderAction = createAsyncThunk(
  'userOrders/userCancelOrderAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await userCancelOrderApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

// get order companies
export const userGetOrderCompaniesAction = createAsyncThunk(
  'userOrders/userGetOrderCompaniesAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getUserOrderCompaniesApi(filterObj),
      thunkAPI
    });
  }
);
// get order company offers
export const userGetOrderCompanyOffersAction = createAsyncThunk(
  'userOrders/userGetOrderCompanyOffersAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getUserOrderCompanyOffersApi(filterObj),
      thunkAPI
    });
  }
);
// answer offer action
export const userAnswerOfferAction = createAsyncThunk(
  'userOrders/userAnswerOfferAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await userAnswerOfferApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);




//////////////////////////
// get user negotiation history
export const userGetOrderNegotitionHistoryAction = createAsyncThunk(
  'userOrders/userGetOrderNegotitionHistoryAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await userNegotiationHistoryApi(filterObj),
      thunkAPI
    });
  }
);

// user answer order negotiation history
export const userAnswerOrderNegotiationAction = createAsyncThunk(
  'userOrders/userAnswerOrderNegotiationAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await userAnswerNegotiationApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
