import { t } from 'i18next';
import imgsSrcs from '../../helpers/imgsSrcs';
import './CongratsContent.scss';

const CongratsContent = ({ subTitleText, handleCancel, handleOk }) => {
  return (
    <div className="congrats-content-wrapper">
      <img src={imgsSrcs?.congratsOrderImg} alt="congrats" />

      <div className="cong-text">
        <p className="main-t">{t('congrats.title')}</p>
        <div className="sub-t">
          <p>{t('congrats.subTitlePublic')}</p>
          <p>
            {subTitleText}
            <span
              style={{
                color: '#0b0d0f'
              }}
            >
              {t('congrats.hr')}
            </span>
          </p>
        </div>
      </div>

      <div className="action-btns-wrapper">
        <button
          className="close-btn"
          onClick={() => {
            handleCancel &&
              typeof handleCancel === 'function' &&
              handleCancel();
          }}
        >
          {t('congrats.closeBtn')}
        </button>
        <button
          className="req-btn"
          onClick={() => {
            handleOk && typeof handleOk === 'function' && handleOk();
          }}
        >
          {t('congrats.viewReq')}
        </button>
      </div>
    </div>
  );
};

export default CongratsContent;
