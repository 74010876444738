/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getUserFavs } from '../../redux/user-fav-slice/cartSliceThunkApis';
import queryString from 'query-string';
import TopRatedCard from '../../components/top-rated-card/TopRatedCard';
import { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import './UserFavPage.scss';

const UserFavPage = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const fetchedUserFavs = useSelector((store) => store.userFav.fetchedUserFavs);
  const isLoadingFavs = useSelector((store) => store.userFav.isLoadingFavs);
  const fetchFavCount = useSelector((store) => store.userFav.fetchFavCount);

  useEffect(() => {
    dispatch(
      getUserFavs({
        filterObj: {
          ...values
        }
      })
    );
  }, [search, fetchFavCount]);

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (fetchedUserFavs) {
      setItems([
        {
          key: '1',
          label: t('userFavPage.suppliers'),
          children: renderCompList(fetchedUserFavs.supplier)
        },
        {
          key: '2',
          label: t('userFavPage.contractors'),
          children: renderCompList(fetchedUserFavs.contractors)
        }
      ]);
    }
  }, [fetchedUserFavs]);

  const renderCompList = (list) => {
    if (isLoadingFavs) {
      return (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <LoadingOutlined />
        </div>
      );
    } else if (!list || list?.length === 0)
      return <h3>{t('main_app_search.notFound')}</h3>;
    return (
      <div className="comp-list">
        {list.map((item) => {
          return (
            <TopRatedCard
              withFav={true}
              view="grid"
              key={item?.id}
              card={item}
            />
          );
        })}
      </div>
    );
  };

  const onChange = (key) => {
    console.log(key);
  };

  return (
    <div className="user-fav-page">
      <div className="custom-container">
        <Tabs
          type="card"
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default UserFavPage;
