/* eslint-disable eqeqeq */
import React from 'react';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import {
  useLocation,
  Navigate,
  Route,
  Routes as Switch,
  Outlet
} from 'react-router-dom';
import accountTypes, {
  allCompanyTypesArr
} from '../../const-values/accountTypes';
import ForgetPasswordContext from '../../contexts/forget-password-context/ForgetPasswordContext';
import ActivateAccountPage from '../../pages/activate-account-page/ActivateAccountPage';
import CompanyDashobardPage from '../../pages/company-dashboard-page/CompanyDashobardPage';
import CompanyProfilePage from '../../pages/company-profile-page/CompanyProfilePage';
import ForgetPasswordChangedSuccessfullyPage from '../../pages/forget-password-page/ForgetPasswordChangedSuccessfullyPage';
import ForgetPasswordEnterCodePage from '../../pages/forget-password-page/ForgetPasswordEnterCodePage';
import ForgetPasswordNewPasswordPage from '../../pages/forget-password-page/ForgetPasswordNewPasswordPage';
import ForgetPasswordPage from '../../pages/forget-password-page/ForgetPasswordPage';
import HomePage from '../../pages/home-page/HomePage';
import NotFoundPage from '../../pages/not-found-page/NotFoundPage';
import ProfilePage from '../../pages/profile-page/ProfilePage';
import SigninPage from '../../pages/signin-page/SigninPage';
import MerchentSignupPage from '../../pages/signup-page/MerchentSignupPage';
import UserSignupPage from '../../pages/signup-page/UserSignupPage';
import generalRoute from '../../utils/generalRoute';
import protectMe from '../../utils/protectMe';
import GeneralRoutes from './general-routes/GeneralRoutes';
import merchentRouterLinks from './merchent-routes/merchentRouterLinks';
import MerchentRoutes from './merchent-routes/MerchentRoutes';
import routerLinks from './routerLinks';
import UserRoutes from './user-routes/UserRoutes';
import generalRouterLinks from './general-routes/gerneralRouterLinks';
import PricesPage from '../../pages/prices-page/PricesPage';
const Routes = () => {
  const loggedIn = useSelector((store) => store.user.loggedIn);
  const user = useSelector((store) => store.user.user);
  const location = useLocation();
  const { user: forgetPasswordUser } = useContext(ForgetPasswordContext);

  ////////////////////////////////////////////
  /////////// Forget password ///////////////
  const renderForgetPasswordPageRoute = () => {
    if (loggedIn) {
      <Navigate to={routerLinks?.notFound} />;
    } else {
      // if no forgetPasswordUser in localstorage
      if (!forgetPasswordUser) {
        return <ForgetPasswordPage />;
      } else {
        // if the forgetPasswordUser does not entered an activation code then navigate to changePassword enter activation code route
        if (!forgetPasswordUser?.isUserEnteredForgetPasswordActivationCode)
          return <Navigate to={routerLinks?.forgetPasswordActivateCodeRoute} />;
        // else if the forgetPasswordUser entered a success code then navigate to changePassword enter new password route
        else if (
          forgetPasswordUser?.isUserEnteredForgetPasswordActivationCode &&
          !forgetPasswordUser?.isUserChangedForgettedPassword
        )
          return <Navigate to={routerLinks?.forgetPasswordNewPasswordRoute} />;
        //else  if the forgetPasswordUser changed password then navigate to the changed password successfully route
        else if (forgetPasswordUser?.isUserChangedForgettedPassword)
          return (
            <Navigate
              to={routerLinks?.forgetPasswordChangedSuccessfullyRoute}
            />
          );
        // else
        return <Navigate to={routerLinks?.signinPage} />;
      }
    }
  };
  /////////////////
  /////////////////
  const renderForgetPasswordEnterCodeRoute = () => {
    if (loggedIn) {
      <Navigate to={routerLinks?.notFound} />;
    } else {
      // if no forgetPasswordUser in localstorage
      if (!forgetPasswordUser) {
        return <Navigate to={routerLinks?.forgetPasswordRoute} />;
      } else {
        // if the forgetPasswordUser does not entered an activation code then return enter activation code page
        if (!forgetPasswordUser?.isUserEnteredForgetPasswordActivationCode)
          return <ForgetPasswordEnterCodePage />;
        // else if the forgetPasswordUser entered a success code then navigate to changePassword enter new password route
        else if (
          forgetPasswordUser?.isUserEnteredForgetPasswordActivationCode &&
          !forgetPasswordUser?.isUserChangedForgettedPassword
        )
          return <Navigate to={routerLinks?.forgetPasswordNewPasswordRoute} />;
        //else  if the forgetPasswordUser changed password then navigate to the changed password successfully route
        else if (forgetPasswordUser?.isUserChangedForgettedPassword)
          return (
            <Navigate
              to={routerLinks?.forgetPasswordChangedSuccessfullyRoute}
            />
          );
        // else
        return <Navigate to={routerLinks?.signinPage} />;
      }
    }
  };

  ////////////////
  ////////////////
  const renderForgetPasswordEnterNewPassRoute = () => {
    if (loggedIn) {
      <Navigate to={routerLinks?.notFound} />;
    } else {
      // if no forgetPasswordUser in localstorage
      if (!forgetPasswordUser) {
        return <Navigate to={routerLinks?.forgetPasswordRoute} />;
      } else {
        // if the forgetPasswordUser does not entered an activation code then navigate to changePassword enter activation code route
        if (!forgetPasswordUser?.isUserEnteredForgetPasswordActivationCode)
          return <Navigate to={routerLinks?.forgetPasswordActivateCodeRoute} />;
        // else if the forgetPasswordUser entered a success code then return changePassword new password page
        else if (
          forgetPasswordUser?.isUserEnteredForgetPasswordActivationCode &&
          !forgetPasswordUser?.isUserChangedForgettedPassword
        )
          return <ForgetPasswordNewPasswordPage />;
        //else  if the forgetPasswordUser changed password then navigate to the changed password successfully route
        else if (forgetPasswordUser?.isUserChangedForgettedPassword)
          return (
            <Navigate
              to={routerLinks?.forgetPasswordChangedSuccessfullyRoute}
            />
          );
        // else
        return <Navigate to={routerLinks?.signinPage} />;
      }
    }
  };
  ////////////////
  ////////////////
  const renderForgetPasswordSuccessfullRoute = () => {
    if (loggedIn) {
      <Navigate to={routerLinks?.notFound} />;
    } else {
      // if no forgetPasswordUser in localstorage
      if (!forgetPasswordUser) {
        return <Navigate to={routerLinks?.forgetPasswordRoute} />;
      } else {
        // if the forgetPasswordUser does not entered an activation code then navigate to changePassword enter activation code route
        if (!forgetPasswordUser?.isUserEnteredForgetPasswordActivationCode)
          return <Navigate to={routerLinks?.forgetPasswordActivateCodeRoute} />;
        // else if the forgetPasswordUser entered a success code then navigate to changePassword enter new password route
        else if (
          forgetPasswordUser?.isUserEnteredForgetPasswordActivationCode &&
          !forgetPasswordUser?.isUserChangedForgettedPassword
        )
          return <Navigate to={routerLinks?.forgetPasswordNewPasswordRoute} />;
        //else  if the forgetPasswordUser changed password then return changed password successfully page
        else if (forgetPasswordUser?.isUserChangedForgettedPassword)
          return <ForgetPasswordChangedSuccessfullyPage />;
        // else
        return <Navigate to={routerLinks?.signinPage} />;
      }
    }
  };
  ////////////////////////////////////////////
  /////////// End Forget password ///////////////

  const renderSigninRoute = () => {
    if (!loggedIn) {
      return <SigninPage />;
    } else if (loggedIn && user && !user?.activated) {
      return <SigninPage />;
      // return <Navigate to={routerLinks?.activateAccountRoute} />;
    } else {
      if (user?.accountType == accountTypes()?.user)
        return <Navigate to={routerLinks?.homePage} />;
      return <Navigate to={merchentRouterLinks?.merchentDashboard} />;
    }
  };
  const renderSignupRoute = (type) => {
    if (!loggedIn) {
      if (type == accountTypes()?.user) return <UserSignupPage />;
      else if (type == accountTypes()?.company) return <MerchentSignupPage />;
      // else if (type == accountTypes()?.employee)
      //   return <h1>Employee signup page</h1>;
      return null;
    } else if (loggedIn && user && !user?.activated) {
      return <Navigate to={routerLinks?.activateAccountRoute} />;
    } else {
      if (
        user?.accountType == accountTypes()?.user ||
        user?.accountType == accountTypes()?.employee
      )
        return <Navigate to={routerLinks?.homePage} />;
      return <Navigate to={merchentRouterLinks?.merchentDashboard} />;
    }
  };

  const AuthLayout = () => {
    return (
      <div className="auth-layout">
        <Outlet />
      </div>
    );
  };

  return (
    <Switch location={location} key={location?.pathname}>
      <Route element={<AuthLayout />}>
        <Route
          exact
          path={routerLinks?.userSignupRoute}
          element={renderSignupRoute(accountTypes()?.user)}
        />
        <Route
          exact
          path={routerLinks?.merchentSignupRoute}
          element={renderSignupRoute(accountTypes()?.company)}
        />
        {/* <Route
        exact
        path={routerLinks?.employeeSignupRoute}
        element={renderSignupRoute(accountTypes()?.user)}
      /> */}
        <Route
          exact
          path={routerLinks?.signinPage}
          element={renderSigninRoute()}
        />
        <Route
          exact
          path={routerLinks?.forgetPasswordRoute}
          element={renderForgetPasswordPageRoute()}
        />

        <Route
          exact
          path={routerLinks?.forgetPasswordActivateCodeRoute}
          element={renderForgetPasswordEnterCodeRoute()}
        />
        <Route
          exact
          path={routerLinks?.forgetPasswordNewPasswordRoute}
          element={renderForgetPasswordEnterNewPassRoute()}
        />
        <Route
          exact
          path={routerLinks?.forgetPasswordChangedSuccessfullyRoute}
          element={renderForgetPasswordSuccessfullRoute()}
        />

        <Route
          exact
          path={routerLinks?.activateAccountRoute}
          element={
            loggedIn && user && !user?.activated ? (
              <ActivateAccountPage />
            ) : (
              <Navigate to={routerLinks?.signinPage} />
            )
          }
        />
      </Route>
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/* {MainAppBarRoutes()} */}

      {user?.activated &&
      (user?.accountType == accountTypes()?.company ||
        user?.accountType == accountTypes()?.employee)
        ? protectMe(
            routerLinks?.merchentDashboard,
            <CompanyDashobardPage />,
            'comp_route_key',
            routerLinks?.signinPage,
            loggedIn,
            user,
            [accountTypes()?.company, accountTypes()?.employee]
          )
        : generalRoute(
            routerLinks?.homePage,
            <HomePage />,
            'home_page_key',
            routerLinks?.signinPage,
            loggedIn,
            user
          )}

      {GeneralRoutes()}
      {/* {(user?.accountType == accountTypes()?.user ||
        user?.accountType == accountTypes()?.employee) &&
        UserRoutes()} */}
      {/* {user?.accountType == accountTypes()?.company && MerchentRoutes()} */}
      {protectMe(
        routerLinks?.profileRoute,
        user?.accountType === accountTypes()?.user ? (
          <ProfilePage />
        ) : (
          <CompanyProfilePage />
        ),
        'profile_route_key',
        routerLinks?.signinPage,
        loggedIn,
        user,
        [
          accountTypes()?.user,
          accountTypes()?.company,
          accountTypes()?.employee
        ]
      )}
      {protectMe(
        generalRouterLinks.pricesRoute,
        <PricesPage />,
        'prices_route_key',
        routerLinks.signinPage,
        loggedIn,
        user,
        [accountTypes()?.company, accountTypes?.employee]
      )}

      {UserRoutes()}
      {MerchentRoutes()}

      <Route path="*" element={<NotFoundPage />} />
    </Switch>
  );
};

export default Routes;
