import './WorksSection.scss';
import img1 from '../../assets/imgs/works/works-1.png';
import img2 from '../../assets/imgs/works/works-2.png';
import img3 from '../../assets/imgs/works/works-3.png';
import line1 from '../../assets/imgs/works/line-1.png';
import line2 from '../../assets/imgs/works/line-2.png';
import { useTranslation } from 'react-i18next';

const WorksSection = () => {
  const { t } = useTranslation();
  const sharedT = (key) => t(`worksSections.${key}`);

  const renderListItem = (img, title, desc) => {
    return (
      <div className="list-item">
        <img src={img} alt="step-one" />
        <p className="item-title">{title}</p>
        <p className="item-desc">{desc}</p>
      </div>
    );
  };

  return (
    <section className="works-section">
      <div className="custom-container">
        <h2 className="section-main-title">{sharedT('title')}</h2>

        <div className="steps-list-wrap">
          {renderListItem(
            img1,
            sharedT('stepOne.title'),
            sharedT('stepOne.desc')
          )}
          <img className="line-img line1" src={line1} alt="next step" />
          {renderListItem(
            img2,
            sharedT('stepTwo.title'),
            sharedT('stepTwo.desc')
          )}
          <img className="line-img line2" src={line2} alt="next step" />
          {renderListItem(
            img3,
            sharedT('stepThree.title'),
            sharedT('stepThree.desc')
          )}
        </div>
      </div>
    </section>
  );
};

export default WorksSection;
