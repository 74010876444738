/* eslint-disable eqeqeq */
import userRouterLinks from '../app/user-routes/uesRouterLinks';
import accountTypes from '../../const-values/accountTypes';
import merchentRouterLinks from '../app/merchent-routes/merchentRouterLinks';
import UserSettingsIcon from '../../common/icons/UserSettingsIcon';
import imgsSrcs from '../../helpers/imgsSrcs';
import routerLinks from '../app/routerLinks';

const mainAppProfileMenuLinks = (i18n, t, user) => {
  const userLabelTransString = (key) => t(`userDashboard.${key}`);
  const merchentLabelTransString = (key) => t(`companyDashboard.${key}`);

  if (user?.accountType == accountTypes()?.user)
    return [
      {
        id: 1,
        name: userLabelTransString('settings'),
        link: routerLinks?.profileRoute,
        icon: <img src={imgsSrcs?.profileSettingsImg} alt="" />
      },
      {
        id: 2,
        name: userLabelTransString('fav'),
        link: userRouterLinks?.myFavRoute,
        icon: <img src={imgsSrcs?.favImg} alt="" />
      },
      {
        id: 3,
        name: userLabelTransString('orders'),
        link: userRouterLinks?.userOrdersRoute,
        icon: <img src={imgsSrcs?.ordersImg} alt="" />
      },
      {
        id: 4,
        name: userLabelTransString('subPlans'),
        link: userRouterLinks?.userSubscriptionRoute,
        icon: <img src={imgsSrcs?.subImg} alt="" />
      }
    ];
  else if (
    user?.accountType == accountTypes()?.company ||
    user?.accountType == accountTypes()?.employee
  ) {
    return [
      {
        id: 1,
        name: merchentLabelTransString('settings'),
        link: routerLinks?.profileRoute,
        icon: <img src={imgsSrcs?.profileSettingsImg} alt="settings" />
      },
      {
        id: 2,
        name: merchentLabelTransString('orders'),
        link: merchentRouterLinks?.merchentDashboard,
        icon: <img src={imgsSrcs?.ordersImg} alt="orders" />
      }
    ];
  }
  return null;
};

export default mainAppProfileMenuLinks;
