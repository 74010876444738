import { configureStore } from '@reduxjs/toolkit';
import cartReducer from './cart-slice/cartSlice';
import companiesReducer from './companies-slice/companiesSlice';
import companyOrdersReducer from './company-orders-slice/companyOrdersSlice';
import generalReducer from './general-slice/generalSlice';
import quoteReducer from './quote-slice/quoteSlice';
import userFavReducer from './user-fav-slice/userFavSlice';
import userOrdersReducer from './user-orders-slice/userOrdersSlice';
import userReducer from './user-slice/userSlice';

export default configureStore({
  reducer: {
    user: userReducer,
    userFav: userFavReducer,
    general: generalReducer,
    companies: companiesReducer,
    cart: cartReducer,
    quote: quoteReducer,
    userOrders: userOrdersReducer,
    companyOrders: companyOrdersReducer
  }
});
