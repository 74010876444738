import { createSlice } from '@reduxjs/toolkit';
import checkRes from '../../utils/checkRes';
import {
  getUserOrdersAction,
  getUserSingleOrdersAction,
  userAnswerOfferAction,
  userAnswerOrderNegotiationAction,
  userCancelOrderAction,
  userGetOrderCompaniesAction,
  userGetOrderCompanyOffersAction,
  userGetOrderNegotitionHistoryAction,
} from './userOrdersSliceThunkApi';

const initialState = {
  isLoadingUserOrders: false,
  fetchedUserOrders: [],
  userOrdersPagination: {
    current_page: 1,
    par_page: 0,
    total: 0
  },
  selectedCompOffersModalOpened: false,
  negotiationssModalOpened: false,
  selectedOffer: null,
  orderNegotiations: [],
  selectedNegotiation: null,
  /////////////////////
  isSubmitting: false,
  isLoadingUserOrder: false,
  isLoadingUserOrderNegotiations: false,
  fetchedUserSingleOrder: null,
  fetchSingleOrderCount: 0,
  //////////////
  cancelOrderModalOpened: false,
  /////////////////
  ////////////////
  confirmNegotiateModalOpened: false,
  rejectNegotiateModalOpened: false,
  //////////////


  isLoadingOrderCompanies: false,
  fetchOrderCompaniesCount: 0,
  fetchedOrderCompanies: [],
  companiesModalOpened: false,
  acceptCompOfferModalOpened: false,
  rejectCompOfferModalOpened: false,
  userOfferModalOpened: false,
  isSubmittingUserOffer: false,
  isLoadingCompanyOffers: false,
  fetchedCompanyOffers: null,
  selectedCompany: null,
  selectedCompanyOffer: null,
};

const userOrdersSlice = createSlice({
  name: 'userOrders',
  initialState,
  reducers: {
    setSelectedCompOffersModalOpenedAction: (sliceState, action) => {
      sliceState.selectedCompOffersModalOpened = action.payload;
    },
    setNegotiationsModalOpenedAction: (sliceState, action) => {
      sliceState.negotiationssModalOpened = action.payload;
    },
    setCancelOrderModalOpenedAction: (sliceState, action) => {
      sliceState.cancelOrderModalOpened = action.payload;
    },
    setConfirmNegotiateModalOpened: (sliceState, action) => {
      sliceState.confirmNegotiateModalOpened = action.payload;
    },
    setRejectNegotiateModalOpened: (sliceState, action) => {
      sliceState.rejectNegotiateModalOpened = action.payload;
    },
    setSelectedNeotiationAction: (sliceState, action) => {
      sliceState.selectedNegotiation = action.payload;
    },
    setCompaniesModalOpenedAction: (sliceState, action) => {
      sliceState.companiesModalOpened = action.payload
    },
    setSelectedCompanyAction: (sliceState, action) => {
      sliceState.selectedCompany = action.payload
    },
    setSelectedCompanyOfferAction: (sliceState, action) => {
      sliceState.selectedCompanyOffer = action.payload
    },
    setAcceptCompOfferModalOpenedAction: (sliceState, action) => {
      sliceState.acceptCompOfferModalOpened = action.payload
    },
    setRejectCompOfferModalOpenedAction: (sliceState, action) => {
      sliceState.rejectCompOfferModalOpened = action.payload
    },
    setUserOfferModalOpenedAction: (sliceState, action) => {
      sliceState.userOfferModalOpened = action.payload
    }
  },
  extraReducers: {
    // get user orders
    [getUserOrdersAction.pending]: (state) => {
      state.isLoadingUserOrders = true;
    },
    [getUserOrdersAction.fulfilled]: (state, action) => {
      state.isLoadingUserOrders = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedUserOrders = data?.data;
      if (data?.pagination) state.userOrdersPagination = data.pagination;
      // if (data?.pagination) state.tablePagination = data.pagination;
    },
    [getUserOrdersAction.rejected]: (state, action) => {
      state.isLoadingUserOrders = false;
    },
    // get single user order
    [getUserSingleOrdersAction.pending]: (state) => {
      state.isLoadingUserOrder = true;
    },
    [getUserSingleOrdersAction.fulfilled]: (state, action) => {
      state.isLoadingUserOrder = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedUserSingleOrder = data;
      // if (data?.pagination) state.tablePagination = data.pagination;
    },
    [getUserSingleOrdersAction.rejected]: (state, action) => {
      state.isLoadingUserOrder = false;
    },
    //
    //user cancel order action
    [userCancelOrderAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [userCancelOrderAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.fetchSingleOrderCount += 1;
        state.cancelOrderModalOpened = false;
      }
    },
    [userCancelOrderAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    //
    //
    //
    //user get order companies action
    [userGetOrderCompaniesAction.pending]: (state) => {
      state.isLoadingOrderCompanies = true;
    },
    [userGetOrderCompaniesAction.fulfilled]: (state, action) => {
      state.isLoadingOrderCompanies = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        const data = action?.payload?.res?.data?.data;
        state.fetchedOrderCompanies = data;
      }
    },
    [userGetOrderCompaniesAction.rejected]: (state) => {
      state.isLoadingOrderCompanies = false;
    },
    //
    //
    //user get order company offers action
    [userGetOrderCompanyOffersAction.pending]: (state) => {
      state.isLoadingCompanyOffers = true;
    },
    [userGetOrderCompanyOffersAction.fulfilled]: (state, action) => {
      state.isLoadingCompanyOffers = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        const data = action?.payload?.res?.data?.data;
        state.fetchedCompanyOffers = data;
      }
    },
    [userGetOrderCompanyOffersAction.rejected]: (state) => {
      state.isLoadingCompanyOffers = false;
    },
    //
    //user answer offer action
    [userAnswerOfferAction.pending]: (state) => {
      state.isSubmittingUserOffer = true;
    },
    [userAnswerOfferAction.fulfilled]: (state, action) => {
      state.isSubmittingUserOffer = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.selectedCompanyOffer = null;
        state.fetchSingleOrderCount += 1;
        state.userOfferModalOpened = false;
        state.acceptCompOfferModalOpened = false;
        state.rejectCompOfferModalOpened = false;
      }
    },
    [userAnswerOfferAction.rejected]: (state) => {
      state.isSubmittingUserOffer = false;
    },

    //user get order negotiations history
    [userGetOrderNegotitionHistoryAction.pending]: (state) => {
      state.isLoadingUserOrderNegotiations = true;
    },
    [userGetOrderNegotitionHistoryAction.fulfilled]: (state, action) => {
      state.isLoadingUserOrderNegotiations = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        const data = action?.payload?.res?.data?.data;
        state.orderNegotiations = data;
      }
    },
    [userGetOrderNegotitionHistoryAction.rejected]: (state) => {
      state.isLoadingUserOrderNegotiations = false;
    },
    // user answer order nego
    [userAnswerOrderNegotiationAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [userAnswerOrderNegotiationAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.fetchSingleOrderCount += 1;
        state.fetchOrderCompaniesCount += 1;
        state.userOfferModalOpened = false;
        state.confirmNegotiateModalOpened = false;
        state.rejectNegotiateModalOpened = false;
      }
    },
    [userAnswerOrderNegotiationAction.rejected]: (state) => {
      state.isSubmitting = false;
    }
  }
});

const userOrdersReducer = userOrdersSlice.reducer;

export const {
  setSelectedCompOffersModalOpenedAction,
  setNegotiationsModalOpenedAction,
  setCancelOrderModalOpenedAction,
  setConfirmNegotiateModalOpened,
  setRejectNegotiateModalOpened,
  setSelectedNeotiationAction,
  setCompaniesModalOpenedAction,
  setSelectedCompanyOfferAction,
  setSelectedCompanyAction,
  setUserOfferModalOpenedAction,
  setAcceptCompOfferModalOpenedAction,
  setRejectCompOfferModalOpenedAction
} = userOrdersSlice.actions;

export default userOrdersReducer;
