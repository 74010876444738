/* eslint-disable eqeqeq */
import React from 'react';
import { Dropdown, Button, Avatar } from 'antd';
import { Link as RouterLink } from 'react-router-dom';
import { UserOutlined, LoadingOutlined } from '@ant-design/icons';
import useSignout from '../../custom-hooks/useSignout';
import userRouterLinks from '../app/user-routes/uesRouterLinks';
import merchentRouterLinks from '../app/merchent-routes/merchentRouterLinks';
import CustomImage from '../../common/custom-image/CustomImage';
import mainAppProfileMenuLinks from './mainAppProfileMenuLinks';
import { useTranslation } from 'react-i18next';
import dropdownIcon from '../../assets/imgs/icons/appbar-dropdown-icon.svg';
import './MainAppProfileMenu.scss';
import accountTypes from '../../const-values/accountTypes';
import { useSelector } from 'react-redux';
import imgsSrcs from '../../helpers/imgsSrcs';
import LoadingModal from '../../common/loading-modal/LoadingModal';

const MainAppProfileMenu = () => {
  const user = useSelector((store) => store.user.user);
  const { t, i18n } = useTranslation();
  const { isLoadingSignout, signMeOut } = useSignout();
  const handleSignout = () => {
    signMeOut();
  };

  const renderProfileMenu = () => {
    if (mainAppProfileMenuLinks(i18n, t, user)?.length > 0) {
      return mainAppProfileMenuLinks(i18n, t, user).map((item) => ({
        key: item?.id,
        label: (
          <RouterLink className="menu-link" to={item?.link}>
            {item?.icon}
            {item?.name}
          </RouterLink>
        )
      }));
      // <Menu.Item key={item?.id}>
      //   <RouterLink className="menu-link" to={item?.link}>
      //     {item?.icon}
      //     {item?.name}
      //   </RouterLink>
      // </Menu.Item>
    }
    return null;
  };

  return (
    <div className="avatar-wrapper">
      <Dropdown
        overlayClassName="main-app-profile-dropdown"
        arrow
        trigger={['click']}
        // disabled={loadingSignout}
        menu={{
          items: [
            {
              key: 'user_settings_key',
              label: (
                <RouterLink
                  to={
                    user?.accountType == accountTypes()?.company ||
                    user?.accountType == accountTypes()?.employee
                      ? merchentRouterLinks?.merchentSettings
                      : userRouterLinks?.accountSettings
                  }
                  className="settings-link"
                >
                  <CustomImage
                    style={{
                      width: '58px',
                      height: '58px',
                      border: '2px solid #58b030',
                      borderRadius: '50%'
                    }}
                    src={user?.image}
                    className="profile-img"
                  />
                  <div className="name-wallet">
                    <div className="name">
                      {t('profileMenu.hello')}, {user?.name}
                    </div>
                  </div>
                </RouterLink>
              )
            },
            // {
            //   key: 'profile_key',
            //   className: 'profile-label',
            //   label: t('profileMenu.profile')
            // },
            ...renderProfileMenu(),
            {
              key: 'signout_key',
              icon: <img src={imgsSrcs?.signoutImg} alt="signout" />,
              className: 'signout-btn',
              label: t('main_app_bar_links.signout'),
              onClick: () => {
                handleSignout();
              }
            }
          ]
        }}
      >
        <Button className="profile-menu-btn" type="text">
          {/* <Avatar size={40} icon={<UserOutlined />} src={user?.image} /> */}
          <CustomImage src={user?.image} className="profile-img-btn" />

          <span
            style={{
              display: 'inline-block',
              maxWidth: '92px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textTransform: 'capitalize'
            }}
          >
            {user?.name}
          </span>
          {isLoadingSignout ? (
            <LoadingOutlined />
          ) : (
            <img src={dropdownIcon} alt="drop" />
          )}
        </Button>
      </Dropdown>

      {isLoadingSignout && <LoadingModal />}
    </div>
  );
};

export default MainAppProfileMenu;
