import React from 'react';

const RadioButtonEmpty = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <circle
        cx="11"
        cy="11"
        r="10.25"
        stroke={color || '#98A6B3'}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default RadioButtonEmpty;
