import FaqSection from '../home-page/FaqSection';
import HomeContactSection from '../home-page/HomeContactSection';

const ContactPage = () => {
  return (
    <div className="contact-page">
      <HomeContactSection />
      <FaqSection />
    </div>
  );
};

export default ContactPage;
