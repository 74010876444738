import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Modal } from 'antd';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import imgsSrcs from '../../helpers/imgsSrcs';
import {
  resetCartAction,
  setNegotiationModalOpenedAction,
  setNegotiationPriceAction
} from '../../redux/cart-slice/cartSlice';
import { setQuoteType } from '../../redux/quote-slice/quoteSlice';
import { createSupplierQuoteAction } from '../../redux/quote-slice/quoteSliceThunkApi';
import checkRes from '../../utils/checkRes';
import './NegotiationModal.scss';

const NegotiationModal = () => {
  const { t, i18n } = useTranslation();
  const negotiationModalOpened = useSelector(
    (store) => store.cart.negotiationModalOpened
  );
  const dispatch = useDispatch();
  const sharedT = (key) => t(`cartPage.${key}`);

  const schema = Yup.object().shape({
    price: Yup.string()
      .required(sharedT('negotiationForm.price.errors.required'))
      .matches(
        /^(\d+)?(\.\d+)?$/,
        sharedT('negotiationForm.price.errors.numbersOnly')
      )
      .test(
        'price',
        sharedT('negotiationForm.price.errors.invalidPrice'),
        (v, context) => {
          let result = true;
          if (!v || (v && parseFloat(v) <= 0)) {
            result = false;
          }
          return result;
        }
      )
      .test(
        'price',
        sharedT('negotiationForm.price.errors.invalidPrice'),
        (v, context) => {
          let result = true;
          if (
            v &&
            parseFloat(v) < fetchedSingleCompany?.companyData?.minimum_order
          ) {
            result = false;
          }
          return result;
        }
      )
  });
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      price: ''
    }
  });

  const cartProducts = useSelector((store) => store.cart.cart.cartProducts);
  const fetchedSingleCompany = useSelector(
    (store) => store.companies.fetchedSingleCompany
  );

  const [busy, setBusy] = useState(false);
  const onSubmit = async (data) => {
    // dispatch(setNegotiationPriceAction(data.price));
    // dispatch(setQuoteType(quoteTypes?.supplierQuote));
    // dispatch(setQuoteModalOpened(true));
    setBusy(true);
    const formData = new FormData();
    if (cartProducts?.length > 0) {
      const arr = cartProducts.map((p) =>
        p?.option_id
          ? {
              product_id: p?.product_id || '',
              quantity: p?.quantity || '',
              unit_id: p?.unit?.id || '',
              option_id: p?.option?.id || ''
            }
          : {
              product_id: p?.product_id || '',
              quantity: p?.quantity || '',
              unit_id: p?.unit?.id || ''
            }
      );
      formData.append('products', JSON.stringify(arr));
    }
    formData.append('negotiatePrice', data.price);
    formData.append('company_id', fetchedSingleCompany?.id || '');
    //
    //
    //

    const action = await dispatch(
      createSupplierQuoteAction({
        dataToBeSubmitted: formData
      })
    );
    const res = action?.payload?.res;
    if (checkRes(res)) {
      dispatch(resetCartAction());
      dispatch(setNegotiationModalOpenedAction(false));
    }
  };

  const [form] = Form.useForm();
  return (
    <>
      <Modal
        destroyOnClose
        className="negotiation-modal"
        closeIcon={<img src={imgsSrcs.closeCircleImg} alt="close" />}
        width="96%"
        style={{ maxWidth: '732px' }}
        title={
          <div className="modal-h">
            <p className="main-header-t">{sharedT('negotiationPrice')}</p>
            <p className="sub-header-t">{sharedT('addNegoPrice')}</p>
          </div>
        }
        open={negotiationModalOpened}
        onOk={() => {
          dispatch(setNegotiationModalOpenedAction(false));
          dispatch(setQuoteType(''));
        }}
        onCancel={() => {
          dispatch(setNegotiationModalOpenedAction(false));
          dispatch(setQuoteType(''));
        }}
        footer={false}
      >
        <Form
          className="negotiate-form custom-shared-form"
          form={form}
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}
        >
          <div className="form-header">
            <p className="sub-title">{sharedT('addNegoFormTitle')}</p>
          </div>

          <div className="form-body">
            <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
              <AntdTextField
                className="custom-text-field"
                name="price"
                type="text"
                errorMsg={errors?.price?.message}
                validateStatus={errors?.price ? 'error' : ''}
                control={control}
              />
              <p className="custom-label">
                {sharedT('negotiationForm.price.label')}
              </p>
            </div>

            <div className="action-btns">
              <button
                onClick={() => {
                  dispatch(setNegotiationPriceAction(''));
                  dispatch(setNegotiationModalOpenedAction(false));
                }}
                type="button"
                className="cancel-btn"
              >
                {sharedT('negotiationForm.cancel')}
              </button>
              <button type="submit" className="submit-btn">
                {sharedT('negotiationForm.submit')}
              </button>
            </div>
          </div>
        </Form>
      </Modal>
      {busy && <LoadingModal />}
    </>
  );
};

export default NegotiationModal;
