import React from 'react';

const HeartIcon = ({ color }) => {
  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="blue"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 19.6496C11.19 19.6496 10.89 19.6096 10.64 19.5196C6.82 18.2096 0.75 13.5596 0.75 6.68961C0.75 3.18961 3.58 0.349609 7.06 0.349609C8.75 0.349609 10.33 1.00961 11.5 2.18961C12.67 1.00961 14.25 0.349609 15.94 0.349609C19.42 0.349609 22.25 3.19961 22.25 6.68961C22.25 13.5696 16.18 18.2096 12.36 19.5196C12.11 19.6096 11.81 19.6496 11.5 19.6496ZM7.06 1.84961C4.41 1.84961 2.25 4.01961 2.25 6.68961C2.25 13.5196 8.82 17.3196 11.13 18.1096C11.31 18.1696 11.7 18.1696 11.88 18.1096C14.18 17.3196 20.76 13.5296 20.76 6.68961C20.76 4.01961 18.6 1.84961 15.95 1.84961C14.43 1.84961 13.02 2.55961 12.11 3.78961C11.83 4.16961 11.19 4.16961 10.91 3.78961C9.98 2.54961 8.58 1.84961 7.06 1.84961Z"
        fill={color ? color : '#6D6D6D'}
      />
    </svg>
  );
};

export default HeartIcon;
