import protectMe from '../../../utils/protectMe';
import routerLinks from '../routerLinks';
import userRouterLinks from './uesRouterLinks';
import accountTypes from '../../../const-values/accountTypes';
import UserOrdersPage from '../../../pages/user-orders-page/UserOrdersPage';
import UserSingleOrderPage from '../../../pages/user-single-order-page/UserSingleOrderPage';
import { useSelector } from 'react-redux';
import UserFavPage from '../../../pages/user-fav-page/UserFavPage';

const UserRoutes = () => {
  // const { user, loggedIn } = useContext(UserContext);

  const loggedIn = useSelector((store) => store.user.loggedIn);
  const user = useSelector((store) => store.user.user);
  return [
    protectMe(
      userRouterLinks?.userDashboard,
      <h1>User dashboard page</h1>,
      'user_dashboard_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    protectMe(
      userRouterLinks?.accountSettings,
      <h1>User settings page</h1>,
      'user_settings_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    protectMe(
      userRouterLinks?.changePassword,
      <h1>User change password page</h1>,
      'user_change_pass_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    protectMe(
      userRouterLinks?.userOrdersRoute,
      <UserOrdersPage />,
      'user_orders_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    protectMe(
      userRouterLinks?.userSingleOrderRoute(),
      <UserSingleOrderPage />,
      'user_single_order_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    protectMe(
      userRouterLinks?.myFavRoute,
      <UserFavPage />,
      'user_fav_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    )
  ];
};

export default UserRoutes;
