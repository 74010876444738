import axios from 'axios';
import apisUrls from '../apisUrls';

export const forgetPasswordEnterEmailApi = (values) => {
  return axios.post(apisUrls.forgetPasswordUrl, values);
};

export const forgetPasswordEnterCodeApi = (token, values) => {
  return axios.post(apisUrls.checkPasswordCodeUrl, values, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const forgetPasswordResendCodeApi = (token, values) => {
  return axios.post(apisUrls.resendCode, values, {});
};

export const forgetPasswordResetPasswordApi = (token, values) => {
  return axios.post(apisUrls.resetPasswordUrl, values, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
