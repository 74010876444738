const StarIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.54258 12.969C8.31299 12.8409 8.03318 12.8409 7.8036 12.969L4.26877 14.9404C3.70559 15.2545 3.0316 14.7667 3.15739 14.1358L3.94675 10.177C3.99796 9.91993 3.9115 9.65478 3.71838 9.47683L0.743782 6.73636C0.269864 6.29971 0.527285 5.51029 1.1683 5.43454L5.19127 4.95916C5.45254 4.92829 5.67888 4.76442 5.78909 4.52642L7.48619 0.860954C7.75655 0.27698 8.58963 0.27698 8.85998 0.860954L10.5571 4.52642C10.6673 4.76442 10.8936 4.92829 11.1549 4.95916L15.1779 5.43454C15.8189 5.51029 16.0763 6.29971 15.6023 6.73636L12.6278 9.47683C12.4347 9.65478 12.3482 9.91993 12.3994 10.177L13.1888 14.1358C13.3146 14.7667 12.6406 15.2545 12.0773 14.9404L8.54258 12.969Z"
        fill={color || '#E76229'}
      />
    </svg>
  );
};

export default StarIcon;
