import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import ForgetPasswordModal from './ForgetPasswordModal';
import ForgetPasswordContext from '../../contexts/forget-password-context/ForgetPasswordContext';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import routerLinks from '../../components/app/routerLinks';
import AntdCheckbox from '../../common/antd-form-components/AntdCheckbox';
import './SigninForm.scss';
import EyeOnIcon from '../../common/icons/EyeOnIcon';
import EyeOffIcon from '../../common/icons/EyeOffIcon';
import { useDispatch, useSelector } from 'react-redux';
import { signinAction } from '../../redux/user-slice/userSliceThunkApis';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import {
  setCurrentUserAction,
  setUserToStateAction
} from '../../redux/user-slice/userSlice';
// import useFCMDeviceToken from '../../custom-hooks/useFCMDeviceToken';

const SigninForm = () => {
  const { setUser: setForgetPassUser } = useContext(ForgetPasswordContext);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const generalLabelStr = (key) => t(`signin_form.${key}.label`);
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  // const fcmDeviceToken = useFCMDeviceToken();
  const { forgetPasswordModalOpened } = useContext(ForgetPasswordContext);
  const schema = Yup.object().shape({
    // loginKey: Yup.string()
    //   .required('من فضلك ادخل البريد الاكترونى')
    //   .email('ادخل بريد الكترونى صحيح'),
    // email: Yup.string()
    //   .required(t('signin_form.email.errors.required'))
    //   .email(t('signin_form.email.errors.type_error')),
    phone: Yup.string().required(t('signin_form.phone.errors.required')),
    password: Yup.string().required(t('signin_form.password.errors.required'))
  });
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      // email: 'testNew@test.com',
      // password: '12341234',
      phone: '',
      password: '',
      remember: true
    }
  });
  const isSubmitting = useSelector((store) => store.user.isSubmitting);
  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    try {
      const action = await dispatch(
        signinAction({
          dataToBeSubmitted: {
            ...data
          }
        })
      );
      const res = action?.payload?.res;
      if (res?.data?.data && res?.data?.status === 1) {
        if (res?.data?.data?.status === 0) {
          dispatch(
            setUserToStateAction({
              ...res?.data.data,
              accountType: data?.accountType || res.data.data.userType,
              companyType: res.data.data.companyType,
              activated: false,
              remember: data?.remember || false,
              resendCode: true
            })
          );
          navigate(routerLinks?.activateAccountRoute);
        } else if (res?.data?.data?.status === 1) {
          if (data.remember) {
            dispatch(
              setCurrentUserAction({
                ...res?.data?.data,
                accountType: data?.accountType || res.data.data.userType,
                companyType: res.data.data?.companyType,
                activated: true,
                remember: data?.remember || false
              })
            );
            setForgetPassUser(null);
            navigate(routerLinks?.homePage);
          } else {
            dispatch(
              setUserToStateAction({
                ...res.data.data,
                accountType: data?.accountType || res.data.data.userType,
                companyType: res.data.data?.companyType,
                activated: true,
                remember: data?.remember || false
              })
            );
            setForgetPassUser(null);
            navigate(routerLinks?.homePage);

            // if (
            //   data?.accountType == accountTypes()?.user ||
            //   res?.data?.data?.type == accountTypes()?.user
            // ) {
            //   navigate(routerLinks?.homePage);
            // } else {
            //   navigate(merchentRouterLinks?.merchentDashboard);
            // }
          }
        }
      }
    } catch (error) {}
  };

  const [form] = Form.useForm();
  return (
    <>
      <Form
        className="signin-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-header">
          <p className="bold-font main-title">{t('signin_form.title')}</p>
          <p className="sub-title">{t('signin_form.sub_title')}</p>
        </div>

        <div className="form-body">
          <div
            className={`custom-text-field-wrapper with-prefix ${i18n.dir()}`}
          >
            <AntdTextField
              className="custom-text-field"
              name="phone"
              type="text"
              errorMsg={errors?.phone?.message}
              validateStatus={errors?.phone ? 'error' : ''}
              control={control}
            />
            <p className="custom-label">
              {generalLabelStr('phone')}{' '}
              <span
                style={{
                  direction: 'ltr'
                }}
              >
                ( 558615134 )
              </span>
            </p>
            <span className="prefix-span">
              <span
                style={{
                  fontSize: 20,
                  borderRadius: 2
                }}
                className={`fi fi-sa`}
              />{' '}
              {i18n.language === 'ar' ? '(966+)' : '(+966)'}
            </span>
          </div>

          <div className="login-password-field-wrap">
            <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
              <AntdTextField
                className="custom-text-field"
                name="password"
                type={passwordVisible ? 'text' : 'password'}
                errorMsg={errors?.password?.message}
                validateStatus={errors?.password ? 'error' : ''}
                control={control}
              />
              <p className="custom-label">{generalLabelStr('password')}</p>
            </div>

            {watch('password') && (
              <button
                type="button"
                className="eye-icon-btn"
                style={{
                  left: i18n.dir() === 'rtl' ? '14px' : 'auto',
                  right: i18n.dir() === 'ltr' ? '14px' : 'auto'
                }}
                onClick={() => {
                  setPasswordVisible((prevState) => !prevState);
                }}
              >
                {passwordVisible ? <EyeOnIcon /> : <EyeOffIcon />}
              </button>
            )}
          </div>
          <RouterLink
            className="forget-p"
            // onClick={() => setForgetPasswordModalOpened(true)}
            to={routerLinks?.forgetPasswordRoute}
          >
            {t('signin_form.forget_pass')}
          </RouterLink>
          <AntdCheckbox
            name="remember"
            control={control}
            label={t('signup_form.remember_me')}
            errorMsg={errors?.remember?.message}
            validateStatus={errors?.remember ? 'error' : ''}
          />
          {/* <AntdCheckbox name="remember" label="تذكرنى" control={control} /> */}
          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={isSubmitting}
          >
            {isSubmitting
              ? t('signin_form.submit_btn.loading')
              : t('signin_form.submit_btn.label')}
          </Button>
        </div>
        <div className="dont-have-account-text">
          <span>{t('signin_form.dont')}</span>{' '}
          <RouterLink to={routerLinks?.userSignupRoute}>
            {t('signin_form.new_account')}
          </RouterLink>
        </div>
      </Form>

      {forgetPasswordModalOpened && <ForgetPasswordModal />}
      {isSubmitting && <LoadingModal />}
    </>
  );
};

export default SigninForm;
