import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import accountTypes from '../../../const-values/accountTypes';
import {
  employeesPermissionsValues,
  storePermissionsValues
} from '../../../const-values/permissions';
// import { ChatProvider } from '../../../contexts/chat-context/ChatContext';
import CompanyOrdersPage from '../../../pages/company-dashboard-page/CompanyOrdersPage';
import CompanySingleOrderPage from '../../../pages/company-dashboard-page/CompanySingleOrderPage';
// import UserChangePasswordPage from '../../../pages/user-profile-menu-pages/user-change-password-page/UserChangePasswordPage';
import protectMe from '../../../utils/protectMe';
import routerLinks from '../routerLinks';

const MerchentRoutes = () => {
  const loggedIn = useSelector((store) => store.user.loggedIn);
  const user = useSelector((store) => store.user.user);
  const { t } = useTranslation();
  const {
    editStores,
    deleteStores,
    addStores,
    reviewOrders,
    addEmployees,
    reviewStatistics
  } = storePermissionsValues(t);
  const { addProducts, editProducts, deleteProducts } =
    employeesPermissionsValues(t);
  const checkAuthedEmployeeRoute = ({
    rolesArr,
    supposedRouteRole,
    authed
  }) => {
    if (rolesArr?.length > 0) {
      // check permission
      if (
        rolesArr.includes(editStores) ||
        rolesArr.includes(deleteStores) ||
        rolesArr.includes(addStores) ||
        rolesArr.includes(reviewOrders) ||
        rolesArr.includes(addEmployees) ||
        rolesArr.includes(reviewStatistics) ||
        rolesArr.includes(addProducts) ||
        rolesArr.includes(editProducts) ||
        rolesArr.includes(deleteProducts)
      ) {
        // check if authed by default like view stores route

        if (authed) {
          return accountTypes()?.employee;
        } else if (rolesArr.includes(supposedRouteRole))
          return accountTypes()?.employee;
      }
      return null;
    }
  };

  return [
    // protectMe(
    //   routerLinks?.merchentDashboard,
    //   <MerchentDashboardPage />,
    //   1,
    //   routerLinks?.signinPage,
    //   loggedIn,
    //   user
    // ),

    protectMe(
      routerLinks?.companyOrdersRoute,
      <CompanyOrdersPage />,
      'comp_orders_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.company, accountTypes()?.employee]
    ),
    protectMe(
      routerLinks?.companySingleOrderRoute(),
      <CompanySingleOrderPage />,
      'comp_single_order_key',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.company, accountTypes()?.employee]
    ),

    protectMe(
      routerLinks?.merchentStores,
      <h1>Merchent stores page</h1>,
      'merchent_stores_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.company, accountTypes()?.employee]
    ),
    // new store route
    protectMe(
      routerLinks?.merchentNewStore,
      <h1>New store page</h1>,
      'merchent_new_store_route_id',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [
        accountTypes()?.company,
        checkAuthedEmployeeRoute({
          rolesArr:
            user?.storeRoles?.length >= 0 &&
            user?.employeeRolesArray?.length >= 0
              ? [...user?.storeRoles, ...user?.employeeRolesArray]
              : [],
          supposedRouteRole: storePermissionsValues(t)?.addStores,
          authed: false
        })
      ]
    )
    // edit store route
    // protectMe(
    //   routerLinks?.merchentEditStore(),
    //   <h1>Newe store page</h1>,
    //   'new_store_route_id',
    //   routerLinks?.signinPage,
    //   loggedIn,
    //   user,
    //   [
    //     accountTypes()?.company,
    //     checkAuthedEmployeeRoute({
    //       rolesArr:
    //         user?.storeRoles?.length >= 0 &&
    //         user?.employeeRolesArray?.length >= 0
    //           ? [...user?.storeRoles, ...user?.employeeRolesArray]
    //           : [],
    //       supposedRouteRole: storePermissionsValues(t)?.editStores,
    //       authed: false
    //     })
    //   ]
    // ),
  ];
};

export default MerchentRoutes;
