/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../common/bread-crumb/Breadcrumb';
import CustomEmpty from '../../common/custom-empty/CustomEmpty';
import generalRouterLinks from '../../components/app/general-routes/gerneralRouterLinks';
import routerLinks from '../../components/app/routerLinks';
import {
  calcCartTotal,
  resetCartAction,
  setNegotiationModalOpenedAction,
  setNegotiationPriceAction
} from '../../redux/cart-slice/cartSlice';
import { getSingleCompanyAction } from '../../redux/companies-slice/companiesSliceThunkApis';
import { createSupplierQuoteAction } from '../../redux/quote-slice/quoteSliceThunkApi';
import { setNotAuthedModalOpened } from '../../redux/user-slice/userSlice';
import checkRes from '../../utils/checkRes';
import CartCard from './CartCard';
import './CartPage.scss';
import NegotiationModal from './NegotiationModal';
import orderPaymentApi from '../../apis/general/orderPaymentApi';
import LoadingModal from '../../common/loading-modal/LoadingModal';

const CartPage = () => {
  const { i18n, t } = useTranslation();
  const sharedT = (key) => t(`cartPage.${key}`);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const cartProducts = useSelector((store) => store.cart.cart.cartProducts);
  const cartServiceName = useSelector(
    (store) => store.cart.cart.cartServiceName
  );
  const cartSupplierId = useSelector((store) => store.cart.cart.cartSupplierId);
  const cartServiceId = useSelector((store) => store.cart.cart.cartServiceId);
  const cartTotal = useSelector((store) => store.cart.cart.cartTotal);
  const user = useSelector((store) => store.user.user);
  const fetchedSingleCompany = useSelector(
    (store) => store.companies.fetchedSingleCompany
  );

  useEffect(() => {
    dispatch(calcCartTotal());
    // if (cartProducts?.length > 0) {
    //   let total = 0;
    //   total = cartProducts.reduce((acc, curr) => {
    //     return acc + Number(curr.price) * Number(curr.quantity);
    //   }, total);

    //   setTotal(total);
    // }
  }, [cartProducts]);

  useEffect(() => {
    async function getSingleComp() {
      try {
        const action = await dispatch(
          getSingleCompanyAction({
            filterObj: { company_id: params?.company_id }
          })
        );
        const res = action?.payload?.res;
        if (!checkRes(res)) navigate(routerLinks?.homePage);
      } catch (error) {}
    }

    getSingleComp();
  }, [i18n.language]);

  const renderCartItemsList = useMemo(() => {
    return () =>
      cartProducts.map((item, index) => {
        return (
          <CartCard
            key={index}
            card={{
              ...item,
              companyName: fetchedSingleCompany?.name
            }}
          />
        );
      });
  }, [cartProducts, fetchedSingleCompany]);

  const [busy, setBusy] = useState(false);
  const handleSubmit = async () => {
    setBusy(true);
    const formData = new FormData();
    if (cartProducts?.length > 0) {
      const arr = cartProducts.map((p) =>
        p?.option_id
          ? {
              product_id: p?.product_id || '',
              quantity: p?.quantity || '',
              unit_id: p?.unit?.id || '',
              option_id: p?.option?.id || ''
            }
          : {
              product_id: p?.product_id || '',
              quantity: p?.quantity || '',
              unit_id: p?.unit?.id || ''
            }
      );
      formData.append('products', JSON.stringify(arr));
    }
    formData.append('company_id', fetchedSingleCompany?.id || '');
    //
    //
    //

    const action = await dispatch(
      createSupplierQuoteAction({
        dataToBeSubmitted: formData
      })
    );
    const res = action?.payload?.res;
    if (checkRes(res)) {
      dispatch(resetCartAction());

      try {
        const paymentRes = await orderPaymentApi({
          order_id: res?.data?.data?.id
        });
        if (
          checkRes(paymentRes) &&
          paymentRes?.data?.data &&
          typeof paymentRes?.data?.data === 'string'
        ) {
          setBusy(false);
          window.location.href = paymentRes.data.data;
        }
      } catch (e) {
        setBusy(false);
      }
    }
  };

  if (cartProducts?.length === 0 || !cartProducts) {
    return (
      <div className="supplier-products-page">
        <CustomBreadcrumb
          arr={[
            {
              title: t('breadcrumbs.home'),
              isLink: true,
              to: routerLinks?.homePage
            },
            {
              title: t('breadcrumbs.suppliers'),
              isLink: true,
              to: generalRouterLinks?.suppliersRoute
            },
            {
              title: t('breadcrumbs.supplierDetails'),
              isLink: true,
              to: `${generalRouterLinks?.singleCompanyRoute}?company_id=${
                params?.company_id || ''
              }`
            },
            {
              title: t('breadcrumbs.supplierProducts'),
              isLink: false
            }
          ]}
        />
        <div className="custom-container">
          <CustomEmpty>
            <h2
              style={{
                margin: '18px auto',
                textAlign: 'center'
              }}
            >
              {/* No Proudcts found! */}
              {t('supplierProductsPage.emptyCart')}
            </h2>
          </CustomEmpty>
        </div>
      </div>
    );
  } else if (cartProducts?.length > 0) {
    return (
      <div className="cart-page">
        <div className="custom-container">
          <div className="bread-crumb-cart-wrapper">
            <CustomBreadcrumb
              arr={[
                {
                  title: t('breadcrumbs.home'),
                  isLink: true,
                  to: routerLinks?.homePage
                },
                {
                  title: t('breadcrumbs.suppliers'),
                  isLink: true,
                  to: generalRouterLinks?.suppliersRoute
                },
                {
                  // title: t('breadcrumbs.supplierDetails'),
                  title:
                    fetchedSingleCompany?.name ||
                    t('breadcrumbs.supplierDetails'),
                  isLink: true,
                  to: `${generalRouterLinks?.singleCompanyRoute}?company_id=${
                    params?.company_id || ''
                  }`
                },
                {
                  // title: t('breadcrumbs.supplierProducts'),
                  title: cartServiceName || t('breadcrumbs.supplierProducts'),
                  isLink: false
                }
              ]}
            />
          </div>

          <div className="products-summary-wrapper">
            <section className="pro-section">
              <div className="sec-header">
                {/* <p className="title">{sharedT('cart')}</p> */}
                <p className="title">{cartServiceName}</p>
                <p className="sub-title">{sharedT('subTitle')}</p>
              </div>

              <div className="supp-products-list">{renderCartItemsList()}</div>
            </section>

            <section className="summary-actions-section">
              <div className="summary-section">
                <div className="sum-header">
                  <p className="title">{sharedT('summary')}</p>
                  <p className="sub-title">{sharedT('summarySubTitle')}</p>
                </div>

                <div className="sec-body">
                  <div className="row-wrap">
                    <div className="row-title">{sharedT('quantity')}</div>
                    <div className="row-value">
                      {cartProducts.length > 0
                        ? cartProducts.reduce(
                            (acc, curr) => acc + curr?.quantity,
                            0
                          )
                        : 0}{' '}
                      {sharedT('items')}
                    </div>
                  </div>
                  <div className="row-wrap">
                    <div className="row-title">{sharedT('subTotal')}</div>
                    <div className="row-value">
                      {cartTotal ? parseFloat(cartTotal).toFixed(2) : ''}{' '}
                      <span style={{ fontSize: 14 }}>{t('currency.sar')}</span>
                    </div>
                  </div>

                  <div className="row-wrap">
                    <div className="row-title tax-title">
                      <span
                        style={{
                          color: '#0B0D0F',
                          fontWeight: 'bold',
                          fontSize: 18,
                          lineHeight: 1.5
                        }}
                      >
                        {sharedT('totalCost')}
                      </span>
                      <div
                        style={{
                          color: '#668199',
                          fontSize: 13
                        }}
                        className="tax-title"
                      >
                        {sharedT('taxInclude')}
                      </div>
                    </div>
                    <div className="row-value colored">
                      {cartTotal
                        ? (parseFloat(cartTotal) * 1.14).toFixed(2)
                        : ''}{' '}
                      <span style={{ fontSize: 14 }}>{t('currency.sar')}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="actions-wrap">
                <button
                  className="ngo-btn"
                  onClick={() => {
                    if (!user) {
                      dispatch(setNotAuthedModalOpened(true));
                    } else {
                      dispatch(setNegotiationModalOpenedAction(true));
                    }
                  }}
                >
                  {sharedT('submitNego')}
                </button>
                <button
                  className="buy-btn"
                  onClick={() => {
                    if (!user) {
                      dispatch(setNotAuthedModalOpened(true));
                    } else {
                      dispatch(setNegotiationPriceAction(''));
                      handleSubmit();
                      // dispatch(setQuoteType(quoteTypes?.supplierQuote));
                      // dispatch(setQuoteModalOpened(true));
                    }
                  }}
                >
                  {sharedT('buy')}
                </button>
              </div>
            </section>
          </div>

          <Link
            className="back-link"
            to={generalRouterLinks?.supplierProductsRoute(
              cartSupplierId,
              cartServiceId
            )}
          >
            {sharedT('back')}
          </Link>
        </div>

        <NegotiationModal />

        {busy && <LoadingModal />}
      </div>
    );
  }

  return null;
};

export default CartPage;
