import axios from 'axios';
import apisUrls from '../apisUrls';

const createSupplierQuoteApi = (values) => {
  return axios.post(apisUrls.createQuoteSupplier, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export default createSupplierQuoteApi;
