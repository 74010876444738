/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import CountryCityOptions from '../../common/country-city-options/CountryCityOptions';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import ServicesSelectOption from '../../common/services-select-option/ServicesSelectOption';
import { getCompanyTypesArr } from '../../const-values/accountTypes';
import { getServicesAction } from '../../redux/general-slice/generalSliceThunkApis';
import './AdvancedSearchForm.scss';
// import useFCMDeviceToken from '../../custom-hooks/useFCMDeviceToken';

const AdvancedSearchForm = ({ onSubmit, searchBtnText }) => {
  const { t, i18n } = useTranslation();
  const countries = useSelector((store) => store.general.fetchedCountriesData);
  const generalLabelStr = (key) => t(`signup_form.${key}.label`);
  // const fcmDeviceToken = useFCMDeviceToken();
  const schema = Yup.object().shape({
    companyType: Yup.string().required('')
  });
  const { search } = useLocation();
  const values = queryString.parse(search);
  const dispatch = useDispatch();
  const isLoadingServices = useSelector(
    (store) => store.general.isLoadingServices
  );
  const services = useSelector((store) => store.general.fetchedServicesData);
  const servicesPagination = useSelector(
    (store) => store.general.servicesPagination
  );
  const handleClickMore = () => {
    if (servicesPagination.current_page < servicesPagination.total_pages)
      dispatch(
        getServicesAction({
          filterObj: {
            page: servicesPagination.current_page + 1
          }
        })
      );
  };

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      companyType: values?.companyType || '',
      service_id: '',
      subService_id: '',
      subSubService_id: '',
      country_id: values?.country_id || '',
      city_id: values?.city_id || ''
    }
  });

  const [allSubServicesArr, setAllSubServicesArr] = useState([]);
  const [allSubSubServicesArr, setAllSubSubServicesArr] = useState([]);
  useEffect(() => {
    if (watch('service_id') && services?.length > 0) {
      let foundServObj = null;
      const arr = [];

      foundServObj = services.find((obj) => obj?.id == watch('service_id'));
      if (
        foundServObj &&
        typeof foundServObj === 'object' &&
        foundServObj?.subServices?.length >= 0
      ) {
        arr.push(...foundServObj.subServices);
      }

      setAllSubServicesArr(arr);
      setValue('subService_id', []);
    }
  }, [watch('service_id')]);

  useEffect(() => {
    if (watch('subService_id') && allSubServicesArr?.length > 0) {
      let foundObj = null;
      const arr = [];

      foundObj = allSubServicesArr.find(
        (obj) => obj?.id == watch('subService_id')
      );

      if (
        foundObj &&
        typeof foundObj === 'object' &&
        foundObj?.subServices?.length >= 0
      ) {
        arr.push(...foundObj.subServices);
      }

      setAllSubSubServicesArr(arr);
      setValue('subSubService_id', '');
    }
  }, [watch('subService_id'), allSubServicesArr]);

  const isSubmitting = useSelector((store) => store.user.isSubmitting);
  const submitForm = async (data) => {
    onSubmit(data);
  };

  const [form] = Form.useForm();
  return (
    <>
      <Form
        className="advanced-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(submitForm)}
      >
        <div className="form-header">
          <p className="bold-font main-title">
            {t('hero_section.advancedSearch')}
          </p>
          <p className="sub-title">{t('hero_section.subTitle')}</p>
        </div>

        <div className="form-body">
          <div
            className={`custom-select-field-wrapper without-icon ${i18n.dir()}`}
          >
            <AntdSelectOption
              className="custom-select-field"
              name={`companyType`}
              errorMsg={errors?.companyType && errors.companyType.message}
              validateStatus={errors?.companyType ? 'error' : ''}
              control={control}
              setValue={setValue}
              options={
                getCompanyTypesArr(t)?.length > 0 &&
                getCompanyTypesArr(t).map((obj) => ({
                  title: obj?.name,
                  value: obj?.value
                }))
              }
              showSearch={true}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
            <p className="custom-label">{generalLabelStr('companyType_id')}</p>
          </div>

          <CountryCityOptions
            countries={countries}
            countryName="country_id"
            cityName="city_id"
            countryLabel={generalLabelStr('country_id')}
            cityLabel={generalLabelStr('city_id')}
            //
            watch={watch}
            setValue={setValue}
            control={control}
            errors={errors}
          />

          <ServicesSelectOption
            control={control}
            setValue={setValue}
            errors={errors}
            name="service_id"
            label={generalLabelStr('services')}
            mode="tag"
            selectOptionArr={services}
            selectOptionPagination={servicesPagination}
            isLoadingSelectOption={isLoadingServices}
            handleClickMore={handleClickMore}
            parentClassName="advanced-form"
          />
          <ServicesSelectOption
            control={control}
            setValue={setValue}
            errors={errors}
            name="subService_id"
            label={generalLabelStr('subServices')}
            // mode="multiple"
            parentClassName="advanced-form"
            selectOptionArr={allSubServicesArr}
          />
          <ServicesSelectOption
            control={control}
            setValue={setValue}
            errors={errors}
            name="subSubService_id"
            label={generalLabelStr('subServices')}
            // mode="multiple"
            parentClassName="signup-form"
            selectOptionArr={allSubSubServicesArr}
          />

          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={isSubmitting}
          >
            {t('hero_section.search')}
          </Button>
        </div>
      </Form>

      {isSubmitting && <LoadingModal />}
    </>
  );
};

export default AdvancedSearchForm;
