/* eslint-disable eqeqeq */
const accountTypes = () => {
  return {
    user: 1,
    company: 2,
    employee: 3
  };
};

export const companyTypes = () => {
  return {
    contractor: 1,
    supplier: 2,
    office: 3,
    supportiveServices: 4
  };
};

export default accountTypes;

export const allCompanyTypesArr = [
  companyTypes()?.supplier,
  companyTypes()?.contractor,
  companyTypes()?.office,
  companyTypes()?.supportiveServices
];

export const getCompanyTypesArr = (t) => [
  {
    id: 1,
    name: t('companyTypes.contractor'),
    value: companyTypes()?.contractor
  },
  {
    id: 2,
    name: t('companyTypes.supplier'),
    value: companyTypes()?.supplier
  },
  {
    id: 3,
    name: t('companyTypes.office'),
    value: companyTypes()?.office
  },
  {
    id: 4,
    name: t('companyTypes.supportiveServices'),
    value: companyTypes()?.supportiveServices
  }
];
