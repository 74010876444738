import { t } from 'i18next';
import { useEffect } from 'react';
import CustomBreadcrumb from '../../common/bread-crumb/Breadcrumb';
import routerLinks from '../../components/app/routerLinks';
import HomeDevelopmentSection from '../home-page/HomeDevelopmentSection';
import WorksSection from '../home-page/WorksSection';

const AboutUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about-us-page">
      <CustomBreadcrumb
        arr={[
          {
            title: t('breadcrumbs.home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: t('breadcrumbs.about'),
            isLink: false
          }
        ]}
      />
      <HomeDevelopmentSection />
      <WorksSection />
    </div>
  );
};

export default AboutUsPage;
